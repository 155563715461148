// ========= Modal Contents ========= //
.orderModal {
  .modalContents {
    width: 60%;
    height: auto;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: none;
    padding: 0;
    border-radius: 12px;

    .modalHeader {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      padding: 1rem;

      h6 {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.6;
        letter-spacing: 0.0075em;
        flex: 0 0 auto;
        margin: 0px;
      }

      .btnClose {
        background-color: transparent;
        border: 2px solid #d65758;
        border-radius: 50%;
        padding: 2px;
        min-width: auto;
        transition: background-color 0.3s ease, color 0.3s ease;

        .MuiSvgIcon-root {
          color: #d65758;
          transition: color 0.3s ease;
          font-size: 16px;
        }

        &:hover {
          background-color: #f13a3a;
          border: 2px solid #f13a3a;

          .MuiSvgIcon-root {
            color: #fff;
          }
        }
      }
    }

    .modalBody {
      padding: 1rem;
      border-bottom: 1px solid #e0e0e0;
      height: 65vh;
      overflow-y: auto;

      .orderDetails {
        p {
          font-size: 14px;
          line-height: 1.8rem;
        }
        .infoDetails {
          margin-top: 6px;
 
          .infoDetailsList {
            border-right: 1px solid #cdcdcd;
            padding-right: 6px;
          }
        }
        .accordionList {
          margin-top: 1rem;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          overflow: hidden;

          .accordHead {
            min-height: unset;
            height: 40px;
            max-height: unset;
            border-bottom: 1px solid #dedede;

            p {
              font-weight: 600;
            }
          }

          .Mui-expanded {
            margin-bottom: 0 !important;
            margin-top: 0 !important;
          }
        }
      }
    }

    .modalFooter {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      padding: 1rem;

      .btnCancel {
        &.grey {
          color: #696969;
          border-color: #d8d8d8;

          &:hover {
            background-color: #f2f2f2;
          }
        }
      }
    }

    &.viewDetails {
      width: 60%;
      height: auto;

      .modalBody {
        height: 65vh;
        overflow-y: auto;

        p {
          font-size: 14px;
          line-height: 1.8rem;
        }

        .accordionList {
          margin-top: 1rem;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          overflow: hidden;

          .accordHead {
            min-height: unset;
            height: 40px;
            max-height: unset;
            border-bottom: 1px solid #dedede;

            p {
              font-weight: 600;
            }
          }

          .Mui-expanded {
            margin-bottom: 0 !important;
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

// For Responsive ----
@media (min-width: 330px) and (max-width: 400px) {
  .orderModal {
    .modalContents {
      width: 320px;
    }
  }
}

@media (min-width: 401px) and (max-width: 768px) {
  .orderModal {
    .modalContents {
      width: 370px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .orderModal {
    .modalContents {
      width: 550px;
    }
  }
}