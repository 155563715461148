.pageContainer {
    &.homePage {
        .homeBlock {
            .react-multiple-carousel__arrow--left {
                left: calc(2% + 1px);
            }

            .react-multiple-carousel__arrow--right {
                right: calc(2% + 1px);
            }

            .react-multiple-carousel__arrow {
                min-width: 26px;
                min-height: 26px;
                border-radius: 50%;

                &::before {
                    font-size: 16px;
                }
            }

            .pageLeftBlock,
            .pageRightBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;

                h4 {
                    font-size: 14px;
                }

                p {
                    font-size: 12px;
                    color: #767676;
                }

                ul {
                    padding-left: 20px;

                    li {
                        font-size: 12px;
                        color: #767676;
                    }
                }
            }

            .centerBlockTop {
                width: 100%;
                height: auto;
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
                margin: 0 auto 25px;

                .topField {
                    display: flex;
                    align-items: center;

                    .profileImg {
                        width: 55px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inputBox {
                        width: 100%;

                        input {
                            border-radius: 35px;
                            padding: 14px 16px;
                            background-color: #ebedf6;
                            border: none;
                            box-shadow: none;
                            color: rgba(0, 0, 0, 0.6);

                        }
                    }
                }

                .bottomField {
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 100px);
                    margin: 16px auto 0;

                    .mediaButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            button {
                                border: none;
                                background-color: transparent;
                                padding: 0;
                                margin-right: 10px;

                                svg {
                                    color: #378fe9;
                                }
                            }
                        }
                    }

                    .dateButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            button {
                                border: none;
                                background-color: transparent;
                                padding: 0;
                                margin-right: 10px;

                                svg {
                                    color: #c37d16;
                                }
                            }
                        }
                    }

                    .writePostButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            button {
                                border: none;
                                background-color: transparent;
                                padding: 0;
                                margin-right: 10px;

                                svg {
                                    color: #e06847;
                                }
                            }
                        }
                    }
                }
            }

            .homeBodyContent {
                .react-multi-carousel-list {
                    .react-multi-carousel-dot-list {
                        .react-multi-carousel-dot {
                            button {
                                border: none;
                                width: 10px;
                                height: 10px;
                                background: rgba(213, 105, 57, 0.3);
                            }
                        }

                        .react-multi-carousel-dot--active button {
                            background: #d56939;
                        }
                    }
                }

                .topAreaCardImageView {
                    margin-bottom: 18px;
                }

                .mainCategories {
                    padding-left: 10px;
                    padding-right: 10px;

                    .templeCard {
                        border: none;
                        margin-bottom: 25px;
                        display: flex;
                        align-items: center;
                        margin: auto;
                        justify-content: center;
                        margin-top: 0px;
                        background-color: transparent;

                        &:hover {
                            cursor: pointer;
                        }

                        .card-img-area {
                            width: auto;
                            height: 100px;

                            @media (max-width: 370px) {
                                height: 76px;
                            }

                            @media (min-width: 371px) and (max-width: 435px) {
                                height: 100px;
                            }

                            @media (min-width: 436px) and (max-width: 491px) {
                                height: 115px;
                            }

                            @media (min-width: 492px) and (max-width: 547px) {
                                height: 130px;
                            }

                            @media (min-width: 548px) and (max-width: 670px) {
                                height: 160px;
                            }

                            @media (min-width: 670px) and (max-width: 900px) {
                                height: 200px;
                            }

                            @media (min-width: 1740px) and (max-width: 3000px) {
                                height: 120px;
                            }

                            .card-img-top {
                                width: 100%;
                                height: 100%;
                                border-radius: 20px;
                                object-fit: cover;
                            }
                        }

                        .card-body {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 8px 0 6px 0;

                            @media (max-width: 767px) {
                                padding-bottom: 0;
                            }


                            .card-title {
                                margin-bottom: 0;
                                font-weight: 500;
                                font-size: 14px !important;

                                @media (max-width: 767px) {
                                    font-size: 14px;
                                }
                            }


                            a.btn {
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .knowYourrashi {
                    padding: 12px 10px 8px 10px;
                    border-radius: 1.5rem;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }

                .independenceOffer {
                    margin-top: 25px;

                    .titleBlock {
                        text-align: center;
                        position: relative;
                        margin-bottom: 30px;
                        margin-left: 10px;
                        margin-right: 10px;

                        &::after {
                            width: 100%;
                            height: 1px;
                            background-color: #707070;
                            content: "";
                            position: absolute;
                            top: 50%;
                            margin-top: -1px;
                            left: 0;
                            right: 0;
                            opacity: 0.2;
                        }

                        h1 {
                            &.mainTitle {
                                font-size: 24px;
                                text-transform: uppercase;
                                text-align: center;
                                padding-bottom: 0;
                                margin-bottom: 0;
                                color: #000;
                                text-transform: uppercase;
                                font-weight: bold;
                                position: relative;
                                display: inline-block;
                                background-color: #f1f0f5;
                                position: relative;
                                z-index: 2;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }

                    .offerZoneArea {
                        display: flex;
                        align-items: center;
                        
                        .offerImg {
                            padding: 0 10px 8px 10px;
                            text-align: center;
    
                            img {
                                width: 100%;
                            }
                        }
                    }
                }

                .featureDetailArea {
                    margin-top: 25px;

                    .titleBlock {
                        text-align: center;
                        position: relative;
                        margin-bottom: 30px;
                        margin-left: 10px;
                        margin-right: 10px;

                        &::after {
                            width: 100%;
                            height: 1px;
                            background-color: #707070;
                            content: "";
                            position: absolute;
                            top: 50%;
                            margin-top: -1px;
                            left: 0;
                            right: 0;
                            opacity: 0.2;
                        }

                        h1 {
                            &.mainTitle {
                                font-size: 24px;
                                text-transform: uppercase;
                                text-align: center;
                                padding-bottom: 0;
                                margin-bottom: 0;
                                color: #000;
                                text-transform: uppercase;
                                font-weight: bold;
                                position: relative;
                                display: inline-block;
                                background-color: #f1f0f5;
                                position: relative;
                                z-index: 2;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }

                    .featureCard {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        margin: 0px 10px 25px 10px;
                        border-radius: 20px;
                        overflow: hidden;
                        height: 230px;
                        cursor: pointer;

                        .firstCardDes {
                            width: 100%;
                            height: 100%;
                            background-color: #352168;
                            background-image: url(../../assets/alpona.png);
                            background-position-x: 100px;
                            background-position-y: 20px;
                            background-size: cover;
                            background-repeat: no-repeat;

                            .panchangTitle {
                                color: #fff;
                            }

                            .upperDes {
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                gap: 15px;
                                //margin-top: 10px;
                                @media (min-width: 366px) and (max-width: 464px) {
                                    margin: 14px 0;
                                }

                                @media (min-width: 691px) and (max-width: 714px) {
                                    margin: 14px 0;
                                }

                                @media (min-width: 715px) and (max-width: 899px) {
                                    margin: 14px 0;
                                }

                                @media (min-width: 1020px) and (max-width: 1055px) {
                                    margin: 14px 0;
                                }

                                @media (min-width: 1056px) and (max-width: 1199px) {
                                    margin: 14px 0;
                                }

                                @media (min-width: 1349px) and (max-width: 1396px) {
                                    margin: 14px 0;
                                }

                                @media (min-width: 1397px) and (max-width: 1400px) {
                                    margin: 14px 0;
                                }

                                @media (min-width: 1401px) and (max-width: 2600px) {
                                    margin: 14px 0;
                                }

                                .upperLeft {
                                    background-color: #886dcc;
                                    border-radius: 10px;
                                    padding: 10px;
                                    width: 50%;
                                    overflow-y: auto;
                                    max-height: 100px;
                                    padding-right: 8px;
                                    position: relative;
                                    z-index: 10;
                                    // == Hide scrollbar styles == //
                                    scrollbar-width: none;
                                    &::-webkit-scrollbar {
                                        display: none;
                                    }

                                    h4 {
                                        color: #fff;
                                        font-size: 32px;
                                        font-weight: bold;
                                        display: inline-block;
                                        position: relative;

                                        span {
                                            position: absolute;
                                            left: 100%;
                                            top: 0;
                                            font-size: 18px;
                                        }
                                    }

                                    p {
                                        font-size: 12px;
                                        font-weight: normal;
                                        text-align: left;
                                        color: #fff;
                                        letter-spacing: 0.5px;
                                        margin: 0;
                                    }

                                }

                                .upperRight {
                                    width: 50%;

                                    h5 {
                                        color: #e6e6e6;
                                        font-size: 14px;
                                        text-align: left;
                                        font-weight: 600;
                                        margin: 0;
                                        padding: 0;
                                    }

                                    p {
                                        font-size: 12px;
                                        color: #fff;
                                        letter-spacing: 0.5px;
                                        margin: 0 0 6px 0;
                                        padding: 0;

                                        @media (min-width: 400px) and (max-width: 405px) {
                                            font-size: 12px;
                                        }

                                        @media (min-width: 406px) and (max-width: 464px) {
                                            font-size: 14px;
                                        }

                                        @media (min-width: 755px) and (max-width: 770px) {
                                            font-size: 13px;
                                        }

                                        @media (min-width: 771px) and (max-width: 899px) {
                                            font-size: 14px;
                                        }

                                        @media (min-width: 1056px) and (max-width: 1115px) {
                                            font-size: 12px;
                                        }

                                        @media (min-width: 1116px) and (max-width: 1199px) {
                                            font-size: 13px;
                                        }
        
                                        @media (min-width: 1397px) and (max-width: 1400px) {
                                            font-size: 12px;
                                        }

                                        @media (min-width: 1401px) and (max-width: 2600px) {
                                            font-size: 12px;
                                        }

                                        
                                    }

                                    .boldTitle {
                                        font-size: 14px;
                                        font-weight: bold;

                                        @media (min-width: 400px) and (max-width: 464px) {
                                            font-size: 16px;
                                        }

                                        @media (min-width: 755px) and (max-width: 899px) {
                                            font-size: 16px;
                                        }

                                        @media (min-width: 1056px) and (max-width: 1115px) {
                                            font-size: 14px;
                                        }

                                        @media (min-width: 1116px) and (max-width: 1199px) {
                                            font-size: 16px;
                                        }
        
                                        @media (min-width: 1397px) and (max-width: 1400px) {
                                            font-size: 16px;
                                        }

                                        @media (min-width: 1401px) and (max-width: 2600px) {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }

                            .lowerDes {
                                display: flex;
                                gap: 15px;
                                align-items: center;
                                justify-content: space-around;
                                margin-top: 10px;

                                .sunrise {
                                    display: flex;
                                    align-items: center;

                                    .cBlock {
                                        p {
                                            font-size: 13px;
                                            color: #fff;
                                            text-align: left;
                                            margin-left: 10px;
                                            margin-bottom: 0px;

                                            &.fwb {
                                                font-weight: 600;
                                            }
                                        }
                                    }

                                    img {
                                        width: 40px;
                                        height: 40px;
                                    }
                                }

                                .sunset {
                                    display: flex;
                                    align-items: center;

                                    .cBlock {
                                        p {
                                            font-size: 13px;
                                            color: #fff;
                                            text-align: left;
                                            margin-left: 10px;
                                            margin-bottom: 0px;

                                            &.fwb {
                                                font-weight: 600;
                                            }
                                        }
                                    }

                                    img {
                                        width: 42px;
                                        height: 30px;
                                    }
                                }
                            }

                            .viewMoreBtn {
                                text-align: end;

                                button {
                                    font-size: 10px;
                                    border-radius: 20px;
                                    background-color: #fff;
                                    color: #d56939;
                                }
                            }
                        }

                        .cardInn {
                            margin: 0 auto;
                            width: 100%;
                            height: 100%;
                            position: relative;

                            img {
                                width: 100%;
                                height: 100%;
                            }

                            .viewMoreBtn {
                                position: absolute;
                                bottom: 10px;
                                right: 14px;

                                button {
                                    font-size: 10px;
                                    border-radius: 20px;
                                    background-color: #462212;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

                .upPujaCard {
                    margin-top: 25px;

                    .titleBlock {
                        text-align: center;
                        position: relative;
                        margin-bottom: 30px;
                        margin-left: 10px;
                        margin-right: 10px;

                        &::after {
                            width: 100%;
                            height: 1px;
                            background-color: #707070;
                            content: "";
                            position: absolute;
                            top: 50%;
                            margin-top: -1px;
                            left: 0;
                            right: 0;
                            opacity: 0.2;
                        }

                        h1 {
                            &.mainTitle {
                                font-size: 24px;
                                text-transform: uppercase;
                                text-align: center;
                                padding-bottom: 0;
                                margin-bottom: 0;
                                color: #000;
                                text-transform: uppercase;
                                font-weight: bold;
                                position: relative;
                                display: inline-block;
                                background-color: #f1f0f5;
                                position: relative;
                                z-index: 2;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }

                    .pujaCardBox {
                        min-height: 100px;
                        height: auto;
                        border-radius: 20px;
                        background-color: #fff;
                        text-align: center;
                        padding: 0;
                        overflow: hidden;
                        margin: 0px 10px 25px 10px;

                        &:hover {
                            cursor: pointer;
                        }

                        img {
                            width: 100%;
                        }

                        .card-body {
                            min-height: 120px;

                            .title,
                            h6 {
                                color: #901414;
                                font-size: 14px;
                                font-weight: bold;
                                text-transform: uppercase;
                                padding: 13px;
                                margin: 0;
                                text-align: left;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .content {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 13px;

                                p {
                                    color: #000;
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 1.5;
                                    margin: 0;
                                    text-align: left;
                                }

                                a {
                                    text-transform: uppercase;
                                    text-decoration: none;
                                    color: #fff;
                                    background-color: #d56939;
                                    padding: 8px 15px;
                                    border-radius: 20px;
                                    font-size: 14px;
                                    font-weight: 600;
                                    display: inline-block;
                                    transition: all 0.7s ease;

                                    &:hover {
                                        background-color: #b14a1e;
                                    }
                                }
                            }
                        }
                    }
                }

                .popularTemple {
                    margin-top: 25px;

                    .titleBlock {
                        text-align: center;
                        position: relative;
                        margin-bottom: 30px;
                        margin-left: 10px;
                        margin-right: 10px;

                        &::after {
                            width: 100%;
                            height: 1px;
                            background-color: #707070;
                            content: "";
                            position: absolute;
                            top: 50%;
                            margin-top: -1px;
                            left: 0;
                            right: 0;
                            opacity: 0.2;
                        }

                        h1 {
                            &.mainTitle {
                                font-size: 24px;
                                text-transform: uppercase;
                                text-align: center;
                                padding-bottom: 0;
                                margin-bottom: 0;
                                color: #000;
                                text-transform: uppercase;
                                font-weight: bold;
                                position: relative;
                                display: inline-block;
                                background-color: #f1f0f5;
                                position: relative;
                                z-index: 2;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }

                    .ptBox {
                        width: auto;
                        // display: inline-block;
                        border-radius: 20px;
                        background-color: #fff;
                        text-align: center;
                        padding: 0;
                        overflow: hidden;
                        margin: 0 8px 25px 8px;
                        position: relative;
                        cursor: pointer;

                        .cardImgBlock {
                            height: 130px;
                            overflow: hidden;
                            background-color: #242424;
                            border-radius: 12px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }


                        .ptBody {
                            padding: 12px;

                            .title {
                                color: #000;
                                font-size: 14px;
                                font-weight: bold;
                                margin-bottom: 10px;
                                margin: 0;
                                text-align: left;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .content {
                                padding: 0;

                                p {
                                    color: #777;
                                    font-size: 12px;
                                    margin-bottom: 0;
                                    text-align: left;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }

                            .nwlyAddedbadge {
                                width: auto;
                                height: auto;
                                padding: 5px 10px;
                                position: absolute;
                                top: 20px;
                                right: -2px;
                                bottom: auto;
                                background-color: #d56939;
                                color: #fff;
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 16px;
                                border-radius: 16px 0 0 16px;
                            }
                        }
                    }

                }

                .blessings {
                    margin-top: 25px;

                    .titleBlock {
                        text-align: center;
                        position: relative;
                        margin-bottom: 30px;
                        margin-left: 10px;
                        margin-right: 10px;

                        &::after {
                            width: 100%;
                            height: 1px;
                            background-color: #707070;
                            content: "";
                            position: absolute;
                            top: 50%;
                            margin-top: -1px;
                            left: 0;
                            right: 0;
                            opacity: 0.2;
                        }

                        h1 {
                            &.mainTitle {
                                font-size: 24px;
                                text-transform: uppercase;
                                text-align: center;
                                padding-bottom: 0;
                                margin-bottom: 0;
                                color: #000;
                                text-transform: uppercase;
                                font-weight: bold;
                                position: relative;
                                display: inline-block;
                                background-color: #f1f0f5;
                                position: relative;
                                z-index: 2;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }

                    .blessingCard {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: auto 10px;

                        .blessingBox {
                            border-radius: 20px;
                            background-color: #fff;
                            text-align: center;
                            padding: 0;
                            overflow: hidden;

                            img {
                                width: 417px;
                                max-width: 100%;
                            }
                        }
                    }
                }

                .recentPost {
                    margin-top: 20px;

                    .titleBlock {
                        text-align: center;
                        position: relative;
                        margin-bottom: 30px;

                        &::after {
                            width: 100%;
                            height: 1px;
                            background-color: #707070;
                            content: "";
                            position: absolute;
                            top: 50%;
                            margin-top: -1px;
                            left: 0;
                            right: 0;
                            opacity: 0.2;
                        }

                        h1 {
                            &.mainTitle {
                                font-size: 24px;
                                text-transform: uppercase;
                                text-align: center;
                                padding-bottom: 0;
                                margin-bottom: 0;
                                color: #000;
                                text-transform: uppercase;
                                font-weight: bold;
                                position: relative;
                                display: inline-block;
                                background-color: #f1f0f5;
                                position: relative;
                                z-index: 2;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }

                    .recentPostCard {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: auto;

                        .recentPostBox {
                            border-radius: 20px;
                            background-color: #fff;
                            text-align: center;
                            padding: 15px;
                            overflow: hidden;
                            min-height: 358px;
                            margin-bottom: 25px;

                            .postTop {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .postProfile {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    border: 3px solid #d2693a;
                                    overflow: hidden;
                                    // display: inline-block;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .postTitle {
                                    text-align: left;

                                    h6 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: bold;

                                        img {
                                            max-width: 14px;
                                            margin-left: 5px;
                                        }
                                    }

                                    p {
                                        font-size: 12px;
                                        margin: 0;
                                    }
                                }
                            }

                            .postText {
                                p {
                                    font-size: 14px;
                                    margin: 0;
                                    padding: 10px 0;
                                    text-align: left;
                                }
                            }

                            .postImage {
                                img {
                                    width: 100%;
                                    height: 28em;
                                    object-fit: cover;
                                    border-radius: 20px;
                                }

                                video {
                                    width: 100%;
                                    height: 28em;
                                    object-fit: cover;
                                    border-radius: 20px;
                                }
                            }

                            .content {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-top: 12px;

                                .icons {
                                    ul {
                                        list-style: none;
                                        padding: 0;
                                        margin: 0;

                                        li {
                                            display: inline-block;
                                            padding: 0 5px;

                                            a img {
                                                max-width: 30px;
                                            }
                                        }
                                    }
                                }

                                .likeText {
                                    p {
                                        font-size: 12px;
                                        margin: 0;

                                        img {
                                            max-width: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .homeRightBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }
        }

        .createPost {
            width: auto;
            position: fixed;
            top: 94px;
            right: 0;
            z-index: 10;

            .btnCreatePost {
                font-size: 14px;
                background-color: #d56939;
                color: #fff;
                border: none;
                transition: all 0.7s ease;
                border-radius: 30px 0 0 30px;
                box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);

                &:hover {
                    background-color: #b14a1e;
                }
            }
        }


    }
}


.socialMediaSec {
    margin-top: 35px;

    .titleBlock {
        text-align: center;
        position: relative;
        margin-bottom: 30px;
        margin-left: 10px;
        margin-right: 10px;

        &::after {
            width: 100%;
            height: 1px;
            background-color: #707070;
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -1px;
            left: 0;
            right: 0;
            opacity: 0.2;
        }

        h1 {
            &.mainTitle {
                font-size: 24px;
                text-transform: uppercase;
                text-align: center;
                padding-bottom: 0;
                margin-bottom: 0;
                color: #000;
                text-transform: uppercase;
                font-weight: bold;
                position: relative;
                display: inline-block;
                background-color: #f1f0f5;
                position: relative;
                z-index: 2;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.blessYSiso {
    .blessIsoImg {
        width: 100%;
        margin: 2rem auto 1.5rem auto;

        img {
            width: 70%;
            height: auto;
        }
    }
}

/* ========= Responsive SCSS ========= */

@media (min-width: 1600px) {
    .pageContainer {
        &.homePage {
            .homeBlock {
                .homeBodyContent {
                    .upPujaCard {
                        .pujaCardBox {
                            width: calc(100% - 18px);
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: 900px) {
    .pageContainer {
        &.homePage {
            .homeBlock {
                .homeBodyContent {
                    .independenceOffer {
                        .offerImg {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: 899px) {
    .pageContainer {
        padding-top: 20px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .pageContainer {
        padding-bottom: 60px;
    }
}

@media (min-width:768px) and (max-width: 991px) {
    .pageContainer {
        &.homePage {
            .homeBlock {
                .homeBodyContent {
                    .popularTemple {
                        .ptBox {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    .pageContainer {
        &.homePage {
            .homeBlock {
                .homeBodyContent {
                    .popularTemple {
                        .ptBox {
                            width: auto;

                            .cardImgBlock {
                                height: 200px;
                            }
                        }
                    }

                    .upPujaCard,
                    .featureDetailArea,
                    .popularTemple,
                    .independenceOffer,
                    .socialMediaSec {
                        .titleBlock {
                            margin-bottom: 20px;
                            
                            h1 {
                                &.mainTitle {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .blessYSiso {
        .blessIsoImg {
            margin: 2rem auto 1.5rem auto;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media (max-width: 600px) {
    .pageContainer {
        &.homePage {
            .homeBlock {
                .homeBodyContent {
                    .independenceOffer {
                        .offerZoneArea {
                            display: inline-block;

                            .offerImg {
                                padding-bottom: 16px;

                                &:last-child {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

@media (max-width:399px) {
    .pageContainer.homePage {
        .homeBlock {
            .homeBodyContent {
                .mainCategories {
                    .templeCard {
                        .card-body {
                            p {
                                font-size: 14px;
                                font-weight: 500 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}