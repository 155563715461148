.pageContainer {
    &.postContentPage {
        .postContentBlock {
            .postContentCenterBlock {
                .backToBlockBtn {
                    color: #d56939;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .postContentInfo {
                    margin-top: 1rem;

                    .postContentTitle {
                        font-size: 24px;
                        font-weight: 600;
                        margin-bottom: 0.3rem;
                        color: #181818;
                    }

                    .postContentSubTitle {
                        font-size: 14px;
                        color: #181818;
                        margin-bottom: 1rem;
                    }

                    .postContentBanner {
                        margin-bottom: 14px;

                        img {
                            width: 100%;
                            height: 54vh;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                    }

                    .postBlindContent {
                        table {
                            border-color: #d8d8d8;
                            background-color: #fff;
                            border: 1px solid #d8d8d8;
                            margin-bottom: 10px;

                            tbody {
                                tr {
                                    &:first-child {
                                        td {
                                            color: #d56939 !important;
                                            font-weight: 600 !important;
                                        }
                                    }

                                    border-bottom: 1px solid #d8d8d8;

                                    td {
                                        padding: 10px 16px;
                                        color: #181818;
                                        font-weight: 500;
                                        border-right: 1px solid #d8d8d8;

                                        p {
                                            margin-bottom: 0 !important;
                                        }
                                    }
                                }
                            }
                        }

                        img {
                            width: 100%;
                            //max-width: 100%;
                            margin-top: 16px;
                            height: 65vh;
                            object-fit: cover;
                        }

                        iframe {
                            width: 100%;
                            height: 350px;

                            @media (max-width: 600px) {
                                height: auto;
                            }
                        }

                        ul {
                            padding-left: 1rem;
                        }

                        h2 {
                            margin-bottom: 8px;
                        }
                    }
                }

                .postContentFooter {
                    margin-top: 3rem;
                    margin-bottom: 3rem;

                    .footerLeftInfo {
                        p {
                            color: #181818;
                            font-size: 16px;
                        }

                        img {
                            width: 20px;
                            max-width: 100%;
                        }
                    }

                    .footerRightInfo {
                        p {
                            font-size: 16px;
                            color: #181818;
                            font-weight: 500;
                        }

                        img {
                            width: 30px;
                            max-width: 100%;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}


// ---- For Responsive ----
@media (min-width: 340px) and (max-width: 767px) {
    .pageContainer {
        &.postContentPage {
            .postContentBlock {
                .postContentCenterBlock {
                    .postContentInfo {
                        .postContentTitle {
                            font-size: 22px;
                            line-height: 28px;
                        }

                        .postContentSubTitle {
                            font-size: 12px;
                        }

                        .postContentBanner {
                            img {
                                height: 18vh;
                            }
                        }

                        .postBlindContent {
                            img {
                                width: 100%;
                                height: auto;
                            }

                            table {
                                table-layout: fixed;
                                width: 100%;

                                tbody {
                                    tr {
                                        td {
                                            padding: 5px 8px;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 1;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: normal;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .postContentFooter {
                        .footerRightInfo {
                            img {
                                width: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .pageContainer {
        &.postContentPage {
            .postContentBlock {
                .postContentCenterBlock {
                    .postContentInfo {
                        .postContentBanner {
                            img {
                                height: 30vh;
                            }
                        }
                    }
                }
            }
        }
    }
}


// --- Native View --- //
.pageContainerPostContNative {
    padding: 10px 20px 20px;
    background-color: #f1f0f5;
    min-height: calc(100vh - 160px) !important;
    position: relative;

    &.postContentPage {
        .postContentBlock {
            .postContentCenterBlock {
                .backToBlockBtn {
                    color: #d56939;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .postContentInfo {
                    margin-top: 1rem;

                    .postContentTitle {
                        font-size: 24px;
                        font-weight: 600;
                        margin-bottom: 0.3rem;
                        color: #181818;
                    }

                    .postContentSubTitle {
                        font-size: 14px;
                        color: #181818;
                        margin-bottom: 1rem;
                    }

                    .postContentBanner {
                        margin-bottom: 14px;

                        img {
                            width: 100%;
                            height: 54vh;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                    }

                    .postBlindContent {
                        table {
                            border-color: #d8d8d8;
                            background-color: #fff;
                            border: 1px solid #d8d8d8;
                            margin-bottom: 10px;

                            tbody {
                                tr {
                                    &:first-child {
                                        td {
                                            color: #d56939 !important;
                                            font-weight: 600 !important;
                                        }
                                    }

                                    border-bottom: 1px solid #d8d8d8;

                                    td {
                                        padding: 10px 16px;
                                        color: #181818;
                                        font-weight: 500;
                                        border-right: 1px solid #d8d8d8;

                                        p {
                                            margin-bottom: 0 !important;
                                        }
                                    }
                                }
                            }
                        }

                        img {
                            width: 100%;
                            //max-width: 100%;
                            margin-top: 16px;
                            height: 65vh;
                            object-fit: cover;
                        }

                        ul {
                            padding-left: 1rem;
                        }

                        h2 {
                            margin-bottom: 8px;
                        }
                    }
                }

                .postContentFooter {
                    margin-top: 3rem;
                    margin-bottom: 3rem;

                    .footerLeftInfo {
                        p {
                            color: #181818;
                            font-size: 16px;
                        }

                        img {
                            width: 20px;
                            max-width: 100%;
                        }
                    }

                    .footerRightInfo {
                        p {
                            font-size: 16px;
                            color: #181818;
                            font-weight: 500;
                        }

                        img {
                            width: 30px;
                            max-width: 100%;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}


// ---- For Native Responsive ----
@media (min-width: 340px) and (max-width: 767px) {
    .pageContainerPostContNative {
        &.postContentPage {
            .postContentBlock {
                .postContentCenterBlock {
                    .postContentInfo {
                        .postContentTitle {
                            font-size: 22px;
                            line-height: 28px;
                        }

                        .postContentSubTitle {
                            font-size: 12px;
                        }

                        .postContentBanner {
                            img {
                                height: 18vh;
                            }
                        }

                        .postBlindContent {
                            img {
                                width: 100%;
                                height: auto;
                            }

                            table {
                                table-layout: fixed;
                                width: 100%;

                                tbody {
                                    tr {
                                        td {
                                            padding: 5px 8px;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 1;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: normal;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .postContentFooter {
                        .footerRightInfo {
                            img {
                                width: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .pageContainerPostContNative {
        &.postContentPage {
            .postContentBlock {
                .postContentCenterBlock {
                    .postContentInfo {
                        .postContentBanner {
                            img {
                                height: 30vh;
                            }
                        }
                    }
                }
            }
        }
    }
}