.AddDevoteeDetails {

    p,
    label,
    a,
    input,
    button {
        font-family: "Roboto", sans-serif !important;
    }

    //For Input Autofill BG color
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .chadhavaDetails {
        .infoDevotee {
            border: 1px solid #d56939;
            padding: 1rem;
            background-color: #fff;
            border-radius: 20px;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;

            svg {
                font-size: 26px;
            }

            @media (min-width: 340px) and (max-width: 400px) {
                font-size: 15px;

                svg {
                    font-size: 22px;
                }
            }
        }
    }

    .addDevotee {
        margin-top: 1rem;
        background-color: #fff;
        padding: 1rem;
        border-radius: 20px;

        // p {
        //     margin-bottom: 1rem;
        // }

        .formGroup {
            .formField {
                margin-bottom: 0.8rem;

                &.mbAdd {
                    margin-bottom: 0.8rem !important;
                }

                &.lightOpa {
                    opacity: 0.7;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                label {
                    font-size: 14px;
                    color: #282c40;
                    font-weight: bold;
                }

                input {
                    font-size: 14px;
                    color: #282c40;
                }

                .errorText {
                    color: red; 
                }

                .addDevt {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #d56939;
                    font-weight: bold;
                    font-family: "Roboto", sans-serif;
                    margin-top: 0.5rem;
                    display: flex;
                    align-items: center;
                    padding: 0;
                    background-color: transparent;

                    &:hover {
                        background-color: transparent;
                    }

                    &:focus {
                        background-color: transparent;
                    }

                    svg {
                        margin-right: 0.2rem;
                    }
                }

                .btnAddOptional {
                    border-radius: 30px;
                    border: 1px solid #707070;
                    color: #282c40;
                    padding: 13px 18px;
                    line-height: 18px;
                    margin-top: 0.5rem;
                }

                .formCheck {
                    label {
                        cursor: auto;

                        span {
                            padding-top: 0px;
                            padding-bottom: 0px;
                            font-size: 14px;

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }

                .monokamnaDetails {
                    display: block;
                    margin-top: 10px;

                    .MuiInputBase-root {
                        padding: 10px 14px 14px;
                        border-radius: 12px;
                    }
                }
            }

            .optionalDetailBtn {
                margin-top: 20px;

                button {
                    border: 1px solid #000;
                    border-radius: 30px;
                    color: #000;
                    padding: 10px 16px;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }

        .devoteeDetails {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            .editDevotee {
                padding: 3px 8px;
                font-size: 10px;
                background-color: #4c7fcf;
                color: #fff;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }

    .chadhavaItems {
        .formGroup {
            margin: 0;

            .chadhavaItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid rgba(112, 112, 112, 0.2);
                padding: 1rem 0rem;

                &:last-child {
                    border-bottom: none;
                }

                &:first-child {
                    padding-top: 0;
                }

                .chadhavaLeft {
                    width: 60%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .itemImg {
                        width: 65px;
                        height: 65px;
                        max-width: 100%;
                        margin-right: 1rem;
                        border-radius: 4px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 6px;
                            background-color: #e2e2e2;
                        }
                    }

                    .namePrice {
                        width: calc(100% - 96px);
                        margin: 0.5rem 0;

                        p {
                            &:first-child {
                                font-size: 14px;
                                font-weight: 600;
                                margin-bottom: 0.5rem;
                            }

                            &:last-child {
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                    }
                }

                .chadhavaRight {
                    width: 40%;
                    position: relative;
                    text-align: right;

                    .addItemsBtns {
                        margin: auto;
                        text-align: right;

                        .btnsGroup {
                            border-radius: 0.2rem;
                            overflow: hidden;
                            background-color: #d56939;

                            .MuiButton-root {
                                &:first-child:hover,
                                &:last-child:hover {
                                    background-color: #af552f;
                                }
                            }

                            button {
                                border-radius: 0;
                                padding: 0.2rem 0.5rem;
                                box-shadow: none;
                                min-width: auto !important;
                                color: #fff;
                                font-weight: 600;
                                background-color: #d56939;

                                svg {
                                    font-size: 12px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .chadhavaAddOnsItems {
        .addOnsSlider {
            .react-multiple-carousel__arrow--left {
                left: 1%;
            }

            .react-multiple-carousel__arrow--right {
                right: 1%;
            }

            .react-multiple-carousel__arrow {
                min-width: 26px;
                min-height: 26px;
                border-radius: 50%;

                &::before {
                    font-size: 16px;
                }
            }

            .carousel-slider {
                .slider-wrapper {
                    ul {
                        &.slider {
                            width: 20%;
                        }
                    }
                }

                .carousel-status {
                    display: none;
                }
            }

            .thumbs-wrapper {
                display: none;
            }

            .addOnsItems {
                .addOnsItem {
                    position: relative;
                    margin-bottom: 1.5rem;

                    .imgBlock {
                        padding: 0.2rem;
                        width: 115px;
                        height: 115px;
                        margin: auto;

                        img {
                            width: 100%;
                            height: 100%;
                            max-width: 100%;
                            background-color: #e2e2e2;
                            border-radius: 10px;
                        }

                        @media (min-width: 330px) and (max-width: 380px) {
                            width: 98px !important;
                            height: 98px !important;
                        }

                        @media (max-width: 768px) {
                            width: 106px;
                            height: 106px;
                        }

                        @media (min-width: 900px) and (max-width: 952px) {
                            width: 78px;
                            height: 78px;
                        }

                        @media (min-width: 953px) and (max-width: 1100px) {
                            width: 90px;
                            height: 90px;
                        }

                        @media (min-width: 1101px) and (max-width: 1280px) {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    label {
                        width: 100px;
                        max-width: 80%;
                        border-radius: 16px;
                        text-align: center;
                        padding: 0.2rem;
                        background-color: #ca5261;
                        font-size: 12px;
                        font-weight: 500;
                        color: #fff;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        right: 0;
                        margin: auto;

                        @media (min-width: 1025px) and (max-width: 1200px) {
                            font-size: 10px;
                        }
                    }

                    .addBtn {
                        width: 90px;
                        max-width: 80%;
                        margin: auto;
                        font-size: 1rem;
                        color: #d56939;
                        font-weight: 600;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -15px;
                        background-color: #ffece3;
                        border: 1px solid #f2ae8d;
                        padding: 0.1rem 0.5rem;
                        text-transform: uppercase;
                        transition: 0.3s ease;

                        &:hover {
                            background-color: #ffd5c1;
                        }
                    }
                }
            }

            .addOnsDescription {
                text-align: center;

                p {
                    &:first-child {
                        font-size: 14px !important;
                        font-weight: 600 !important;
                        line-height: 16px;
                        margin-bottom: 0.5rem;
                    }

                    &:last-child {
                        color: #d56939 !important;
                    }
                }
            }
        }
    }

    .dakshinaPandit {
        .offerDakshina {
            p {
                //margin-bottom: 1rem;
                display: inline-block;
            }

            .dakshinaCheck {
                margin-top: 0.5rem;

                .checkItems {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.5rem;
                    justify-content: space-between;
                    overflow-x: auto;
                    overflow-y: hidden;
                    padding: 0.5rem;
                    position: relative;
                  
                    /* Custom scrollbar */
                    scrollbar-width: thin;
                    scrollbar-color: #d56939 transparent;
                    
                    /* Webkit-based browsers (Chrome, Safari, Edge) */
                    &::-webkit-scrollbar {
                      height: 6px;
                    }
                  
                    &::-webkit-scrollbar-thumb {
                      background-color: #d56939;
                      border-radius: 10px;
                    }
                  
                    &::-webkit-scrollbar-track {
                      background: transparent;
                    }
                  
                    @media (max-width: 594px) {
                      justify-content: flex-start;
                    }
                  
                    @media (min-width: 900px) and (max-width: 1165px) {
                      justify-content: flex-start;
                    }
                  
                    label {
                      width: calc(25% - 0.8rem);
                      height: 52px;
                      margin: 0;
                      position: relative;
                  
                      
                  
                      &.chipPresent span {
                        align-items: flex-end;
                        padding-bottom: 2px;
                      }
                  
                      span {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                  
                        span {
                          display: none;
                        }
                  
                        &.MuiRadio-root {
                          &::before {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            content: "";
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            margin: auto;
                            border-width: 1px;
                            border-color: #b5b5b5;
                            border-style: solid;
                            background-color: #fff;
                            border-radius: 4px;
                          }
                  
                          &.Mui-checked {
                            &::before {
                              border-color: #f2ae8d;
                              background-color: #ffece3;
                            }
                          }
                        }
                      }
                  
                      .nwlyAddedbadge {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 24px;
                        background-color: #d56939;
                        border-radius: 4px 4px 0 0;
                  
                        span {
                          display: block !important;
                          text-align: center;
                          padding-top: 2px;
                        }
                      }
                    }
                }
            }
        }

        // --- Custom Dakshina Text Area --- //
        .customDakshinaTextArea {
            height: 52px;
            text-align: center;
          
            @media (min-width: 200px) and (max-width: 371px) {
                width: 100px;
            }
          
            @media (min-width: 372px) and (max-width: 590px) {
                width: 105px;
            }
          
            @media (min-width: 591px) and (max-width: 767px) {
                width: 130px;
            }
          
            @media (min-width: 768px) and (max-width: 900px) {
                width: 160px;
            }
          
            @media (min-width: 901px) and (max-width: 1200px) {
                width: 110px;
            }
          
            @media (min-width: 1201px) and (max-width: 1300px) {
                width: 148px;
            }

            @media (min-width: 1301px) and (max-width: 1500px) {
                width: 150px;
            }

            @media (min-width: 1501px) and (max-width: 2800px) {
                width: 170px;
            }
        }

        // --- Custom Dakshina Card --- //
        .customDakshinaCardArea {
            width: 150px;
            height: 100%;
            display: flex;
            align-items: center;
            min-width: auto;
            text-align: center;
          
            @media (min-width: 200px) and (max-width: 371px) {
              width: auto;
              min-width: 100px;
            }
          
            @media (min-width: 372px) and (max-width: 590px) {
              width: auto;
              min-width: 105px;
            }
          
            @media (min-width: 591px) and (max-width: 767px) {
              width: auto;
              min-width: 130px;
            }
          
            @media (min-width: 768px) and (max-width: 900px) {
              width: auto;
              min-width: 160px;
            }
          
            @media (min-width: 901px) and (max-width: 1200px) {
              width: auto;
              min-width: 110px;
            }
          
            @media (min-width: 1201px) and (max-width: 1300px) {
                width: auto;
                min-width: 148px;
            }
  
            @media (min-width: 1301px) and (max-width: 1500px) {
                width: auto;
                min-width: 150px;
            }

            @media (min-width: 1501px) and (max-width: 2800px) {
                width: auto;
                min-width: 170px;
            }
        }     
        
        .checkForFuture {
            margin-top: 1rem;
            
            .checkBoxFuture {
                padding: 0;
 
                &:hover {
                    background-color: transparent;
                }
            }
 
            .checkForFutureText {
                margin-bottom: 0 !important;
            }
        }
    }

    .billDetails {
        .billLists {
            ul {
                padding-top: 0;
                padding-bottom: 0;

                li {
                    padding: 8px 0;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(112, 112, 112, 0.2);

                    &:first-child {
                        padding-top: 0px;
                    }

                    p {
                        margin-bottom: 0;
                    }

                    &:last-child {
                        padding-top: 1rem;
                        border-bottom: none;

                        p {
                            font-weight: bold !important;
                        }
                    }
                }
            }
        }
    }

    .paymentBlock {
        .paymentOption {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;

            .payOption {
                width: 50%;

                .paymentType {
                    width: 100%;

                    .MuiSelect-select {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;

                        span {
                            width: 26px;
                            height: 26px;
                            border-radius: 50%;
                            overflow: hidden;
                            display: inline-block;
                            margin-right: 0.5rem;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                }
            }

            .payNow {
                width: 50%;
                text-align: right;

                .btnPayNow {
                    background-color: #d56939;
                    color: #fff;
                    padding: 1.2rem 2rem;
                    font-size: 18px;
                    border-radius: 40px;
                    border: none;
                    text-align: center;
                    font-weight: bold;
                    width: 180px;
                    max-width: 100%;
                }
            }
        }
    }

    .saveAndContinueBtn {
        background: linear-gradient(-45deg, #0000 33%, hsla(0, 0%, 100%, .5) 50%, #0000 66%), linear-gradient(-45deg, #d8ad1d, #d56939);
        background-size: 250% 250%, 100% 100%;
        animation: gradient 15s ease infinite;
        height: 100vh;
        border-radius: 0.8rem;
        padding: 0.5rem 1rem;
        width: calc(100%);
        height: 54px;
        display: inline-block;
        color: #fff;
        margin: 0 auto;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 1rem;

        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }

            50% {
                background-position: 100% 50%;
            }

            100% {
                background-position: 0% 50%;
            }
        }

    }

    // ----- Puja Booking Checkout Cart SCSS -----
    .pujaBookingDetails {
        margin-top: 20px;

        .cardDesign {
            margin-top: 1rem;
            background-color: #fff;
            padding: 1rem;
            border-radius: 20px;

            p {
                font-size: 14px;
            }

            &.pujaInformation {
                .pujaInfoTop {
                    border-bottom: 2px solid #eaeeff;
                    padding-bottom: 1rem;

                    .pujaNameDate {
                        .pujaName {
                            color: #d56939;
                            font-size: 16px;
                            letter-spacing: 0.2px;
                        }

                        p {
                            font-weight: bold;
                            margin: 4px 0;
                        }
                    }

                    .pujaImage {
                        width: 55px;
                        height: 55px;
                        max-width: 100%;
                        border-radius: 6px;
                        background-color: #707070;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 6px;
                        }
                    }
                }

                .pujaInfoBottom {
                    margin-top: 1rem;

                    .templeName {
                        margin-bottom: 8px;

                        img {
                            width: auto;
                            height: 30px;
                            margin-right: 2px;
                        }

                        p {
                            font-weight: 600;
                            letter-spacing: 0.2px;
                        }
                    }

                    .templeAddress {
                        img {
                            width: 16px;
                            height: auto;
                        }

                        p {
                            font-weight: 600;
                            letter-spacing: 0.2px;
                        }
                    }
                }
            }

            &.addressSce {
                .addressTitle {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000;
                }

                .addressText {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;
                    //margin-top: 10px;

                    svg {
                        font-size: 30px;
                        color: #d56939;
                        margin-right: 10px;
                    }

                    .areaInfo {
                        font-weight: 500;
                        color: #000;
                    }

                    .locationInfo {
                        color: #626367;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                    }

                    button {
                        padding-right: 0;
                    }
                }

                .arrowBtn {
                    button {
                        padding-right: 0;
                        justify-content: end;

                        &:focus {
                            outline: none;
                            border: none;
                        }

                        &:active {
                            outline: none;
                            box-shadow: none;
                            border: none;
                        }

                        &:focus-visible {
                            outline: none;
                            border: none;
                        }

                        svg {
                            color: #242424;
                        }
                    }
                }
            }

            &.devoteeDetails {
                .devoteeTitle {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000;
                }

                .devoteeNameSec {
                    .devoteeNameTitle {
                        font-weight: 600;
                        margin-top: 10px;
                    }

                    .nameArea {
                        margin-top: 8px;

                        .boxColor {
                            width: -webkit-fill-available;
                            height: auto;
                            background-color: #000;
                            color: #fff;
                            padding: 6px 8px;
                            font-size: 14px;
                            border-radius: 20px;
                            // margin-right: 6px;
                        }
                    }

                    .devoteeBottom {
                        margin-top: 40px;

                        .devoteeCount {
                            background-color: #ffece3;
                            border: 1px solid #f2ae8d;
                            border-radius: 5px;
                            padding: 2px 10px;
                            font-weight: 500;
                            color: #000;
                        }

                        .editCart {
                            padding: 2px 10px;
                            background-color: #4c7fcf;
                            color: #fff;
                            text-transform: capitalize;
                            font-weight: 500;
                            font-size: 13px;
                            box-shadow: none;

                            svg {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            &.prasadCheckbox {
                .mainAcceptPrasadBox{
                    gap: 0.5rem;
                }

                .acceptPrasadCheckBox {
                    //padding: 9px 0;

                    .checkBox {
                        padding: 0;
                    }

                    p {
                        font-weight: 600;
                        color: #181818;
                    }
                }

                .prasadImage {
                    width: 42px;
                    height: 42px;
                    background-color: #707070;
                    border-radius: 3px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 3px;
                    }
                }

                .disclaimer {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    color: #333333;
                    background-color: #fff6f2;
                    border: 1px solid #c75b2b;
                    padding: 8px 10px;
                    font-size: 12px !important;
                    font-weight: 500;
                    border-radius: 6px;
                    margin-top: 16px;

                    svg {
                        color: #c75b2b;
                        font-size: 18px;
                        margin-right: 5px;
                    }
                }
            }

            &.offerZone {
                .offerZoneTitle {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000;
                }

                .couponCodeTop {
                    //border-bottom: 2px solid #eaeeff;
                    //padding-bottom: 1rem;
                    margin-top: 8px;

                    .couponApplySec {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;

                        img {
                            width: 22px;
                            max-width: 100%;
                            margin-right: 8px;
                        }

                        .couponText {
                            font-weight: 500;
                            color: #000;

                            span {
                                color: #ca5261;
                                font-weight: 600;
                            }

                            .cupCodeText {
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .couponCodeBottom {
                    margin-top: 12px;

                    .couponApplySec {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;

                        img {
                            width: 22px;
                            max-width: 100%;
                            margin-right: 8px;
                        }

                        .couponText {
                            font-weight: 500;
                            color: #000;
                        }
                    }

                    .arrowBtn {
                        button {
                            padding-right: 0;
                            justify-content: end;

                            &:focus {
                                outline: none;
                                border: none;

                            }

                            &:hover {
                                background-color: transparent;
                            }

                            &:active {
                                outline: none;
                                box-shadow: none;
                                border: none;
                            }

                            &:focus-visible {
                                outline: none;
                                border: none;
                            }

                            svg {
                                color: #242424;
                            }
                        }
                    }
                }
            }

            &.billDetails {
                .billDetailsTitle {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000;
                }

                .detailList {
                    margin-top: 1rem;

                    .listItem {
                        border-bottom: 2px solid #eaeeff;
                        padding-bottom: 10px;
                        padding-top: 10px;

                        &:first-child {
                            padding-top: 0;
                        }

                        &:last-child {
                            border-bottom: 0;
                            padding-bottom: 0;
                        }

                        p {
                            span {
                                font-weight: 600;
                            }
                        }

                        .totalAmountCut {
                            text-decoration: line-through;
                            font-weight: 600;
                        }

                        .couponCode {
                            color: #ca5261;
                            font-weight: 600;

                            span {
                                text-transform: uppercase;
                            }
                        }

                        .couponCodeDiscount {
                            color: #ca5261;
                            // text-decoration: line-through;
                            font-weight: 600;
                        }

                    }
                }
            }

            &.prasadMbAdd {
                @media (max-width: 900px) {
                    margin-bottom: 5rem;
                }
            }

            &.userDetails {
                .userDetailsTitle {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000;
                }

                .userDetailList {
                    margin-top: 1rem;

                    p {
                        border-bottom: 2px solid #eaeeff;
                        padding-bottom: 10px;
                        padding-top: 10px;

                        &:first-child {
                            padding-top: 0;
                        }

                    }
                }

                .mailAcceptDetail {
                    padding-top: 10px;

                    .checkBox {
                        padding: 0;
                    }
                }
            }

            &.cancellationPolicy {

                //For Payment BTN Spacing
                @media (max-width: 900px) {
                    margin-bottom: 5rem;
                }

                .cancelPolicyTitle {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000;
                }

                .policyText {
                    margin-top: 10px;

                }
            }
        }

        .paymentBtn {
            margin-top: 1rem;
            padding: 1rem;
            border-radius: 20px 20px 0 0;
            background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 33%, hsla(0, 0%, 100%, 0.5) 50%, rgba(0, 0, 0, 0) 66%), linear-gradient(-45deg, #d8ad1d, #d56939);
            background-size: 250% 250%, 100% 100%;
            animation: gradient 15s ease infinite;
            z-index: 1000;

            p {
                color: #fff;
                font-size: 18px;
                font-weight: 600;
            }

            button {
                background-color: #fff;
                box-shadow: none;
                border-radius: 30px;
                color: #d56939;
                font-size: 16px;
                font-weight: 600;
                padding: 8px 24px;
                text-transform: uppercase;
            }

            @media (min-width: 1200px) {
                margin-bottom: -20px;
            }
        }

    }

    // --- For Prasad Address --- //
    .cardDesign {
        margin-top: 1rem;
        background-color: #fff;
        padding: 1rem;
        border-radius: 20px;

        p {
            font-size: 14px;
        }

        &.addressSce {
            .addressTitle {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            .addressText {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                //margin-top: 10px;

                svg {
                    font-size: 30px;
                    color: #d56939;
                    margin-right: 10px;
                }

                .areaInfo {
                    font-weight: 500;
                    color: #000;
                }

                .locationInfo {
                    color: #626367;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }

                button {
                    padding-right: 0;
                }
            }

            .arrowBtn {
                button {
                    padding-right: 0;
                    justify-content: end;

                    &:focus {
                        outline: none;
                        border: none;
                    }

                    &:hover {
                        background-color: transparent;
                    }

                    &:active {
                        outline: none;
                        box-shadow: none;
                        border: none;
                    }

                    &:focus-visible {
                        outline: none;
                        border: none;
                    }

                    svg {
                        color: #242424;
                    }
                }
            }
        }
    }

    .logoutCartArea {
        padding: 30px;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 1rem;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 300px) and (max-width: 767px) {
            display: inline-block;
            padding: 20px;
            width: 100%;
        }

        @media (min-width: 900px) and (max-width: 1353px) {
            display: inline-block;
            padding: 20px;
            width: 100%;
        }

        .logoutCartAreaLeft {
            p {
                margin-bottom: 0;
                color: #6c6c6c;
                line-height: 24px;

                @media (min-width: 300px) and (max-width: 767px) {
                    margin-bottom: 15px;
                }

                @media (min-width: 900px) and (max-width: 1353px) {
                    margin-bottom: 15px;
                }
            }
        }

        .logoutCartAreaRight {
            button {
                border: none;
                padding: 10px 16px;
                background: linear-gradient(-45deg, #d66f35, #d8a820, #d66f35, #d8a820);
                background-size: 400% 400%;
                animation: gradient 15s ease infinite;
                border-radius: 40px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }
    // --- Prasad Address End --- //

    @media (max-width: 900px) {
        .paymentBtn {
            bottom: 70px !important;
            position: fixed !important;
            width: calc(100% - 40px) !important;
        }
    }

    @media (min-width: 330px) and (max-width: 400px) {
        .paymentBtn {
            p {
                font-size: 16px !important;
            }

            button {
                font-size: 14px !important;
            }
        }
    }

    // ----- Puja Booking Checkout Cart END SCSS -----
}

// For ADD Optional Data
.optionalDetailsModal {
    .innerContent {
        .formDescription {
            color: #666666;
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #5f5f5f;
        }

        .formField {
            margin-bottom: 0.8rem;
        }

        .titleBlock {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 6px;
        }
    }

    .saveConBtn {
        width: 100%;
        background-color: #d56939;
        color: #fff;
        padding: 8px;
        margin: 10px 15px;

        &:hover {
            background-color: #9b4a28;
        }
    }
}




.menuItem {
    span {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        margin-right: 0.5rem;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: 767px) {
    .saveAndContinueBtn {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        position: relative;
        height: 60px !important;
        bottom: -12px;
    }

    .chadhavaAddOnsItems {
        .addOnsSlider {
            .carousel-slider {
                .slider-wrapper {
                    ul {
                        &.slider {
                            width: 50% !important;
                        }
                    }
                }

            }

        }
    }

    .AddDevoteeDetails {
        .addDevotee {
            &.paymentBlock {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                padding-bottom: 1.2rem;
                position: relative;
                bottom: -12px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .chadhavaAddOnsItems {
        .addOnsSlider {
            .carousel-slider {
                .slider-wrapper {
                    ul {
                        &.slider {
                            width: 33.333% !important;
                        }
                    }
                }

            }

        }
    }
}