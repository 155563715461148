.overViewText1 {
    // border-bottom: 3px solid #fff;
    // padding-bottom: 1rem;

    p {
        &:first-child {
            margin-bottom: 1rem;
        }

        &:last-child {
            font-size: 16px !important;
            white-space: pre-line;
        }

        &:nth-child(2) {
            font-size: 16px !important;
        }
    }

    
}

.readMoreLessBtn {
    box-shadow: none !important;
    padding: 0 !important;
    font-size: 12px !important;
    border-bottom: 1px solid #d56939 !important;
    border-radius: 0 !important;
    justify-content: left !important;

    &:hover {
        box-shadow: none !important;
        background-color: transparent !important;
    }
}

.prasadFaq {
    padding-top: 1rem;
    border-top: 3px solid #fff;
    margin-bottom: 60px;
    margin-top: 1.5rem;

    @media (max-width: 900px) {
        margin-bottom: 0;
    }

    .chadhavaFaqList {
        margin-top: 4px;
        font-family: Roboto;

        .accordionInfo {
            background-color: transparent;
            box-shadow: none;
        }
    }
}