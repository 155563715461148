// .languageSelectContainer {
//   background-color: #000;
//   .languageSelectText {
//     padding: 0.8rem 1rem;
//     // margin-bottom: 10px;

//     .engLanguage,
//     .hindiLanguage {
//       // padding: 10px 16px;
//       font-size: 14px;
//       font-weight: 500;
//       // background-color: #fff;
//       border-radius: 20px;
//     }
//   }

  

//   .languageSelectBtn {
//     margin-bottom: 10px;
//     button {
//       box-shadow: none;
//       border-radius: 20px;
//       text-transform: capitalize;

//     }
//   }

//   .btnGroupLang {
//     button {
//       border: none;
//       line-height: 24px;
//     }
//   }
  
// }

// .valmikiImage {
//   margin-bottom: 6px;
//   margin-left: -1.2rem;
//   margin-right: -1.2rem;
// }

// // For Responsive //
// @media (min-width: 340px) and (max-width: 402px) {
//   .languageSelectText {
//     .engLanguage {
//       span {
//         display: none;
//       }
//     }
//   }
// }

// @media (max-width: 767px) {
//   .languageSelectContainer {
//     .btnGroupLang {
//       button {
//         padding: 8px 16px;
//         line-height: 22px;
//       }
//     }
//   }
// }

.languageSelectContainer {
  background-color: #000;

  .languageSelectText {
    padding: 0.6rem 1rem;
    // margin-bottom: 10px;

    .engLanguage,
    .hindiLanguage {
      // padding: 10px 16px;
      font-size: 14px;
      font-weight: 500;
      // background-color: #fff;
      border-radius: 20px;
      line-height: 23px;
    }
  }



  .languageSelectBtn {
    margin-bottom: 10px;

    button {
      box-shadow: none;
      border-radius: 20px;
      text-transform: capitalize;

    }
  }

  .btnGroupLang {
    button {
      min-width: 30px;
      font-size: 16px;
      border: none;
      line-height: 18px;
      border: 1px solid #d56939;
      padding: 8px 16px !important;

      &:first-child {
        border-radius: 10px 0 0 10px !important;
        //border-right: none;
      }

      &:last-child {
        border-radius: 0 10px 10px 0 !important;
        // border-left: none;
      }
    }
  }

}

.valmikiImage {
  margin-bottom: 6px;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}

// For Responsive //
@media (min-width: 340px) and (max-width: 402px) {
  .languageSelectText {
    .engLanguage {
      span {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .languageSelectContainer {
    .btnGroupLang {
      button {
        padding: 8px 16px;
        line-height: 22px;
      }
    }
  }
}



