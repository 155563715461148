// .pageContainer {
//     &.pujaPage {

//         .pujaBlock {

//             .pujaLeftBlock {
//                 background-color: #fff;
//                 border-radius: 12px;
//                 padding: 15px;
//             }

//             .centerBlockTop {
//                 width: 100%;
//                 height: auto;
//                 background-color: #fff;
//                 border-radius: 12px;
//                 padding: 15px;
//                 margin: 0 auto 25px;

//                 .topField {
//                     display: flex;
//                     align-items: center;

//                     .profileImg {
//                         width: 55px;
//                         border-radius: 50%;
//                         overflow: hidden;
//                         margin-right: 10px;

//                         img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }

//                     .inputBox {
//                         width: 100%;

//                         input {
//                             border-radius: 35px;
//                             padding: 14px 16px;
//                             background-color: #ebedf6;
//                             border: none;
//                             box-shadow: none;
//                             color: rgba(0, 0, 0, 0.6);
//                         }
//                     }
//                 }

//                 .bottomField {
//                     display: flex;
//                     justify-content: space-between;
//                     width: calc(100% - 100px);
//                     margin-left: 80px;
//                     margin-top: 16px;

//                     .mediaButton {
//                         display: flex;
//                         align-items: center;

//                         a {
//                             text-decoration: none;
//                             color: #000;
//                             font-size: 14px;

//                             img {
//                                 width: 20px;
//                                 margin-right: 10px;
//                             }
//                         }
//                     }

//                     .dateButton {
//                         display: flex;
//                         align-items: center;

//                         a {
//                             text-decoration: none;
//                             color: #000;
//                             font-size: 14px;

//                             img {
//                                 width: 18px;
//                                 height: 18px;
//                                 margin-right: 10px;
//                             }
//                         }
//                     }

//                     .writePostButton {
//                         display: flex;
//                         align-items: center;

//                         a {
//                             text-decoration: none;
//                             color: #000;
//                             font-size: 14px;

//                             img {
//                                 width: 20px;
//                                 height: 15px;
//                                 margin-right: 10px;
//                             }
//                         }
//                     }
//                 }
//             }

//             .pujaCenterBlock {
//                 .pujaCardBox {
//                     width: 100%;
//                     height: auto;
//                     border-radius: 12px;
//                     background-color: #fff;
//                     padding: 0;
//                     overflow: hidden;
//                     margin-bottom: 20px;

//                     img {
//                         width: 100%;
//                         height: 448px;
//                         object-fit: cover;
//                     }

//                     .card-body {
//                         min-height: 120px;
//                         padding: 1rem;

//                         .title {
//                             color: #901414;
//                             font-size: 16px;
//                             font-weight: bold;
//                             text-transform: uppercase;
//                             margin: 0;
//                             text-align: left;
//                         }

//                         .content {
//                             display: flex;
//                             align-items: center;
//                             justify-content: space-between;
//                             padding: 0.5rem 0 0 0;

//                             p {
//                                 color: #000;
//                                 font-size: 14px;
//                                 font-weight: 600;
//                                 line-height: 1.5;
//                                 margin: 0;
//                                 text-align: left;
//                             }

//                             a {
//                                 text-transform: uppercase;
//                                 text-decoration: none;
//                                 color: #fff;
//                                 background-color: #d56939;
//                                 padding: 8px 15px;
//                                 border-radius: 20px;
//                                 font-size: 14px;
//                                 font-weight: 600;
//                                 display: inline-block;
//                                 transition: all 0.7s ease;

//                                 &:hover {
//                                     background-color: #b14a1e;
//                                 }
//                             }
//                         }
//                     }

//                     &:last-child {
//                         margin-bottom: 0;
//                     }
//                 }
//             }

//             .pujaRightBlock {
//                 background-color: #fff;
//                 border-radius: 12px;
//                 padding: 15px;
//             }
//         }
//     }
// }

// Puja Info
.pageContainer {
    &.pujaPage {
        .pujainfoCenterBlock {
            .pujaCard {
                p {
                    // font-size: 18px !important;
                    // font-weight: 700;
                    //margin-bottom: 1rem;
                }

                .pujaImgBlock {
                    border-radius: 38px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        margin: auto;
                    }
                }

                .pujaDescription {
                    .nameLocPrice {
                        margin-bottom: 1rem;

                        ul {
                            li {
                                padding: 0;

                                .squreShape {
                                    border-radius: 0;
                                    background-color: transparent;

                                    &.temple {
                                        img {
                                            width: auto;
                                            height: 34px;
                                        }
                                    }

                                    &.location {
                                        img {
                                            width: 22px;
                                            height: auto;
                                        }
                                    }

                                    &.pricetag {
                                        img {
                                            width: 24px;
                                            height: auto;
                                        }
                                    }
                                }

                                span {
                                    font-size: 16px;
                                    color: #434242;
                                    font-weight: 500;
                                }

                                &:last-child {
                                    span {
                                        display: inline-block;
                                        padding: 6px 12px;
                                        border-radius: 20px;
                                        //background-color: #ca5261;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }

                    .overViewText {
                        p {
                            font-size: 16px !important;
                        }
                    }
                }
            }

            .pujaBenefits {
                margin-top: 2rem;

                .benefitsCard {
                    margin-top: 1rem;
                    margin-bottom: 1rem;

                    .benefitCardImg {
                        border-radius: 20px;
                        overflow: hidden;
                        margin-bottom: 1rem;

                        img {
                            width: 100%;
                        }
                    }

                    p {
                        &:first-child {
                            margin-bottom: 1rem;
                        }
                    }

                    p {
                        &:last-child {
                            font-size: 16px !important;
                        }
                    }
                }
            }
        }
    }
}


// For Responsive
@media (min-width: 360px) and (max-width: 767px) {

    .pageContainer.pujaPage {
        .pujaBlock {

            .pujaLeftBlock,
            .pujaRightBlock {
                display: none;
            }

            .pujaCenterBlock {

                .pujaCardBox {
                    margin-bottom: 15px;

                    img {
                        height: 210px;
                    }
                }
            }
        }
    }
}



// .... Puja Details Card ....
.pageContainer {
    &.templePage {
        .templeBlock {
            .templeCenterBlock {
                .pujaAreaCard {
                    width: calc(50% - 1rem);
                    height: auto;
                    border-radius: 12px;
                    background-color: #fff;
                    padding: 15px;
                    overflow: hidden;
                    float: left;
                    margin: 0.5rem;
                    min-height: 420px;
                    //margin-bottom: 20px;

                    .selectPujaDes {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        line-height: 1.4;
                        max-height: 4.5em;
                        text-align: justify;
                        margin-bottom: 15px;
                    }

                    .cardTopPart {
                        margin-bottom: 20px;

                        .cardTitle {
                            p {
                                margin-bottom: 0;
                                font-weight: bold;
                            }
                        }
                    }

                    .pujaImg {
                        margin-bottom: 10px;
                        position: relative;
                        max-height: 250px;

                        img {
                            width: 100%;
                            height: 30vh;
                            object-fit: cover;
                            border-radius: 20px;
                        }

                        .devoteeBadge {
                            width: auto;
                            height: auto;
                            padding: 8px 25px 8px 15px;
                            position: absolute;
                            top: auto;
                            left: 0;
                            bottom: 0;
                            background-color: rgba($color: #000, $alpha: 0.8);
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 16px;
                            border-radius: 0 20px 0 20px;

                            .badgeIcon {
                                img {
                                    width: 20px;
                                    max-width: 100%;
                                    height: auto;
                                    margin-right: 5px;
                                }
                            }

                            .badgeText {
                                p {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .card-body {
                        padding: 0 8px;

                        .pujaDescription {
                            margin-bottom: 8px;
                            margin-left: -6px;
                            position: relative;

                            ul {
                                li {
                                    padding: 0;

                                    .squreShape {
                                        border-radius: 0;
                                        background-color: transparent;

                                        &.temple {
                                            img {
                                                width: auto;
                                                //height: 34px;
                                                height: 28px;
                                            }
                                        }

                                        &.location {
                                            img {
                                                //width: 22px;
                                                width: 19px;
                                                height: auto;
                                            }
                                        }

                                        &.pricetag {
                                            img {
                                                width: 24px;
                                                height: auto;
                                            }
                                        }
                                    }

                                    span {
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 1;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: normal;
                                        line-height: 1.5;
                                        max-height: 4.5em;
                                        font-size: 14px;
                                        color: #181818;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }

                        .selectPujaOverview {
                            font-weight: bold;
                            margin-bottom: 12px;
                        }

                        .cardLastBtn {
                            width: 100%;
                            box-shadow: none;
                            border-radius: 6px;
                            padding: 8px 0;
                            font-size: 14px;

                            @media (min-width: 340px) and (max-width: 390px) {
                                font-size: 13px;
                            }

                            svg {
                                font-size: 23px;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &.pujaBlock {
                    display: block;
                }

                .loaderContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 80vh;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1000;
                }                  
            }

            .bottomBlogContent {
                width: 66%;
                margin: 0 auto;
                padding-top: 2rem;

                @media (max-width: 900px) {
                    width: 100%;
                }
            }
        }
    }
}


// ---- Responsive
@media (max-width: 1200px) {
    .pageContainer {
        &.templePage {
            .templeBlock {
                .templeCenterBlock {
                    .pujaAreaCard {
                        width: 100%;
                        margin: 0;
                        margin-bottom: 15px;
                        min-height: auto;

                        .pujaImg {
                            img {
                                height: 20vh;
                            }
                        }
                    }
                }
            }
        }
    }
}

// .... Puja Details Card End ....



// .... Promotional Puja Page ....
.pageContainer {
    &.promotionalPujaPage {
        .templeBlock {
            .promoPujaCenterBlock {
                position: relative;

                .promoPujaBanner {
                    width: 100%;
                    height: 50vh;
                    // background-image: url(../../assets/promo-puja-banner.png);
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: relative;
                    border-radius: 12px;
                    overflow: hidden;
                    background-color: #fff;
                    margin-bottom: 1rem;
                }

                .shareIcon {
                    cursor: pointer;
                    position: absolute;
                    top: 24px;
                    right: 24px;

                    svg {
                        background-color: #fff;
                        border-radius: 50%;
                        padding: 3px;
                        font-size: 26px;
                        color: #d56939;
                        transform: scaleX(-1);
                    }
                }

                .pujaBookingCloseSec {
                    .bookingCloseTitle {
                        font-size: 14px;
                        font-weight: 500;
                        color: #181818;
                    }

                    .bookingCloseTimer {
                        margin-top: 10px;
                        margin-bottom: 6px;

                        p {
                            font-size: 16px;
                            font-weight: 500;
                            color: #af4c1f;
                            background-color: #fad9ca;
                            padding: 8px 16px;
                            border-radius: 4px;

                            @media (min-width: 360px) and (max-width: 400px) {
                                font-size: 12px !important;
                            }
                        }
                    }
                }

                .pujaBookingTitle {
                    margin: 35px 0;
                    width: 100%;
                    display: inline-block;

                    p {
                        text-align: center;
                        color: #d56939;
                        font-weight: bold;
                        font-size: 20px;
                    }

                    .textBottomLiner {
                        margin: 0 10px;

                        img {
                            width: 100%;
                            margin-top: -46px;
                        }
                    }
                }

                .templeCenterBlock {
                    .pujaAreaCard {
                        width: calc(50% - 16px);
                        height: auto;
                        border-radius: 12px;
                        background-color: #fff;
                        padding: 15px;
                        overflow: hidden;
                        float: left;
                        margin: 0.5rem;
                        min-height: auto;
                    }
                }
            }
        }
    }
}

// ----- For Responsive Purpose -----
@media (min-width: 360px) and (max-width: 767px) {
    .pageContainer {
        &.promotionalPujaPage {
            .templeBlock {
                .promoPujaCenterBlock {
                    .promoPujaBanner {
                        //height: 25vh;
                        height: auto;
                    }

                    .shareIcon {
                        top: 12px;
                        right: 12px;
    
                        svg {                        
                            font-size: 22px;                       
                        }
                    }

                    .pujaBookingCloseSec {
                        .bookingCloseTimer {
                            p {
                                font-size: 14px;
                            }
                        }
                    }

                    .pujaBookingTitle {
                        p {
                            font-size: 16px;
                        }

                        .textBottomLiner {
                            margin: 0 4px;

                            img {
                                margin-top: -40px;
                            }
                        }
                    }

                    .pujaCardBox {
                        .pujaImg {
                            img {
                                height: 15vh;
                                border-radius: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 1200px) {
    .pageContainer {
        &.promotionalPujaPage {
            .templeBlock {
                .promoPujaCenterBlock {
                    .pujaBookingTitle {
                        margin: 10px 0;
                    }

                    .templeCenterBlock {
                        .pujaAreaCard {
                            width: 100%;
                            margin: 0;
                            margin-bottom: 15px;
                            min-height: auto;
                        }
                    }
                }
            }
        }
    }
}

// .... Promotional Puja Page End ....

// Upcoming Puja ========= //
@media (max-width: 399px) {
    .promoPujaCenterBlock {
        .promoPujaBanner {
            width: 100% !important;
            height: auto !important;
        }
    }
}
