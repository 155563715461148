.chatBackgroundInfo {
    padding: 1.2rem;
    min-height: calc(100vh - 220px);
    padding-top: 0;
    position: relative;
    //padding-top: 60px;

    .languageSelectContainer {
        //width: 100%;
        //position: absolute;
        //margin: auto;
        //top: 0;
        margin-left: -1.2rem;
        margin-right: -1.2rem;
        margin-top: 3rem;
        left: 0;
        right: 0;
        background-color: #ffdfd1;
        border-top: 1px solid #f9cfbc;
        border-bottom: 1px solid #f9cfbc;
    }

    .newImageBtn {
        //text-transform: capitalize;
        border-radius: 25px;
        padding: 6px 15px;
        box-shadow: none;
        font-weight: 500;
        color: #fff;
        overflow: hidden;
        position: relative;
        background: linear-gradient(90deg, #d56939, #f49e49, #d56939, #d56939);
        background-size: 300% 100%;
        animation: gradientLoop 5s linear infinite;
        margin-bottom: 11px;

    &:hover {
      box-shadow: none;
      background-color: #005bb5;
    }
    }
}


// ========= Responsive SCSS Start ========= //



@media (min-width: 400px) and (max-width: 768px) {
    .chatBackgroundInfo {
        height: calc(100vh - 144px);
    }
}

@media (max-width: 399px) {
    .chatBackgroundInfo {
        height: calc(100vh - 144px);
    }
}

// ========= Responsive SCSS End ========= //