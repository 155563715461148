.reportTableSec {
    width: auto !important;
    height: auto !important;
    margin-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 0 !important;

    table {
        background-color: #fff;

        thead {
            tr {
                th {
                    border-right: 1px solid #e0e0e0;
                    font-size: 12px;
                    padding: 7px;
                    font-weight: 600;

                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }

        tbody {
            tr {
                // &:first-child td {
                //     color: #d56939;
                //     font-weight: 500;
                // }

                td {
                    border-right: 1px solid #e0e0e0;
                    font-size: 12px;
                    padding: 7px;
                    border-top: 1px solid #e0e0e0;
                }
            }
        }
    }
}

.reportTableTitle {
    color: #d56939;
    font-weight: 500 !important;
    margin: 10px 0 !important;
    text-transform: capitalize;
}