.kundliMatchingFormInfo {
  background-color: #fff;
  padding: 1rem 1.2rem 1.5rem;
  //border-radius: 12px;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  margin-bottom: 2rem;

  .formTitle {
    color: #181818;
    font-size: 18px;
    font-weight: 600;

    span {
      color: #d56939 !important;
      margin-left: 6px;
    }
  }

  .girlBirthInfo {
    padding-bottom: 20px;
  }

  .boyBirthInfo {
    padding-top: 5px;
  }

  .kundliDivider {
    text-align: center;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 100vw;
      height: 4px;
      background-color: #f1f0f5;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      position: relative;
      z-index: 1;
      width: 40px;
      max-width: 100%;
    }
  }

  // ---- Common for all ----
  .birthInfoTitle {
    margin-top: 8px;

    img {
      width: 28px;
      max-width: 100%;
    }

    p {
      color: #d56939;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .innerFormDetails {
    margin-top: 6px;

    label {
      color: #626367;
      font-size: 14px;

      span {
        color: red;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    input {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }

    button {
      &:hover {
        background-color: transparent;
      }

      svg {
        font-size: 22px;
      }
    }

    .genderSelection {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .generateBtn {
    margin-top: 28px;
    width: 100%;
    box-shadow: none;
    padding: 8px 16px;
    background: linear-gradient(to right, #ff5858, #ff9e41);
    transition: 0.3s ease;

    &.Mui-disabled {
      color: #fff;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover {
      box-shadow: none;
      background: linear-gradient(to right, #ff4949, #ff8b2d);
    }

    span {
      margin-left: 4px;

      svg {
        font-size: 22px;
      }
    }
  }
}