.pujaAreaCard {
    width: 100%;
    height: auto;
    border-radius: 12px;
    background-color: #fff;
    padding: 15px;
    overflow: hidden;
    margin-bottom: 20px;
    min-height: auto;

    .selectPujaDes {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 1.4;
        max-height: 4.5em;
        text-align: justify;
        margin-bottom: 15px;
        min-height: 3rem;
    }

    .pujaImg {
        margin-bottom: 10px;
        position: relative;
        max-height: 216px;
        overflow: hidden;
        border-radius: 20px;

        img {
            width: 100%;
            height: 30vh;
            object-fit: cover;
            border-radius: 20px;
        }

        .devoteeBadge {
            width: auto;
            height: auto;
            padding: 8px 25px 8px 15px;
            position: absolute;
            top: auto;
            left: 0;
            bottom: 0;
            background-color: rgba($color: #000, $alpha: 0.8);
            color: #fff;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            border-radius: 0 20px 0 20px;

            .badgeIcon {
                img {
                    width: 20px;
                    max-width: 100%;
                    height: auto;
                    margin-right: 5px;
                }
            }

            .badgeText {
                p {
                    font-size: 14px;
                }
            }
        }
    }

    .card-body {
        padding: 0;

        .cardTitle {
            margin-top: 18px;

            p {
                margin-bottom: 0;
                font-weight: bold;
                font-size: 18px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                color: #181818;
                display: -webkit-box;
                line-height: 1.5;
                max-height: 4.5em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .mainPujaTitle {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                line-height: 1.5;
                max-height: 4.5em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .subTitle {
                color: #5a5a5a;
                font-weight: 500;
                opacity: 0.8;
                font-size: 14px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                line-height: 1.5;
                max-height: 4.5em;
            }
        }

        .pujaDescription {
            margin-bottom: 8px;
            margin-left: -6px;
            position: relative;

            ul {
                li {
                    padding: 0;

                    .squreShape {
                        border-radius: 0;
                        background-color: transparent;
                        width: 100%;
                        height: auto;
                        display: block;

                        &.temple {
                            img {
                                width: auto;
                                height: 28px;
                                margin-left: 7px;
                            }
                        }

                        &.location {
                            img {
                                width: 19px;
                                height: auto;
                                margin-left: 4px;
                            }
                        }

                        &.pricetag {
                            img {
                                width: 24px;
                                height: auto;
                            }
                        }
                    }

                    span {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        line-height: 1.5;
                        max-height: 4.5em;
                        font-size: 14px;
                        color: #181818;
                        font-weight: 500;
                    }
                }
            }
        }

        .selectPujaOverview {
            font-weight: bold;
            margin-bottom: 12px;
        }

        .pujaStartPrice {
            p {
                color: #5a5a5a;
                font-size: 12px;
                display: flex;
                align-items: baseline;
                gap: 5px;

                @media (min-width: 340px) and (max-width: 390px) {
                    font-size: 10px;
                }
            }

            .rupeeSign {
                color: #181818;
                font-size: 16px;
                margin-left: 4px;
                margin-right: 2px;

                @media (min-width: 340px) and (max-width: 420px) {
                    font-size: 14px;
                }
            }

            .priceAmount {
                color: #181818;
                font-size: 22px;
                font-weight: 500;

                @media (min-width: 340px) and (max-width: 420px) {
                    font-size: 18px;
                }
            }
        }

        .cardLastBtn {
            width: 100%;
            align-items: flex-start;
            box-shadow: none;
            border-radius: 6px;
            padding: 8px 0;
            font-size: 14px;
            background: linear-gradient(90deg, #db4250, #f49e49);
            border-radius: 6px;
            box-shadow: none;
            transition: .3s ease;

            @media (min-width: 340px) and (max-width: 390px) {
                font-size: 13px;
            }

            @media (min-width: 391px) and (max-width: 430px) {
                font-size: 14px;
            }

            &:hover {
                background: linear-gradient(90deg, #ff4949, #ff8b2d);
            }
            &.Mui-disabled {
                background: #0000001f !important;
            }

            span {
                margin-left: 2px;

                svg {
                    font-size: 23px;

                    @media (min-width: 340px) and (max-width: 430px) {
                        font-size: 21px;
                    }
                }
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}



// Responsive SCSS ============================================= //

@media (max-width: 1200px) {
    .pujaAreaCard {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
        min-height: auto;

        .pujaImg {
            img {
                height: 20vh;
            }
        }
    }
}

@media (min-width: 1200px) {
    .pujaAreaCard {
        .card-body {
            .cardTitle {
                .subTitle {
                    height: 45px;
                }
            }
        }
    }
}