.aiGurujiModal {
    & .MuiDialog-container {
        justify-content: flex-end;
    }

    & .MuiPaper-root {
        width: 500px;
        height: 90vh;
        margin: 0;
        margin-right: 20px;
        border-radius: 8px;
        background-color: #F1F0F5;
        box-shadow: -5px 6px 14px -1px rgb(0 0 0 / 75%);
        position: relative;

        @media (max-width: 768px) {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            margin-right: 0;
            border-radius: 0;
        }
    }

    .aiGurujiModalContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 !important;
    }
}