.aiGurujiHeader {
    width: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // height: auto;
    position: sticky;
    top: 0;
    padding: 1rem 1.2rem;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;

    .aiGurujiHeaderContent {
        .aiGurujiHeaderLeft {
            .headerLeftInfo {
                .topCloseIconBtn {
                    background-color: transparent;
                    border: none;
                    border-radius: 50%;
                    padding: 2px;
                    transition: background-color 0.3s ease, color 0.3s ease;

                    .MuiSvgIcon-root {
                        // color: #d65758;
                        transition: color 0.3s ease;
                        font-size: 22px;
                    }

                    &:hover {
                        background-color: #f13a3a;
                        border: none;

                        .MuiSvgIcon-root {
                            color: #fff;
                        }
                    }
                }

                .leftTitle {
                    font-size: 18px;
                    font-weight: 500;
                    margin-left: 10px !important;

                    small {
                        font-size: 14px;
                        color: #767676;
                        font-weight: 500;
                    }

                    .betaText {
                        font-size: 14px;
                        color: #fff;
                        font-weight: 500;
                        background-color: #ff9d42;
                        padding: 2px 4px;
                        border-radius: 4px;
                        margin-left: 5px;
                    }
                }
            }
        }

        .aiGurujiHeaderRight {
            .topCloseIconBtn {
                padding: 4px !important;
            }

            .langSelect {
                button {
                    min-width: 24px;
                    font-size: 14px;
                    border: none;
                    line-height: 14px;
                    border: 2px solid #d56939;
                    padding: 4px !important;
                    box-shadow: none;

                    &:first-child {
                        border-radius: 10px 0 0 10px !important;
                        border-right: none;
                    }

                    &:last-child {
                        border-radius: 0 10px 10px 0 !important;
                        // border-left: none;
                    }
                }
            }
        }
    }
}