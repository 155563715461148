.backToMain {
   display: flex;
   align-items: center;
   justify-content: end;
   padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 30px;
    position: absolute;
    right: 1rem;
    bottom: 100px;
    //left: 30px;

  @media (max-width: 767px) {
    margin-right: 20px;
    right: 1.5rem;
  }

  button {
    box-shadow: none;
    z-index: 1;
    text-transform: capitalize;
    font-weight: 400;
    padding: 4px 12px;
    border-radius: 18px;
    background-color: #000;
    border-color: #000;
    color: #fff;

    svg {
      font-size: 16px;
      margin-right: 3px;
    }

    &:hover {
      box-shadow: none;
      background-color: #000;
      border-color: #000;
      color: #fff;
    }
  }
}