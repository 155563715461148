.pageContainer {
    &.pujaSteps {
        .templeBlock {
            .pujaStepsCenterBlock {
                .pujaStepsBanner {
                    height: auto;

                    img {
                        width: 100%;
                        border-radius: 0 0 20px 20px;
                    }
                }

                .pujaStepsBody {
                    background-color: #fff;
                    padding: 1rem;
                    border-radius: 0 0 20px 20px;
                    margin-top: -12px;

                    .mainHeading {
                        color: #d56939;
                        margin: 1rem 0 0.5rem;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .templeDekhoInfo {
                        margin: 1rem 0;

                        img {
                            width: 55px;
                            max-width: 100%;
                        }
                    }

                    .securityInfo {
                        margin-bottom: 1rem;

                        span {
                            font-weight: 600;
                        }
                    }

                    .certificateImg {
                        p {
                            color: #8b8b8b;
                        }

                        img {
                            width: 75px;
                            max-width: 100%;
                        }

                        .startUpIndiaLogo {
                            width: 150px;
                        }
                    }
                }

                .whatWeOffer {
                    margin-top: 2rem;

                    .offerList {
                        border-bottom: 5px solid #fff;
                        padding-bottom: 1rem;
                        padding-Top: 1rem;

                        &:first-child {
                            padding-top: 0;
                        }

                        &:last-child {
                            border-bottom: 0;
                        }

                        img {
                            width: 90px;
                            max-width: 100%;
                        }

                        .pujaStepsImg3,
                        .pujaStepsImg4,
                        .pujaStepsImg5,
                        .pujaStepsImg6 {
                            width: 120px;
                        }

                        p {
                            width: auto;

                            span {
                                font-weight: 600;
                            }
                        }
                    }
                }

                .awesomeBtnArea {
                    text-align: center;
                    background-color: #fff;
                    padding: 1.5rem 1rem;
                    margin-top: 1rem;
                    border-radius: 10px 10px 0 0;

                    p {
                        margin-bottom: 1rem;

                        span {
                            font-weight: 600;
                        }
                    }

                    button {
                        width: 100%;
                        box-shadow: none;
                        border-radius: 6px;
                        padding: 8px 0;
                        text-transform: uppercase;
                        color: #fff;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
    }
}

// ----- Responsive Purpose -----
@media (min-width: 1200px) {
    .pageContainer {
        &.pujaSteps {
            .templeBlock {
                .pujaStepsCenterBlock {
                    .whatWeOffer {
                        .offerList {
                            p {
                                width: 45%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .pageContainer {
        &.pujaSteps {
            .templeBlock {
                .pujaStepsCenterBlock {
                    .pujaStepsBody {
                        .certificateImg {
                            img {
                                width: 65px;
                            }

                            .startUpIndiaLogo {
                                width: 140px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .pageContainer {
        &.pujaSteps {
            .templeBlock {
                .pujaStepsCenterBlock {
                    .whatWeOffer {
                        .offerList {
                            &:last-child {
                                margin-bottom: 7rem;
                            }
                        }
                    }
                    .awesomeBtnArea {
                        position: fixed;
                        bottom: 70px;
                        width: calc(100% - 40px);
                        border-radius: 20px 20px 0 0;
                        padding: 1rem;

                        p {
                            margin-bottom: 0.7rem;
                        }
                    }
                }
            }
        }
    }
}