.pageContainer {
    &.rashiPage {
        .rashiBlock {
            .pageCenterBlock {
                .rashiMainContent {
                    .rashiCard {
                        background-color: #efe9e0;
                        border-radius: 4px;
                        padding: 1.2rem 0.5rem;
                        text-align: center;
                        cursor: pointer;

                        P {
                            margin-bottom: 0;
                        }
                    }

                    .discoverRashiTitle {
                        margin-bottom: 24px;
                        border-top: 4px solid #fff;
                        padding-top: 16px;
                        margin-top: 8px;

                        img {
                            width: 30px;
                            max-width: 100%;
                        }

                        p {
                            font-weight: bold;
                            font-size: 20px;
                        }
                    }

                    .discoverRashiInput {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;

                        .calculateBtn {
                            box-shadow: none;
                            margin-top: 10px;
                            background: linear-gradient(to right, #ff5858, #ff9e41);
                            transition: 0.3s ease;

                            &:hover {
                                background: linear-gradient(to right, #ff4949, #ff8b2d);
                            }
                        }

                    }
                }
            }
        }
    }
}

// For Native View
.pageContainerNativeHoro {
    padding: 10px 20px 20px;
    background-color: #f1f0f5;
    min-height: calc(100vh - 160px) !important;
    position: relative;

    &.rashiPage {
        .rashiBlock {
            .pageCenterBlock {
                .rashiMainContent {
                    .rashiCard {
                        background-color: #efe9e0;
                        border-radius: 4px;
                        padding: 1.2rem 0.5rem;
                        text-align: center;

                        P {
                            margin-bottom: 0;
                        }
                    }

                    .discoverRashiTitle {
                        margin-bottom: 24px;
                        border-top: 4px solid #fff;
                        padding-top: 16px;
                        margin-top: 8px;

                        img {
                            width: 30px;
                            max-width: 100%;
                        }

                        p {
                            font-weight: bold;
                            font-size: 20px;
                        }
                    }

                    .discoverRashiInput {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;

                        .calculateBtn {
                            box-shadow: none;
                            margin-top: 10px;
                        }

                    }
                }
            }
        }
    }
}

//Calculate Your Sign Modal
.calculateBtnModal {
    .calcBtnModalContent {
        .selectedRashi {
            background-color: #efe9e0;
            border-radius: 4px;
            padding: 1rem 0.5rem;
            text-align: center;

            img {
                width: 100px;
                max-width: 100%;
            }

            h6 {
                font-family: "Poppins", sans-serif;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.7;
                letter-spacing: 1.49px;
                color: #322e2e;
                margin-top: 14px;
            }
        }

        .userInfo {
            margin-top: 20px;

            p {
                font-weight: 500;
                text-align: justify;
            }
        }
    }

    .calcModalBottomBtn {
        button {
            box-shadow: none;
        }
    }
}

.chooseRashi {
    // padding: 1rem;

    .rashiMainContent {
        .rshiDetails {
            .rashiBanner {

                img {
                    height: 130px;
                    width: auto;
                }

                .carousel-root {
                    .carousel {
                        &.carousel-slider {
                            background-color: #efe9e0;
                            text-align: center;
                            border-radius: 10px;
                            overflow: hidden;
                            padding: 2rem 2rem 1rem 2rem;
                            min-height: 200px;

                            .control-arrow {
                                position: absolute;
                                top: 50%;
                                margin-top: -10px;
                                background-color: #181818;
                                width: 30px;
                                height: 30px;
                                color: #fff;
                                border-radius: 50%;
                                text-align: center;
                                opacity: 1;

                                &::before {
                                    display: none;
                                }

                                &.control-prev {
                                    left: 1rem;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 8px;
                                        height: 8px;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 2px;
                                        transform: rotate(-45deg);
                                        background-color: transparent;
                                        border-top: 2px solid #fff;
                                        border-left: 2px solid #fff;
                                        margin: auto;
                                    }


                                }

                                &.control-next {
                                    right: 1rem;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 8px;
                                        height: 8px;
                                        top: 0;
                                        right: 2px;
                                        bottom: 0;
                                        left: 0;
                                        transform: rotate(45deg);
                                        background-color: transparent;
                                        border-top: 2px solid #fff;
                                        border-right: 2px solid #fff;
                                        margin: auto;
                                    }
                                }
                            }
                        }

                        .thumbs-wrapper {
                            ul {
                                padding-left: 0;
                                margin-bottom: 0;

                                li {
                                    width: 75px !important;
                                    height: 75px;
                                    overflow: hidden;
                                    text-align: center;
                                    background-color: #efe9e0 !important;
                                    border-radius: 10px;
                                    border-color: transparent;
                                    position: relative;

                                    &.selected {
                                        border-color: #be8b3f !important;
                                        border-width: 1.5px;
                                    }

                                    img {
                                        width: auto;
                                        height: 65%;
                                        margin: auto;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        right: 0;
                                        left: 0;

                                    }
                                }
                            }

                            .control-arrow {
                                position: absolute;
                                top: 50%;
                                margin-top: -10px;
                                background-color: #181818;
                                width: 20px;
                                height: 20px;
                                color: #fff;
                                border-radius: 50%;
                                text-align: center;
                                opacity: 1;
                                opacity: 0.7;

                                &::before {
                                    display: none;
                                }

                                &.control-prev {
                                    left: 0;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 6px;
                                        height: 6px;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 2px;
                                        transform: rotate(-45deg);
                                        background-color: transparent;
                                        border-top: 1.5px solid #fff;
                                        border-left: 1.5px solid #fff;
                                        margin: auto;
                                    }


                                }

                                &.control-next {
                                    right: 0;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 6px;
                                        height: 6px;
                                        top: 0;
                                        right: 2px;
                                        bottom: 0;
                                        left: 0;
                                        transform: rotate(45deg);
                                        background-color: transparent;
                                        border-top: 1.5px solid #fff;
                                        border-right: 1.5px solid #fff;
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    }
                }

                .carousel-slider {
                    .slider-wrapper {
                        ul {
                            &.slider {
                                li {
                                    .ariesRashi {
                                        min-width: 80% !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dayLang {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 1rem;

                .daysRashi {
                    display: flex;
                    gap: 1rem;
                }
            }

            .rashiDescription {
                margin-top: 2rem;

                .topHeaderInfo {
                    margin-bottom: 1rem;

                    h5 {
                        font-size: 1.125rem;
                        color: #d56939;
                        font-weight: 600;
                    }

                    .knowRashiModal {
                        button {
                            box-shadow: none;
                            background: linear-gradient(to right, #ff5858, #ff9e41);
                            transition: 0.3s ease;

                            &:hover {
                                background: linear-gradient(to right, #ff4949, #ff8b2d);
                            }
                        }
                    }
                }

                .contDetails {
                    margin-bottom: 1rem;

                    p {
                        margin-bottom: 0.4rem;
                    }
                }

                .categoryList {
                    border-top: 4px solid #fff;
                    padding-top: 34px;
                    margin-top: 26px;
                    position: relative;

                    .fullWidthBorder {
                        display: none;
                    }

                    .categoryListTitle {
                        font-weight: bold;
                    }
                }

                .shareAppAd {
                    border-top: 4px solid #fff;
                    padding-top: 34px;
                    margin-bottom: 20px;
                    position: relative;

                    .fullWidthBorder {
                        display: none;
                    }

                    img {
                        width: 100%;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// ----- Modal for discovering Rashi -----
.rashiModalInfo {
    & .MuiPaper-root {
        border-radius: 14px;
        width: 650px;
        max-width: 100%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .rashiInputSec {
        .discoverRashiModalTitle {
            margin-bottom: 1rem;

            img {
                width: 30px;
                max-width: 100%;
            }

            p {
                font-weight: bold;
                font-size: 20px;
            }

            .topCloseIconBtn {
                background-color: transparent;
                border: 2px solid #d65758;
                border-radius: 50%;
                padding: 2px;
                transition: background-color 0.3s ease, color 0.3s ease;

                .MuiSvgIcon-root {
                    color: #d65758;
                    transition: color 0.3s ease;
                    font-size: 16px;
                }

                &:hover {
                    background-color: #f13a3a;
                    border: 2px solid #f13a3a;

                    .MuiSvgIcon-root {
                        color: #fff;
                    }
                }
            }
        }

        .discoverRashiModalInput {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .calculateBtn {
                box-shadow: none;
                border-radius: 6px;
                margin-top: 30px;
                margin-bottom: 4px;
                background: linear-gradient(to right, #ff5858, #ff9e41);
                transition: 0.3s ease;

                &:hover {
                    background: linear-gradient(to right, #ff4949, #ff8b2d);
                }
            }

            input {
                font-weight: 500;

                &::placeholder {
                    font-weight: 400;
                }
            }

            label {
                color: #6a6a6a;
                padding-bottom: 4px;
            }
        }

        .calcBtnModalContent {
            .selectedRashi {
                background-color: #efe9e0;
                border-radius: 4px;
                padding: 1rem 0.5rem;
                text-align: center;

                img {
                    width: 100px;
                    max-width: 100%;
                }
            }

            .userInfo {
                margin-top: 16px;

                p {
                    font-weight: 500;
                    color: #707070;
                    //text-align: justify;

                    span {
                        font-weight: 600;
                        color: #000;
                    }
                }
            }
        }
    }
}

// ----- Rashi result Modal -----
.calcModalBottomBtn {
    width: 100%;
    padding: 10px 0 0 !important;
    margin-top: 16px;

    button {
        box-shadow: none;
    }

    .viewRashiBtn {
        background: linear-gradient(to right, #ff5858, #ff9e41);
        transition: 0.3s ease;

        &:hover {
            background: linear-gradient(to right, #ff4949, #ff8b2d);
        }
    }
}

@media (max-width: 480px) {
    .chooseRashi {
        .rashiMainContent {
            .rshiDetails {
                .rashiDescription {
                    margin-top: 1.3rem;

                    .topHeaderInfo {
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: baseline;

                        .knowRashiModal {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .rashiModalInfo {
        .rashiInputSec {
            .discoverRashiModalTitle {
                margin-bottom: 0.5rem;
            }

            .discoverRashiModalInput {
                .calculateBtn {
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .carousel .control-prev,
    .carousel .control-next {
        display: none;
    }

    .chooseRashi {
        .rashiMainContent {
            .rshiDetails {
                .rashiBanner {
                    .carousel-root {
                        .carousel {
                            .thumbs-wrapper {
                                margin: 20px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    // For Native HoroscopeDetailsView Mobile View
    .pageContainerNative {
        padding: 0 20px 20px;
        background-color: #f1f0f5;
        min-height: calc(100vh - 160px) !important;
        position: relative;

        .chooseRashi {
            .rashiMainContent {
                .rshiDetails {
                    .rashiBanner {
                        .carousel-root {
                            .carousel.carousel-slider {
                                width: calc(100% + 40px) !important;
                                margin: -20px;
                                margin-bottom: 20px;
                                border-radius: 0;
                            }
                        }
                    }

                    .rashiDescription {
                        .categoryList {
                            border-top: 0 !important;

                            .fullWidthBorder {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 4px;
                                background-color: #fff;
                                width: calc(100% + 40px);
                                margin: 0 -20px;
                                display: block !important;
                            }
                        }

                        .shareAppAd {
                            border-top: 0 !important;

                            .fullWidthBorder {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 4px;
                                background-color: #fff;
                                width: calc(100% + 40px);
                                margin: 0 -20px;
                                display: block !important;
                            }
                        }
                    }
                }
            }
        }
    }

    // For Native HoroscopeView
    .pageContainerNativeHoro {
        padding: 10px 20px 20px;
        background-color: #f1f0f5;
        min-height: calc(100vh - 160px) !important;
        position: relative;

        &.rashiPage {
            .rashiBlock {
                .pageCenterBlock {
                    .rashiMainContent {
                        .rashiCard {
                            background-color: #efe9e0;
                            border-radius: 4px;
                            padding: 1.2rem 0.5rem;
                            text-align: center;

                            P {
                                margin-bottom: 0;
                            }
                        }

                        .discoverRashiTitle {
                            margin-bottom: 24px;
                            border-top: 4px solid #fff;
                            padding-top: 16px;
                            margin-top: 8px;

                            img {
                                width: 30px;
                                max-width: 100%;
                            }

                            p {
                                font-weight: bold;
                                font-size: 20px;
                            }
                        }

                        .discoverRashiInput {
                            display: flex;
                            flex-direction: column;
                            gap: 16px;

                            .calculateBtn {
                                box-shadow: none;
                                margin-top: 10px;
                            }

                        }
                    }
                }
            }
        }
    }
}

.rashiBanner {
    .carousel-slider {
        .slider-wrapper {
            ul {
                &.slider {
                    li {
                        &:first-child {
                            &.selected {
                                min-width: 100% !important;
                            }
                        }

                        &:last-child {
                            &.selected {
                                min-width: 62% !important;
                            }
                        }

                        .carousel-item {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}