
$font-size: 16px;


.search-container {
  display: flex;
  align-items: center;
  width: 400px;
  margin: 0 auto;
}


.search-input {
  flex: 1;
  .MuiOutlinedInput-root {
    font-size: $font-size;
    border-radius: 4px;
  }

  .MuiInputAdornment-root {
    margin-right: 8px;
    color: #757575; 
  }
}
