.pageContainer {
  &.aboutView {
    .sectionHead {
      .mainTitle {
        text-align: center;
        font-size: 30px;
        font-weight: 600;

        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(to right, #d56939 0%, #d8a820 50%, #000000 100%);
          background-size: 200%;
          animation: gradientAnimation 6s ease-in-out infinite;
        }
      }
    }

    .aboutViewInfo {
      p {
        text-align: justify;
      }
      
      a {
        color: #000;
        font-weight: bold;
        text-decoration: none;
      }

      ul {
        padding: 0;
        margin-bottom: 20px;

        li {
          list-style: none;

          span {
            font-weight: 500;
          }
        }
      }
    }
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 767px) {
  .sectionHead {
    p {
      font-size: 1.3rem !important;
    }
  }
  .aboutViewInfo {
    h5 {
      font-size: 1.2rem !important;
    }
  }
}