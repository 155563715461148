.profileContent {
    max-width: 100%;
    width: 100%;
    margin: auto;
    background-color: #f1f0f5;

    .profileTopBanner {
        background-image: url(../../assets/profilebackground.jpg);
        width: 100%;
        height: 40vh;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        .profileTopContent {
            position: absolute;
            bottom: -88px;
            left: 7%;
            // transform: translateX(-50%);
            display: flex;
            align-items: center;

            img {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                border: 5px solid #fff;
                background-color: #d1d1d1;
            }

            div {
                width: 120px;
                height: 120px;
                border: 5px solid #fff;
                font-size: 24px;
                font-weight: 500;
                img {
                    border-radius: 50%;
                }
            }

            h4 {
                color: #d56939;
                padding-left: 10px;
                font-size: 30px;
                font-weight: 700;
            }
            .profileName {
                font-size: 24px;
                font-weight: 500;
                margin-left: 1rem;
            }
        }
    }

    .container {
        margin-top: 110px;
        padding-bottom: 30px;

        .profileBackground {
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #ebedf6;

            .pdWrapper {
                border-Bottom: 1px solid #e8e8e8;

                .profileDetail {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 15px;
                    cursor: pointer;

                    &:last-child {
                        border-bottom: none;
                    }

                    .profileText {
                        .pTitle {
                            font-size: 20px;
                            font-weight: 550;
                            color: #505050;
                            padding: 0;
                            margin: 0;
                        }

                        .pSubTitle {
                            font-size: 14px;
                            font-weight: 450;
                            color: #505050;
                            margin-bottom: 0;
                        }
                    }

                    .profileArrow {
                        svg {
                            color: #6f6d6d;
                        }
                    }
                }
            }
        }
        .profileOutletPersonalInfo{
          padding: 25px;
        }
        .profileOutlet {          
            .personalInfo {
                .personalInfoTitle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    p {
                        margin-bottom: 0;
                        font-weight: bold;
                    }

                    a {
                        color: #d56939;
                        font-size: 14px;
                        text-decoration: none;
                        font-weight: 600;
                        &:hover {
                            cursor: pointer;
                        }

                        i {
                            padding-right: 5px;
                        }
                    }
                }

                .personalInfoInput {
                    input {
                        padding: 10px 0 5px;
                    }

                    label {
                        top: 8px;
                    }
                }

                .updateBtn {
                    text-align: center;

                    button {
                        color: #fff;
                        box-shadow: none;
                        text-transform: uppercase;
                        background-color: #d56939;
                        padding: 10px 60px;
                        border-radius: 30px;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 2px;
                        display: inline-block;
                        margin-top: 0;
                        transition: all 0.7s ease;
                    }
                }
                .inputField {
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }

            .addressView {
                .addressViewTitle {
                    p {
                        font-weight: bold;
                    }
                }

                .divider {
                    width: 100%;
                    height: 1px;
                    background-color: #dedfe7;
                }

                .active {
                    background-color: #fff6f2;
                    border: 1px solid #ffcdb5;
                    border-color: #ffcdb5 !important;

                    &:last-child {
                        border-bottom: 1px solid #ffcdb5 !important;
                    }
                }

                .homeAddress {
                    border-bottom: 1px solid #ebedf6;
                    //padding-bottom: 10px;
                    margin: 15px 20px 20px;
                    padding: 15px;
                    //margin-top: 15px;
                    border-radius: 10px;
                    cursor: pointer;

                    &:last-child {
                        border-bottom: none;
                    }

                    .homeTitle {
                        font-weight: bold;
                        margin: 0;
                        margin-bottom: 10px;
                        padding: 0;
                        color: #d56939;
                    }

                    .homeAddressContent {
                        p {
                            margin: 0;
                            padding: 0;
                        }
                    }

                    .homeAddressBtn {
                        margin: 20px 0 15px 0;

                        img {
                            max-width: 40px;
                            margin-right: 20px;
                        }
                    }
                }

                button {
                    background: transparent;
                    color: #000;
                    font-weight: 500;
                    box-shadow: none;
                    margin: 10px;

                    img {
                        max-width: 14px;
                        margin-right: 10px;
                    }
                }
            }

            .paymentInfo {
                .paymentInfoTitle {
                    p {
                        font-weight: bold;
                    }
                }

                .cardsInfo {
                    border-bottom: 5px solid #f1f0f5;
                    margin: 20px 0;

                    .cardsTitle {
                        margin-bottom: 0px;
                        font-weight: bold;
                    }

                    .paymentDetailsChart {
                        padding: 15px 0;

                        .detailsChart {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-top: 1px solid #e8e8e8;
                            padding: 10px 0;

                            .nameAndCard {
                                display: flex;
                                align-items: center;

                                .cardImg {
                                    width: 70px;

                                    img {
                                        width: 80%;
                                        height: auto;
                                        margin-right: 15px;
                                    }

                                    &.phonePay {
                                        img {
                                            width: 40px;
                                        }
                                    }
                                }

                                p {
                                    margin-bottom: 0;
                                }
                            }

                            .conformationText {
                                display: flex;
                                align-items: center;

                                a {
                                    font-size: 14px;
                                    color: #d56939;
                                    text-decoration: none;
                                }

                                p {
                                    margin-bottom: 0;

                                    img {
                                        max-width: 15px;
                                        margin-right: 5px;
                                    }
                                }

                                i {
                                    padding-left: 10px;
                                    color: #505050;
                                }
                            }
                        }

                        .cardBtn {
                            padding: 10px 0;

                            button {
                                font-size: 14px;
                                background: transparent;
                                text-transform: capitalize;
                                color: #000;
                                font-weight: 500;
                                padding: 0;
                                box-shadow: none;
                                margin-top: 10px;
                                font-weight: 400;

                                i {
                                    color: #d56939;
                                    margin-right: 5px;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.profileInfoEdit {
    .profileEditBlock {
        width: 400px;
        max-width: 100%;
        margin: auto;
        height: 400px;
        padding: 1rem;

        .profileHeader {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 2rem;

            p {
                font-size: 18px;
                font-weight: 500;
                margin: 0;
            }
        }

        .uploadImageArea {
            width: 200px;
            height: 200px;
            margin: auto;
            border: 1px solid #d7d7d7;
            background-color: #f2f2f2;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            text-align: center;
            position: relative;

            .upImgText {
                color: #6f6d6d;
                font-size: 14px;
                font-weight: 500;

                span {
                    display: inline-block;
                    width: 100%;
                }
            }

            .profileImg {
                width: calc(100% - 2rem);
                height: calc(100% - 2rem);
                border-radius: 50%;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                img {
                    border-radius: 6px;
                }
                div {
                    margin: auto;
                    letter-spacing: -1px;
                }
            }
        }

        .profileBtnBlock {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2.5rem 0 0 0;

            button {
                margin: 0 0.5rem 0 0.5rem;
            }
        }
    }
}

/* ========= For Responsive ========= */

@media (max-width: 991px) {
    .templeApp {
        padding-bottom: 60px;
    }

    .backBtn {
        position: relative;
        top: -15px;
    }

}