.pageContainer {
    &.shaktipethaPage {
        .templeBlock {
            .shaktipethaCenterBlock {
                .shaktipethaTemple {
                    width: calc(50% - 2rem);
                    height: auto;
                    border-radius: 12px;
                    background-color: #fff;
                    padding: 15px;
                    overflow: hidden;
                    float: left;
                    margin: 0.5rem;
                    min-height: 575px;
                    //margin-bottom: 20px;
                    position: relative;

                    .onboardedBadge {
                        width: auto;
                        height: auto;
                        padding: 10px;
                        position: absolute;
                        top: 76px;
                        left: 15px;
                        bottom: auto;
                        background-color: #b136c7;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 16px;
                        text-transform: uppercase;
                        border-radius: 0 20px 20px 0;
                    }

                    .shaktipethaTop {
                        .shaktipethaTitle {
                            p {
                                text-align: left;
                                font-weight: bold;
                                margin-left: 6px;
                            }
                        }
                    }

                    .shaktipethaImage {
                        img {
                            border-radius: 14px;
                            width: 100%;
                            height: 300px;
                            margin-top: 12px;
                            object-fit: cover;
                        }
                    }

                    .shaktipethaDes {
                        .shaktiDesTop {
                            margin-top: 20px;
                            margin-bottom: 15px;

                            p {
                                margin-top: 0 !important;
                                font-weight: bold;
                                border-bottom: 1px solid #d56939;
                            }

                            .visitBtn {
                                border-radius: 20px;
                                box-shadow: none;
                                padding: 5px 20px;
                            }
                        }

                        .shortText {
                            .textInfo {
                                margin-bottom: 16px;
                            }

                            .showMoreLessBtn {
                                background-color: transparent;
                                border: none;
                                border-radius: 0;
                                color: #000;
                                border-bottom: 1px solid #d56939;
                                font-weight: bold;
                                text-transform: initial;
                                padding: 0;
                            }

                            .moreLessIconBtn {
                                justify-content: flex-start;
                                color: #000;

                                &:hover {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



// ---- For Responsive ----
@media (max-width: 1200px) {

    .pageContainer {
        &.shaktipethaPage {
            .templeBlock {
                .shaktipethaCenterBlock {
                    .shaktipethaTemple {
                        width: 100%;
                        margin: 0;
                        margin-bottom: 15px;
                        min-height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 330px) and (max-width: 767px) {
    .pageContainer {
        &.shaktipethaPage {
            .templeBlock {
                .shaktipethaCenterBlock {
                    .shaktipethaTemple {
                        .shaktipethaImage {
                            img {
                                height: 200px;
                            }
                        }
                    }
                }
            }
        }
    }
}