.templeCardBox {
    width: 100%;
    background-color: #fff;
    padding: 12px 15px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 12px;
    position: relative;
    cursor: pointer;

    .templeTop {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        .templeProfile {
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
                //border: 2px solid #d56939;
            }
        }

        .templeTitle {
            overflow: hidden;

            p {
                margin-bottom: 0;
                font-size: 15px;
                font-weight: bold;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                img {
                    width: 14px;
                    margin-left: 5px;
                }
            }

            .ratings {
                display: flex;

                i {
                    color: #d56939;
                    padding-right: 5px;
                    font-size: 14px;
                }

                p {
                    margin-bottom: 0;
                    font-weight: 400;

                    span {
                        color: #d56939;
                        padding-right: 3px;
                    }

                    .ratingCount {
                        color: #a3a3a3;
                    }
                }
            }
        }
    }

    .templeImage {
        img {
            border-radius: 20px;
            width: 100%;
            height: 300px;
            margin-top: 16px;
            object-fit: cover;
        }
        .react-multi-carousel-list{
            button {
                &.react-multiple-carousel__arrow {
                    min-width: 30px;
                    min-height: 30px;
                    &::before {
                        font-size: 14px;
                    }
                }
            }
            .react-multi-carousel-dot-list  {
                bottom: 1rem;
                li {
                    &.react-multi-carousel-dot {
                        button {
                            width: 8px;
                            height: 8px;
                            border: none;
                            border-width: 1px;
                            border-radius: 50% !important;
                            margin-left: 3px;
                            margin-right: 3px;
                        }
                    }
                    &.react-multi-carousel-dot--active {
                        button {
                            background-color: #ff6724 !important;
                            //zoom: 1.2;
                        }
                    } 
                }
            }
        }
        
    }

    .templeDescription {
        min-height: 72px;
        overflow: hidden;
        margin-top: 0.5rem;
        margin-bottom: 1rem;

        p {
            font-size: 14px;
            margin-top: 10px;
            text-align: justify;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            // opacity: 0.7;
        }
    }

    // .templeBottomBlock {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;

    //     .ratings {
    //         display: flex;
    //         align-items: center;
    //         //Changed here
    //         margin-left:20px;
    //         i {
    //             color: #d56939;
    //             padding-right: 5px;
    //         }

    //         p {
    //             font-size: 14px;
    //             color: #181818;
    //             margin: 0 auto;
    //             line-height: normal;
    //             margin-top: 2px;
               

    //             span {
    //                 color: #d56939;
    //                 padding-right: 3px;
    //                 &.ratingCount {
    //                     opacity: 0.6;
    //                     color: #181818;
    //                 }
    //             }
    //         }
    //     }

    //     .likeShare {
    //         a {
    //             padding-left: 10px;

    //             img {
    //                 width: 25px;
    //             }
    //         }
    //     }
    // }

    .imgLogo {
        position: absolute;
        top: 82px;
        right: 26px;
        bottom: auto;

        img {
            width: 30px;
            max-width: 100%;
            background-color: #fff;
            border-radius: 50%;
            padding: 5px;
            height: 30px;
            object-fit: contain;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

// Responsive SCSS ============================================= //

@media (min-width: 1200px) {
    .templeCenterBlock {
        .templeCardBox {
            height: auto;
            min-height: 422px;

            .templeImage {
                .react-multi-carousel-list {
                    ul {
                        li {
                            img {
                                height: 216px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .templeCenterBlock {
        .templeCardBox {
            height: auto;
            min-height: 422px;

            .templeImage {
                .react-multi-carousel-list {
                    ul {
                        li {
                            img {
                                height: 340px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .templeCenterBlock {
        .templeCardBox {
            height: auto;
            min-height: 422px;

            .templeImage {
                .react-multi-carousel-list {
                    ul {
                        li {
                            img {
                                height: 280px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 330px) and (max-width: 767px) {
    .templeCardBox {
        .templeImage {
            img {
                height: 230px;
            }
        }
    }
}
