.pageContainer {
    &.blogViewAllPage {
        .blogViewAllBlock {
            .viewAllContentBlock {
                .backToBlockBtn {
                    color: #d56939;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-left: 10px;
                    margin-bottom: 8px;
                }

                .viewAllTopTitle {
                    p {
                        font-size: 24px;
                        font-weight: 600;
                        margin-left: 10px;
                        margin-bottom: 8px;
                    }
                }

                .bottomPagination {
                    margin: 14px 0;

                    ul {
                        justify-content: center;
                        gap: 12px 0;

                        li {
                            &:first-child {
                                margin-right: 10px;
                            }

                            &:last-child {
                                margin-left: 10px;
                            }
                        }
                    }

                    .paginationArea {
                        display: flex;
                        justify-content: center;
                        padding: 1rem;

                        .MuiPaginationItem-root {
                            color: #d56939;
                            border: 1px solid #d56939;
                            margin: 0 4px;
                            border-radius: 50%;
                            width: 35px;
                            height: 35px;
                            // font-size: 1rem;

                            &:hover {
                                background-color: #ffece3;
                            }

                            &.Mui-selected {
                                background-color: #d56939;
                                color: white;
                                font-weight: bold;
                            }

                            &:focus {
                                outline: none;
                            }
                        }

                        .MuiPaginationItem-ellipsis {
                            font-size: 1.5rem;
                        }

                        .prevButton,
                        .nextButton {
                            width: auto !important;
                            border-radius: 4px;
                            padding: 0 12px;
                            font-size: 1rem;
                            text-transform: capitalize;
                            color: #d56939;
                            border: 1px solid #d56939;
                            background-color: transparent;
                            transition: 0.3s ease;

                            &:hover {
                                background-color: #ffece3;
                            }
                        }
                    }
                }
            }
        }
    }
}


// --- For Responsive ---
@media (max-width: 767px) {
    .pageContainer {
        &.blogViewAllPage {
            .blogViewAllBlock {
                .viewAllContentBlock {
                    .bottomPagination {
                        ul {
                            li {

                                &:first-child,
                                &:last-child {
                                    display: contents;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// --- For Native View ---

.pageContainerBlogCategoryNative {
    padding: 10px 20px 20px;
    background-color: #f1f0f5;
    min-height: calc(100vh - 160px) !important;
    position: relative;

    &.blogViewAllPage {
        .blogViewAllBlock {
            .viewAllContentBlock {
                .backToBlockBtn {
                    color: #d56939;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-left: 10px;
                    margin-bottom: 8px;
                }

                .viewAllTopTitle {
                    p {
                        font-size: 24px;
                        font-weight: 600;
                        margin-left: 10px;
                        margin-bottom: 8px;
                    }
                }

                .bottomPagination {
                    margin: 14px 0;

                    ul {
                        justify-content: center;
                        gap: 12px 0;

                        li {
                            &:first-child {
                                margin-right: 10px;
                            }

                            &:last-child {
                                margin-left: 10px;
                            }
                        }
                    }

                    .paginationArea {
                        display: flex;
                        justify-content: center;
                        padding: 1rem;

                        .MuiPaginationItem-root {
                            color: #d56939;
                            border: 1px solid #d56939;
                            margin: 0 4px;
                            border-radius: 50%;
                            width: 35px;
                            height: 35px;
                            // font-size: 1rem;

                            &:hover {
                                background-color: #ffece3;
                            }

                            &.Mui-selected {
                                background-color: #d56939;
                                color: white;
                                font-weight: bold;
                            }

                            &:focus {
                                outline: none;
                            }
                        }

                        .MuiPaginationItem-ellipsis {
                            font-size: 1.5rem;
                        }

                        .prevButton,
                        .nextButton {
                            width: auto !important;
                            border-radius: 4px;
                            padding: 0 12px;
                            font-size: 1rem;
                            text-transform: capitalize;
                            color: #d56939;
                            border: 1px solid #d56939;
                            background-color: transparent;
                            transition: 0.3s ease;

                            &:hover {
                                background-color: #ffece3;
                            }
                        }
                    }
                }
            }
        }
    }
}

// --- For Native Responsive ---
@media (max-width: 767px) {
    .pageContainerBlogCategoryNative {
        &.blogViewAllPage {
            .blogViewAllBlock {
                .viewAllContentBlock {
                    .bottomPagination {
                        ul {
                            li {

                                &:first-child,
                                &:last-child {
                                    display: contents;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}