.pageContainer {
  &.successPage {
    .successBlock {
      .successCenterBlock {
        width: 100%;
        height: auto;
        background-color: #fff;
        border-radius: 12px;
        padding: 15px;
        margin: 0 auto 25px;

        .orderSuccessPage {
          .iconContainer {
            text-align: center;

            img {
              width: 80px;
              max-width: 100%;
            }
          }

          .paymentStatus {
            margin-top: 8px;

            h5 {
              font-weight: 500;
              margin-bottom: 4px;
              color: green;
            }

            p {
              font-weight: 600;
              color: #d56939;
            }
          }

          .paymentId {
            margin-top: 15px;

            p {
              font-weight: 500;
            }
          }

          .orderInfo {
            margin-top: 20px;

            .secTitle {
              font-weight: 500;
            }
          }

          .bottomBtn {
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
  }
}