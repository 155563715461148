// Define variables for colors used in gradients
$gradient-from: #1331b2;
$gradient-30: #3d5eea;
$gradient-73: #0123ad;
$gradient-to: #000000;

// .section-head {
//   &.style-4 {
//     h2 {
//       font-size: 32px;
//       line-height: 1.4;
//       color: #000;

//       span {
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//         background-image: -webkit-gradient(
//           linear,
//           left top,
//           right top,
//           from($gradient-from),
//           color-stop(30%, $gradient-30),
//           color-stop(30%, #1864f4), // Duplicate color-stop(30%) as seen in original CSS
//           color-stop(73%, $gradient-73),
//           to($gradient-to)
//         );
//         background-image: -webkit-linear-gradient(
//           left,
//           $gradient-from 0%,
//           $gradient-30 30%,
//           #1864f4 30%, // Duplicate stop as seen in original CSS
//           $gradient-73 73%,
//           $gradient-to 100%
//         );
//         background-image: -o-linear-gradient(
//           left,
//           $gradient-from 0%,
//           $gradient-30 30%,
//           #1864f4 30%, // Duplicate stop as seen in original CSS
//           $gradient-73 73%,
//           $gradient-to 100%
//         );
//         background-image: linear-gradient(
//           to right,
//           $gradient-from 0%,
//           $gradient-30 30%,
//           #1864f4 30%, // Duplicate stop as seen in original CSS
//           $gradient-73 73%,
//           $gradient-to 100%
//         );
//       }
//     }
//   }
// }

// Common styles
.text {
  font-size: 14px;
  line-height: 1.6;
  text-align: justify;
}

// Margin classes
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

// Heading styles
h1,
h2,
h3,
h4 {
  font-weight: 700;
  margin: 0;
  line-height: 1.4;
}

// Bold text
.fw-bold {
  font-weight: 700 !important;
}

.pageContainer {
  &.termsAndCondition {
    .sectionHead {
      .mainTitle {
        text-align: center;
        font-size: 30px;
        font-weight: 600;

        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          //background-image: linear-gradient(to right, #d56939 0%, #d56939 30%, #d56939 30%, #d56939 73%, #000000 100%);
          //background-image: linear-gradient(to right, #d56939 0%, #f0a68a 100%);
          background-image: linear-gradient(to right, #d56939 0%, #d8a820 50%, #000000 100%);
          background-size: 200%;
          animation: gradientAnimation 6s ease-in-out infinite;
        }
      }
    }

    @keyframes gradientAnimation {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }

    .tAndcInfoDetails {
      p {
        text-align: justify;

        a {
          color: #000;
          font-weight: bold;
          text-decoration: none;
        }

        ul {
          padding: 0;

          li {
            list-style: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .sectionHead {
    p {
      font-size: 1.3rem !important;
    }
  }

  .tAndcInfoDetails {
    h5 {
      font-size: 1.2rem !important;
      text-align: left !important;
    }
    h6 {
      text-align: left !important;
      font-size: 1rem !important;
    }
  }
}