.chatContainer {
  .pranamBhaktSec {
    //border-bottom: 4px solid #fff;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    position: relative;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   height: 4px;
    //   background-color: #fff;
    //   transform: translateX(-2rem);
    //   width: calc(100% + 4rem);
    // }

    .pranamBhaktLeft {
      .smallValmikiImg {
        img {
          width: 40px;
          //max-width: 100%;
        }
      }

      .pranamBhaktTextArea {
        background-color: #fff;
        padding: 12px 15px;
        border-radius: 24px 24px 24px 2px;
        position: relative;
        margin-top: 3px;

        .messageText {
          font-size: 14px;
          color: #000;
          text-align: left;
        }

        .namestetimestamp {
          font-size: 10px;
          color: #8d8d8d;
        }

        
        .mgsCard {
          position: relative;
          .namestereadIcon {
            width: 14px;
            max-width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          .replyMgs {
            padding-top: 1.2rem;
            margin: 0 auto;
          }
          
        }
        .mgsTime {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .poweredByText {
        font-size: 11px;
        margin-top: 10px;
        font-weight: 500;

        span {
          color: #0092d5;
        }

        .quarkGenLogo {
          width: 15px;
          max-width: 100%;
          margin-left: 6px;
        }
      }
    }

    .pranamBhaktRightIcon {
      margin-left: 8px;

      .musicicon {
        width: 17px;
        //max-width: 100%;
        cursor: pointer;
      }
    }
  }

  .textarea {
    font-size: 12px;
    text-align: left;
    color: #555454;
  }
}