@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* ========= Global Styles ========= */
body {
    background-color: #f1f0f5;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.templeApp {
    max-width: 1920px;
    margin: auto;
}

.pageContainer {
    margin-top: 60px;
    padding: 20px;
    background-color: #f1f0f5;
    min-height: calc(100vh - 160px) !important;
    position: relative;

    .searchView {
        width: calc(100% - 1rem);
        margin: 0 0.5rem 0.5rem;
        background-color: #fff;
        border-radius: 30px;

        input {
            border-radius: 0 30px 30px 0;
            background-color: #fff;
        }
    }

    .templeCard {
        border-radius: 20px;
        overflow: hidden;
        border: none;

        .btn-primary {
            background-color: #d56939;
            color: #fff;
            border: none;
            transition: all 0.7s ease;

            &:hover {
                background-color: #b14a1e;
            }

            &.btnRound {
                border-radius: 32px;
                padding: 8px 18px;
            }
        }
    }

    .btnSecondary {
        background-color: #fff;
        color: #d56939;
    }

    .padding-0 {
        padding: 0 !important;
    }
}

.bottomFooter {
    position: relative;
    z-index: 100;
}

.cartCardDesign {
    .cartCardLt {
        p {
            color: #242424;

            span {
                font-weight: bold;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    .cartCardRt {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
    }
}

/* ========= Start Post Pop-up ========= */

.startPostBox {
    width: 744px;
    max-height: calc(100vh - 1.6rem);
    max-width: 100%;

    .postTopArea {
        display: flex;
        align-items: center;
        justify-content: space-between;


        .leftProfile {
            display: flex;
            align-items: center;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
            }

            h6 {
                margin-bottom: 0;
                font-weight: bold;

                span {
                    font-weight: 400;
                }
            }
        }

        .rightClose {
            button {
                border: none;
                background-color: transparent;
                padding: 0;

                svg {
                    color: #626367;
                }
            }
        }
    }

    .postTextArea {


        textarea {
            margin: 20px 0;
            height: 160px;
            border: none;
            box-shadow: none;
            padding: 15px;
            background-color: transparent;
            border-radius: 6px;
            border: 1px solid #ccc;
        }
    }

    .templeMapDecp {
        .googleMap {
            border-radius: 4px;
            overflow: hidden;

            iframe {
                width: 100%;
                height: 280px;
            }
        }

        .templeName {
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 15px;
            margin-bottom: 30px;
            border-radius: 0 0 4px 4px;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 8px;
            }

            h6 {
                margin-bottom: 0;
                font-weight: bold;

                p {
                    margin-bottom: 0;
                    font-weight: 400;
                }
            }
        }
    }

    .uploadImageArea {
        background-color: #fff;
        padding: 15px;
        cursor: pointer;
        border-radius: 4px;

        .upImgText {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin-bottom: 0;
                font-weight: 600;
                display: flex;
                align-items: center;

                img {
                    max-width: 25px;
                    margin-right: 10px;
                }
            }

            svg {
                padding: 0;
                color: #545558;
            }
        }
    }

    .postArea {
        margin: 20px 0;
        text-align: center;
        padding: 0 20px;

        .postBtn {
            display: inline-block;
            margin-bottom: 18px;

            button {
                background-color: #626367;
                color: #fff;
                padding: 6px 20px;
                border-radius: 20px;
                border: none;
                transition: 0.3s ease;

                &:hover {
                    background-color: #545558;
                }
            }
        }
    }
}

/* ========= Ai Guruji Floating Icon SCSS ========= */
.aiGuruFloatingIconContainer {
    position: fixed;
    bottom: 90px;
    right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 1000;

    @media (max-width: 767px) {
        bottom: 95px;
        //right: 20px;
        right: 0;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
        bottom: 130px;
        right: 0;
    }

    .gurujiAvatar {
        width: 80px;
        max-width: 100%;
        //z-index: 2;
        position: relative;

        @media (max-width: 767px) {
            width: 70px;
        }
    }

    .textSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #000;
        padding: 0 20px;
        padding-left: 22px;
        height: 76px;
        margin-left: -40px;
        //z-index: 1;

        @media (max-width: 768px) {
            height: 68px;
        }

        .liveTag {
            padding: 3px 8px;
            border-radius: 0px;
            transform: rotate(-90deg);
            margin-right: -32px;

            img {
                width: 56px;
                max-width: 100%;
            }
        }

        .textRightPart {
            width: 100px;
            margin: auto;

            .chatText {
                font-size: 14px;
                text-transform: uppercase;
                color: #fff;
                line-height: 16px;
                text-align: right;
                font-weight: bold;
                margin-bottom: 3px;
            }
    
            .onlineStatus {
                display: flex;
                align-items: baseline;
                justify-content: end;
    
                .greenDot {
                    width: 8px;
                    height: 8px;
                    background-color: #baff00;
                    border-radius: 50%;
                    margin-right: 4px;
                }
    
                .onlineCount {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #baff00;
                }
            }
        }
    }
}


/* ========= Responsive SCSS ========= */

@media (min-width: 768px) and (max-width: 991px) {
    .pageContainer {
        padding-top: 30px;
    }
}


@media (min-width: 360px) and (max-width: 767px) {

    .startPostBox {
        .postTopArea {
            align-items: flex-start;
        }

        .uploadImageArea {
            padding: 10px;

            .upImgText {
                p {
                    font-size: 12px;
                }
            }
        }

        .templeMapDecp {
            .templeName {
                padding: 10px;
                margin-bottom: 18px;

                h6 {
                    font-size: 14px;
                }
            }
        }
    }
}

//====================
.searchAutoPopulate {
    .searchTempPuja {
        .searchInput {
            width: 100%;
            margin: auto;
            position: relative;

            input {
                width: 100%;
                margin: auto;
                border-radius: 30px;
                height: 50px;
                font-size: 14px;
                padding-left: 42px;
                border: 1px solid #efefef;
            }

            .searchIcon {
                width: 20px;
                height: 20px;
                position: absolute;
                left: 12px;
                top: 12px;
                z-index: 1;

                svg {
                    color: #252425;
                }
            }
        }
    }
}

.mainCategories {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;

    ul {
        width: 100%;
        margin: auto;
        text-align: center;
        background-color: transparent;

        li {
            width: 95px;
            height: auto;
            float: left;
            padding: 10px;
            text-align: center;
            display: block;
            margin-bottom: 0.5rem;

            .imgCircle {
                width: 75px;
                height: 75px;
                border-radius: 50%;
                overflow: hidden;
                border: 2px solid #d56939;

                img {
                    width: 100%;
                    height: 100%;
                    margin: auto;
                }
            }

            .listTitle {
                text-align: center;
                margin-top: 0.5rem;

                p {
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    color: #181818;
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .pageContainer {
        .searchView {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }
    }
}

// Admin =======================
.sbscoHeadingRow {
    margin-left: 1rem !important;
    margin-right: 1rem !important;

    .sbscoBtnGroup {
        background-color: #fff;
        border: 1px solid #d7d7d7;
        border-radius: 4px;

        button {
            background-color: #fff;
            padding: 6px 12px;
            border-right: 1px solid #d7d7d7 !important;

            &:last-child {
                border-right: none !important;
            }
        }
    }
}

.orderTableCard {
    margin: 0;
    padding: 0;
}

.rangeCalendar {
    position: absolute;
    right: -17px;
    top: 18px;
    z-index: 100;
}

.btnsBlocks {
    button {
        font-size: 12px;

        span {
            svg {
                font-size: 1rem;
            }
        }
    }
}

.dateRangeBlock {
    padding: 3px 6px;
    border: 1px solid #cdd0da;
    border-radius: 3px;
    position: relative;
}

.settlementTable {
    .btnBlocks {
        button {
            font-size: 12px !important;

            span {
                svg {
                    font-size: 1rem;
                }
            }
        }
    }
}

.orderTable {
    .analyticsTable {
        table {
            tbody {
                tr {
                    td {
                        button {
                            font-size: 12px;
                            text-transform: capitalize;
                            padding: 2px 8px;
                        }
                    }
                }
            }
        }
    }

    .pagination {
        .chakra-select__icon-wrapper {
            display: none;
        }
    }
}

.settlementTable {
    .analyticsTable {
        table {
            tbody {
                tr {
                    td {
                        button {
                            font-size: 12px;
                            text-transform: capitalize;
                            padding: 2px 8px;
                        }
                    }
                }
            }
        }
    }

    .pagination {
        .chakra-select__icon-wrapper {
            display: none;
        }
    }
}

.aiGurujiModal {
    background-color: rgba(0, 0, 0, 0.2);
}

/* Global Scrollbar Styles */
* {
    /* Apply to all elements */
    ::-webkit-scrollbar {
      width: 6px; /* Thin scrollbar */
      height: 6px; /* For horizontal scroll */
    }
  
    /* Scrollbar thumb (the draggable part) */
    ::-webkit-scrollbar-thumb {
      background-color: #d56939; /* Scrollbar thumb color */
      border-radius: 10px; /* Rounded edges */
    }
  
    /* Scrollbar track (the area the thumb moves within) */
    ::-webkit-scrollbar-track {
      background: transparent; /* Transparent track */
    }
  
    /* For Firefox */
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #d56939 transparent; /* Orange thumb and transparent track */
  }
  
  /* Optional: Add hover effect to make scrollbar more visible */
  *::-webkit-scrollbar-thumb:hover {
    background-color: #d56939; /* Darker thumb color on hover */
  }