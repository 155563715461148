.tempelonboardingform {
  padding: 20px;
  display: flex;
  justify-content: center;

  .templeOnboardHeader {
    margin-bottom: 20px;

    h4 {
      font-size: 20px;
      font-weight: 600;

      button {
        padding: 0;
        margin-right: 8px;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.formContainer {
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.formHeader .MuiTypography-root {
  font-size: 24px;
  font-weight: bold;
}

.formHeader svg {
  cursor: pointer;
}

.MuiTextField-root {
  margin-bottom: 15px;

  .MuiInputLabel-root {
    font-weight: 500;
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
    font-size: 16px;
  }

  .MuiFormHelperText-root {
    font-size: 12px;
    margin-top: 5px;
  }

  .Mui-error {
    color: red;
  }
}

.MuiCheckbox-root {
  margin-right: 10px;
}

.MuiFormControlLabel-root {
  margin-bottom: 10px;
}

.Box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.imagePreviewContainer {
  display: flex;
  gap: 16px; /* Space between images */
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 8px 0;
}

.imagePreviewWrapper {
  position: relative;
  width: 100px; /* Uniform size for all images */
  height: 100px; /* Uniform size for all images */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0; /* Prevent the image box from shrinking */
}

.imagePreview {
  width: 100%; /* Fit the wrapper */
  height: 100%; /* Fit the wrapper */
  object-fit: cover; /* Scale images properly */
  max-width: 100%;
  border-radius: 8px;
}

.imagePreviewWrapper .MuiIconButton-root {
  position: absolute;
  top: 4px;
  right: 4px;
  background: rgba(255, 255, 255, 0.8);
}

.Button {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .formContainer {
    padding: 15px;
  }

  .MuiTextField-root {
    margin-bottom: 10px;
  }

  .imagePreview {
    width: 100%;
  }

  .MuiTypography-root {
    font-size: 20px;
  }

  .Box {
    flex-direction: column;
    gap: 10px;
  }

  .Button {
    width: 100%;
  }
}
