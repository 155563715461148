.pageContainer {
    //padding-top: 1rem;

    &.pujaSubCategory {
        .templeBlock {
            .pujaSubCategoryCenterBlock {
                .catgIndivisualDetails {
                    display: flex;
                    background-color: #fff;
                    border-radius: 20px;
                    padding: 1rem;
                    margin-bottom: 1.5rem;

                    .bannerImgBlock {
                        width: 50%;
                        margin-right: 1rem;

                        .pujaSubCategoryBanner {
                            width: 100%;
                            height: auto;
                            border-radius: 20px;
                            overflow: hidden;
                            background-color: #fff;
                        }
                    }

                    .categoryDescription {
                        width: calc(65% - 1rem);

                        .desTopTitle {
                            margin-top: 0;
                            margin-bottom: 8px;

                            p {
                                line-height: 18px;
                                font-weight: 600;
                            }

                        }
                    }
                }

                .categoryDescription {
                    .desTopTitle {
                        .categoryTitle {
                            font-weight: 500;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                            line-height: 1.4;
                            max-height: 4.5em;
                        }

                        .shareIcon {
                            cursor: pointer;

                            svg {
                                background: linear-gradient(-45deg, #d66f35, #d8a820, #d66f35, #d8a820);
                                background-size: 200% 200%;
                                animation: gradient 15s ease infinite;
                                border-radius: 50%;
                                padding: 3px;
                                font-size: 24px;
                                color: #fff;
                                transform: scaleX(-1);
                            }
                        }
                    }
                }

                .categoryPujaTitle {
                    text-align: center;
                    position: relative;
                    margin-bottom: 20px;
                    z-index: 2;

                    h6 {
                        font-size: 18px;
                        text-transform: uppercase;
                        color: #000;
                        font-weight: 500;
                        display: inline-block;
                        background-color: #f1f0f5;
                        padding-left: 40px;
                        padding-right: 40px;
                    }

                    &::after {
                        width: 100%;
                        height: 1px;
                        background-color: #707070;
                        content: "";
                        position: absolute;
                        top: 50%;
                        margin-top: -1px;
                        left: 0;
                        right: 0;
                        opacity: 0.2;
                        z-index: -1;
                    }
                }

                .templeCenterBlock {
                    .pujaAreaCard {
                        width: calc(50% - 16px);
                        height: auto;
                        border-radius: 12px;
                        background-color: #fff;
                        padding: 15px;
                        overflow: hidden;
                        float: left;
                        margin: 0.5rem;
                        min-height: 390px;
                    }
                }

                .noPujaItem {
                    p {
                        text-align: center;
                        background-color: #fff;
                        padding: 10px;
                        border-radius: 10px;
                        border: 1px solid #d56939;
                    }
                }

                .pujaBookingTitle {
                    margin: 35px 0;

                    p {
                        text-align: center;
                        color: #d56939;
                        font-weight: bold;
                        font-size: 20px;
                    }

                    img {
                        width: 100px;
                        margin: 0 10px;
                    }
                }

                .pujaCardBox {
                    width: 100%;
                    height: auto;
                    border-radius: 12px;
                    background-color: #fff;
                    padding: 20px;
                    overflow: hidden;
                    margin-bottom: 20px;

                    .cardTopPart {
                        margin-bottom: 20px;

                        .cardTitle {
                            p {
                                margin-bottom: 0;
                                font-weight: bold;
                            }
                        }
                    }

                    .pujaImg {
                        margin-bottom: 25px;

                        img {
                            width: 100%;
                            height: 32vh;
                            object-fit: cover;
                            border-radius: 20px;
                        }
                    }

                    .card-body {
                        .selectPujaOverview {
                            font-weight: bold;
                            margin-bottom: 16px;
                        }

                        .cardLastBtn {
                            width: 100%;
                            box-shadow: none;
                            border-radius: 6px;
                            padding: 10px 0;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}


@media (max-width: 767px) {
    .pageContainer {
        &.pujaSubCategory {
            .templeBlock {
                .pujaSubCategoryCenterBlock {
                    .catgIndivisualDetails {
                        display: block;

                        .bannerImgBlock {
                            width: 100%;
                            margin-bottom: 1rem;

                            .pujaSubCategoryBanner {
                                width: 100%;
                                max-height: 200px;
                            }
                        }

                        .categoryDescription {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

}

@media (min-width: 360px) and (max-width: 767px) {
    .pageContainer {
        &.pujaSubCategory {
            .templeBlock {
                .pujaSubCategoryCenterBlock {
                    .pujaSubCategoryBanner {
                        height: 26vh;
                        margin-bottom: 10px;
                        object-fit: contain;
                        background-color: transparent;
                        padding: 0;
                    }

                    .pujaBookingTitle {
                        p {
                            font-size: 16px;
                        }

                        img {
                            width: 50px;
                        }
                    }

                    .pujaCardBox {
                        .pujaImg {
                            img {
                                height: 15vh;
                                border-radius: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 990px) {
    .pageContainer {
        padding-top: 2rem;

        &.pujaSubCategory {
            .templeBlock {
                .pujaSubCategoryCenterBlock {
                    .pujaSubCategoryBanner {
                        height: 50vh;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}


@media (min-width: 991px) and (max-width: 1024px) {
    .pageContainer {
        padding-top: 2rem;

        &.pujaSubCategory {
            .templeBlock {
                .pujaSubCategoryCenterBlock {
                    .pujaSubCategoryBanner {
                        height: 30vh;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}


@media (max-width: 1200px) {
    .pageContainer {
        &.pujaSubCategory {
            .templeBlock {
                .pujaSubCategoryCenterBlock {
                    .templeCenterBlock {
                        .pujaAreaCard {
                            width: 100%;
                            margin: 0;
                            margin-bottom: 15px;
                            min-height: auto;
                        }
                    }
                }
            }
        }
    }
}