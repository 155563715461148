.vastuSummary {
  .summaryTitle {
    font-size: 14px;
    font-weight: 600;
    color: #d56939;
    margin-bottom: 12px;
  }

  .summaryReport {
    background-color: #fff;
    padding: 12px 14px;
    border-radius: 20px;
    margin-bottom: 24px;
    box-shadow: 0 5px 10px 0 rgba(80, 92, 98, 0.15);

    p {
      font-size: 14px;
      color: #181818;
      white-space: pre-line;
    }
  }
}