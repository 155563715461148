@media (max-width: 1200px) {
  .countryDilog {
    .countrylist {
      padding: 0 30px;
    }
  }
}

/* dilog box css */
.countryDilog {
  .topCountryDialog {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .pickCodeText {
      font-size: 30px;
      font-weight: bold;
      text-align: left;
      color: #d56939;
      line-height: 35px;
      padding: 20px 30px;
      margin-bottom: 10px;
    }

    .topCloseBtn {
      margin: 0 30px;
      background-color: transparent;
      background-color: transparent;
      border: 2px solid #d65758;
      border-radius: 50%;
      padding: 2px;
      transition: background-color 0.3s ease, color 0.3s ease;

      svg {
        color: #d65758;
        transition: color 0.3s ease;
        font-size: 20px;
      }

      &:hover {
        background-color: #f13a3a;
        border: 2px solid #f13a3a;

        svg {
          color: #fff;
        }
      }
    }
  }

  .searchContainer {
    padding: 0 30px;
    text-align: center;

    .searchWrapper {
      position: relative;

      .searchInput {
        width: 100%;
        border-radius: 12px;
        padding: 10px 10px 10px 45px;
        background-color: #f8f9fa;
      }

      .searchIcon {
        position: absolute;
        left: 14px;
        top: 15px;
        color: #424242;
      }
    }
  }

  .countrylist {
    max-height: calc(100% - 150px);
    overflow-y: auto;
    padding:  0 30px !important;
    margin: 20px 0 30px !important;

    @media (max-width: 1200px) {
      padding: 0 30px !important;
    }

    .listItemButton {
      border-radius: 15px;
      margin-bottom: 2px;
      padding: 0.8rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .flagImage {
        margin-right: 20px;
        width: 40px;
        height: 30px;
        border-radius: 5px;
        object-fit: cover;
      }

      .dialCode {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .listItemButtonSelected {
      background-color: #fff1eb;
    }
  }
}
