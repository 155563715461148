.promotionScreen {
    margin-top: 120px;
    margin-bottom: 50px;

    &::after {
        content: "";
        position: fixed;
        width: 600px;
        height: 600px;
        background-color: #f4f4f4;
        border-radius: 4rem;
        transform: rotate(45deg);
        z-index: -1;
        // left: -23rem;
        top: 6rem;
        display: none;
    }

    .leftText {
        padding-left: 90px;
        margin-top: -50px;

        .onlyText {
            .smTitle {
                color: #d56939;
                font-weight: 600;
            }

            .lgTitle {
                font-size: 50px;
                margin-bottom: 20px;
                font-weight: bold;
                line-height: 50px;

            }

            .genContent {
                color: #b6b5b5;
            }
        }

        .downloadOption {
            margin-top: 50px;

            p {
                font-weight: 600;
                margin-bottom: 0;
                color: #d56939;
            }

            .dwnldText {
                text-transform: uppercase;
                font-size: 30px;
                margin-bottom: 30px;
                color: #d56939;
                font-weight: bold;
            }
        }

        .downloadBtn {
            display: flex;
            align-items: center;

            button {
                background-color: transparent;
                border: none;
                padding: 0;

                img {
                    max-width: 200px;
                    margin-right: 10px;
                    border-radius: 12px;
                }
            }
        }
    }

    .rightImg {
        img {
            width: 530px;
            max-width: 100%;
        }
    }
}



// For Responsive
@media (min-width: 360px) and (max-width: 767px) {
    
    .promotionScreen {
        margin-top: 125px;
    }

    .promotionScreen::after {
        top: 7rem;
    }

    .promotionScreen .leftText {
        padding-left: 0;
        margin-top: 0;
    }

    .promotionScreen .leftText .onlyText h5 {
        font-size: 16px;
    }

    .promotionScreen .leftText .onlyText h1 {
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 16px;
    }

    .promotionScreen .leftText .onlyText p {
        font-size: 14px;
    }

    .promotionScreen .leftText .downloadOption {
        margin-top: 30px;
    }

    .promotionScreen .leftText .downloadOption h4 {
        margin-bottom: 16px;
    }

    .promotionScreen .leftText .downloadBtn {
        display: block;
    }

    .promotionScreen .leftText .downloadBtn button {
        margin: 5px 0;
    }

    .promotionScreen .leftText .downloadBtn button img {
        max-width: 80%;
    }

    .promotionScreen .rightImg {
        margin: 30px 0 50px;
        padding-bottom: 50px;
    }

    .promotionScreen .rightImg img {
        max-width: 330px;
    }
    .profileBlock {
        a {
            div {
                letter-spacing: -2px;
            }
        }
    }
}