.pageContainer {
    &.AIGurujiChatList {
        // .couponContainer {
        //     border-radius: 1rem;
        //     box-shadow: none;

        //     .couponHeader {
        //         display: flex;
        //         justify-content: space-between;
        //         align-items: center;
        //         margin-bottom: 1rem;

        //         h4 {
        //             font-size: 20px;

        //             button {
        //                 margin-right: 1rem;
        //             }
        //         }

        //     }

        //     .continueBtn {
        //         background: rgb(214, 111, 53);
        //         border-radius: 0.4rem;
        //         width: calc(100%);
        //         height: 40px;
        //         display: inline-block;
        //         color: #fff;
        //         margin: 1rem auto 0 auto;
        //         box-shadow: none;

        //         &:hover {
        //             background-color: #c45414;
        //         }
        //     }
        // }

        .AIGurujiChatListView {
            .gurujiChatListHeadingRow {
                justify-content: space-between;
                margin: 10px 0px 25px 0px;

                .gurujiChatListHeadingRight {
                    width: 50%;
                    display: flex;
                    justify-content: left;
                    align-items: center;

                    .gurujiChatListBtnGroup {
                        display: flex;
                        align-items: center;
                        justify-content: right;
                        margin-right: 20px;

                        .btngroupanalytics {
                            text-transform: unset;
                            white-space: nowrap;
                            padding-left: 10px;
                            padding-right: 10px;
                        
                            &.MuiButton-colorInherit {
                              border-color: #b8b8b8;
                            }
                        
                            &.MuiButton-colorPrimary {
                              border-top-color: #d56939;
                              border-bottom-color: #d56939;
                              border-left-color: #d56939;
                              border-right-color: #d56939;
                              background-color: #d56939;
                              color: #fff;
                            }
                        
                            &:first-child {
                              border-right-color: #d56939 !important;
                            }
                        
                            &:last-child {
                              &.MuiButton-colorPrimary {
                                border-left-color: #d56939;
                              }
                        
                            }
                          }

                        // button {
                        //     border-radius: 4px;
                        //     border: 1px solid #b8b8b8;
                        //     font-size: 13px;
                        //     padding: 8px 16px;
                        //     font-weight: 600;
                        //     color: #131523;
                        //     transition: 0.3s ease;

                        //     &:hover {
                        //         background-color: #dfdfdf;
                        //     }

                        //     &:first-child {
                        //         border-radius: 4px 0px 0px 4px !important;
                        //     }

                        //     &:nth-child(2) {
                        //         border-radius: 0px 0px 0px 0px !important;
                        //         border-left: none;
                        //         border-right: none;
                        //     }

                        //     &:last-child {
                        //         border-radius: 0px 4px 4px 0px !important;
                        //     }
                        // }
                    }

                    // .dateRangeBlock {
                    //     border-color: #b8b8b8;
                    //     cursor: pointer;
                    //     transition: 0.3s ease;

                    //     .dateRangeBtn {
                    //         button {
                    //             border-radius: 4px;
                    //             border: none;
                    //             background-color: transparent;
                    //             color: #181818;
                    //             box-shadow: none;
                    //             //border-color: #e2e2e3;
                    //             font-size: 14px;
                    //             font-weight: normal;
                    //         }
                    //     }
                    // }
                }

                .btnAddCoupon {
                    button {
                        background-color: #d56939;
                        border: none;
                        color: #fff;
                        padding: 8px 16px;
                        border-radius: 4px;
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 600;
                        transition: 0.3s ease;

                        &:hover {
                            background-color: #b15630;
                        }

                        svg {
                            margin-right: 6px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }




    // .sbscoBtnGroup button {
    //     border-radius: 4px;
    //     border-color: #e2e2e3;
    //     font-size: 13px;
    //     font-weight: 600;
    //     color: #131523;
    // }

    // .sbscoBtnGroup button.css-u0xzq5 {
    //     color: #fff;
    //     background-color: #2185d0;
    //     border-color: #2185d0;
    // }

    // .sbscoBtn button {
    //     border-radius: 4px;
    //     border-color: #e2e2e3;
    //     font-size: 14px;
    //     font-weight: normal;
    // }

    // .sbscoBtnGroup button:first-child {
    //     border-radius: 4px 0px 0px 4px !important;
    // }

    // .sbscoBtnGroup button:nth-child(2) {
    //     border-radius: 0px 0px 0px 0px !important;
    //     border-left: none;
    //     border-right: none;
    // }

    // .sbscoBtnGroup button:last-child {
    //     border-radius: 0px 4px 4px 0px !important;
    // }

    // .sbscoAnalyticsDBContainer {
    //     height: 100%;

    // }

    // .sbscoAnalyticsDBContainer .dbBlock {
    //     height: 36vh;
    //     border-radius: 6px;
    //     padding: 10px;
    // }





    // .couponCodeView {
    //     width: 100%;
    //     position: relative;
    //     margin-top: 50px;

    //     .MuiPaper-root {
    //         box-shadow: none;
    //     }

    //     .btnAddCoupon {
    //         position: absolute;
    //         top: -50px;
    //         right: 0;

    //         z-index: 1;

    //         button {
    //             box-shadow: none;
    //         }
    //     }

    //     .couponDataGrid {
    //         max-width: 100%;
    //         margin: auto;

    //         p {
    //             margin-top: 1rem;
    //         }
    //     }
    // }
}

// .couponDataList {
//     .couponDataGrid {
//         .MuiDataGrid-columnHeader {
//             padding-left: 16px;
//             padding-right: 16px;

//             &:focus {
//                 outline: none;
//             }
//         }

//         .MuiDataGrid-cell {
//             padding-left: 16px;
//             padding-right: 16px;
//         }

//         .MuiDataGrid-withBorderColor:focus {
//             border-color: transparent;
//         }
//     }
// }