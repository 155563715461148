.blogCardMain {
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11) !important;
    margin: 10px;

    .blogCardBanner {
        width: 100%;
        height: 22vh;
        object-fit: cover;
        padding: 10px;
    }

    .blogCardContent {
        padding: 0.5rem 0.8rem 0.6rem;

        h5 {
            font-size: 16px !important;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            height: 7vh;

            // @media (max-width: 767px) {
            //     height: auto;
            // }
        }

        p {
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            line-height: 1.5;
            max-height: 4.5em;
            height: auto;

            @media (max-width: 767px) {
                height: auto;
            }
        }
    }

    .blogCardBottom {
        width: 100%;
        display: inline-block;
        padding: 0 0.8rem 0.8rem;

        p {
            font-size: 12px;
        }

        button {
            box-shadow: none;
            background: linear-gradient(to right, #ff5858, #ff9e41);
            transition: 0.3s ease;

            &:hover {
                background: linear-gradient(to right, #ff4949, #ff8b2d);
            }
        }
    }
}


// ------ For Responsive ------
@media (min-width: 400px) and (max-width: 767px) {
    .blogCardMain {
        .blogCardBanner {
            height: 17vh;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .blogCardMain {
        .blogCardBanner {
            height: 15vh;
        }
    }
}