.pageContainer {
    &.pujaBookingPage {

        .pujaBookingBlock {

            .pujaBookingLeftBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }

            .pujaBookingCenterBlock {
                .pujaBookingTopBanner {
                    
                    position: relative;
            
                    .shareIcon {
                        cursor: pointer;
                        position: absolute;
                        top: 24px;
                        right: 24px;
     
                        svg {
                            background-color: #fff;
                            border-radius: 50%;
                            padding: 3px;
                            font-size: 26px;
                            color: #000;
                            transform: scaleX(-1);
                        }
                    }
                }

                .pujaBookingTitle {
                    margin-top: 10px;

                    p {
                        font-size: 22px;
                        font-weight: bold;
                        margin-bottom: 0;
                        padding: 20px 0;
                    }

                    ul {
                        li {
                            padding: 0;

                            .squreShape {
                                border-radius: 0;
                                background-color: transparent;

                                &.temple {
                                    img {
                                        width: auto;
                                        height: 34px;
                                    }
                                }

                                &.location {
                                    img {
                                        width: 22px;
                                        height: auto;
                                    }
                                }

                                &.rating {
                                    img {
                                        width: 22px;
                                        height: auto;
                                    }
                                }
                            }

                            span {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                line-height: 1.5;
                                max-height: 4.5em;
                                font-size: 16px;
                                color: #181818;
                                font-weight: 500;
                            }

                            p {
                                padding: 0;

                                img {
                                    width: 16px;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }

                .templeCenterBlock {
                    .pujaAreaCard {
                        width: calc(50% - 16px);
                        height: auto;
                        border-radius: 12px;
                        background-color: #fff;
                        padding: 15px;
                        overflow: hidden;
                        float: left;
                        margin: 0.5rem;
                        min-height: auto;
                    }
                }

                .pujaCardBox {
                    width: 100%;
                    height: auto;
                    border-radius: 12px;
                    background-color: #fff;
                    padding: 20px;
                    overflow: hidden;
                    margin-bottom: 20px;

                    .cardTopPart {
                        margin-bottom: 20px;

                        .cardTitle {
                            p {
                                margin-bottom: 0;
                                font-weight: bold;
                            }
                        }
                    }

                    .pujaImg {
                        margin-bottom: 10px;
                        position: relative;

                        img {
                            width: 100%;
                            height: 32vh;
                            object-fit: cover;
                            border-radius: 20px;
                        }

                        .devoteeBadge {
                            width: auto;
                            height: auto;
                            padding: 8px 25px 8px 15px;
                            position: absolute;
                            top: auto;
                            left: 0;
                            bottom: 0;
                            background-color: rgba($color: #000, $alpha: 0.8);
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 16px;
                            border-radius: 0 20px 0 20px;

                            .badgeIcon {
                                img {
                                    width: 20px;
                                    max-width: 100%;
                                    height: auto;
                                    margin-right: 5px;
                                }
                            }

                            .badgeText {
                                p {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .card-body {
                        .pujaDescription {
                            margin-bottom: 1rem;
                            margin-left: -6px;
                            position: relative;

                            ul {
                                li {
                                    padding: 0;

                                    .squreShape {
                                        border-radius: 0;
                                        background-color: transparent;

                                        &.temple {
                                            img {
                                                width: auto;
                                                height: 34px;
                                            }
                                        }

                                        &.location {
                                            img {
                                                width: 22px;
                                                height: auto;
                                            }
                                        }

                                        &.pricetag {
                                            img {
                                                width: 24px;
                                                height: auto;
                                            }
                                        }
                                    }

                                    span {
                                        font-size: 16px;
                                        color: #181818;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }

                        .selectPujaOverview {
                            font-weight: bold;
                            margin-bottom: 12px;
                        }

                        .selectPujaDes {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                            line-height: 1.5;
                            max-height: 4.5em;
                            text-align: justify;
                            margin-bottom: 30px;
                        }

                        .cardLastBtn {
                            width: 100%;
                            box-shadow: none;
                            border-radius: 6px;
                            padding: 10px 0;
                            font-size: 15px;

                            span {
                                margin-left: 4px;

                                svg {
                                    font-size: 25px;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }

            .pujaBookingRightBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }
        }
    }
}




// For Responsive
@media (min-width: 360px) and (max-width: 767px) {

    .pageContainer.pujaBookingPage {
        .pujaBookingBlock {

            .pujaBookingLeftBlock,
            .pujaBookingRightBlock {
                display: none;
            }

            .pujaBookingCenterBlock {
                //margin-top: 20px;
             
               

                .pujaCardBox {
                    margin-bottom: 15px;

                    .pujaImg {
                        img {
                            height: 15vh;
                            border-radius: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .pageContainer {
        &.pujaBookingPage {
            .pujaBookingBlock {
                .pujaBookingCenterBlock {
                    .templeCenterBlock {
                        .pujaAreaCard {
                            width: 100%;
                            margin: 0;
                            margin-bottom: 15px;
                            min-height: auto;
                        }
                    }
                }
            }
        }
    }
}