.poweredByText {
  padding: 5px 0px 5px 20px;
  background-color: #fbfbfb;

  p {
    font-size: 12px;
    color: #555454;
    font-weight: 500;

    span {
      color: #0092d5;
      text-decoration: none;
    }
  }

  img {
    width: 15px;
    height: 15px;
    max-width: 100%;
    margin-left: 5px !important;
  }
}