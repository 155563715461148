.pageContainer {
    &.couponPage {
        .couponContainer {
            border-radius: 1rem;
            box-shadow: none;

            .couponHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;

                h4 {
                    font-size: 20px;

                    button {
                        margin-right: 1rem;
                    }
                }

            }

            .continueBtn {
                background: rgb(214, 111, 53);
                border-radius: 0.4rem;
                width: calc(100%);
                height: 40px;
                display: inline-block;
                color: #fff;
                margin: 1rem auto 0 auto;
                box-shadow: none;

                &:hover {
                    background-color: #c45414;
                }
            }
        }
    }

    .topTitle {
        text-align: center;

        .adminTitleText {
            font-size: 20px;
            font-weight: 600;
            color: #d56939;
        }
    }

    .couponCodeView {
        width: 100%;
        position: relative;
        margin-top: 50px;

        .MuiPaper-root {
            box-shadow: none;
        }

        .btnAddCoupon {
            position: absolute;
            top: -50px;
            right: 0;

            z-index: 1;

            button {
                box-shadow: none;
            }
        }

        .couponDataGrid {
            max-width: 100%;
            margin: auto;

            p {
                margin-top: 1rem;
            }
        }
    }
}

.couponDataList {
    .couponDataGrid {
        .MuiDataGrid-columnHeader {
            padding-left: 16px;
            padding-right: 16px;
            &:focus {
                outline: none;
            }
        }

        .MuiDataGrid-cell {
            padding-left: 16px;
            padding-right: 16px;
        }
        .MuiDataGrid-withBorderColor:focus {
            border-color: transparent;
        }
    }
}