.pageContainerEventViewNative {
    padding: 20px;
    background-color: #f1f0f5;
    min-height: calc(100vh - 160px) !important;
    position: relative;

    &.specialPujaEvent {
        .pageCenterBlock {
            .specialPujaAddOn {
                .pujainfoCenterBlock {
                    .pujaCard {
                        p {
                            font-size: 18px !important;
                            font-weight: 700;
                            margin-bottom: 1rem;
                        }

                        .pujaCardBlock {
                            background-color: #fff;
                            border-radius: 38px 38px 10px 10px;
                            margin-bottom: 2rem;
                            padding-bottom: 0.2rem;
                        }

                        .pujaImgBlock {
                            border-radius: 30px;
                            overflow: hidden;
                            height: auto;
                            // height: 36vh;
                            @media (min-width: 330px) and (max-width: 400px) {
                                height: 22vh;
                            }
                            @media (min-width: 401px) and (max-width: 767px) {
                                height: 20vh;
                            }

                            img {
                                width: 100%;
                                // height: 100%;
                                margin: auto;
                                // object-fit: cover;
                            }
                        }

                        .pujaDescription {
                            .pujaNameInfo {
                                padding: 1rem 1.5rem;
                                padding-bottom: 0;

                                p {
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }

                    .overViewText {
                        border-bottom: 3px solid #fff;
                        padding-bottom: 1rem;

                        p {
                            &:first-child {
                                margin-bottom: 1rem;
                            }

                            &:last-child {
                                font-size: 16px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}