.pujaBookingCloseSec {
    .bookingCloseTitle {
        font-size: 14px;
        font-weight: 500;
        color: #181818;
    }

    .bookingCloseTimer {
        margin-top: 10px;
        margin-bottom: 6px;

        p {
            font-size: 16px;
            font-weight: 500;
            color: #af4c1f;
            background-color: #fad9ca;
            padding: 8px 12px;
            border-radius: 4px;

            @media (min-width: 360px) and (max-width: 378px) {
                font-size: 12px !important;
            }

            @media (min-width: 379px) and (max-width: 392px) {
                font-size: 13px !important;
            }

            @media (min-width: 393px) and (max-width: 407px) {
                font-size: 14px !important;
            }

            @media (min-width: 408px) and (max-width: 425px) {
                font-size: 15px !important;
            }
        }
    }
}