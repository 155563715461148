.pageContainer {
  &.privacyPolicy {
    .sectionHead {
      .mainTitle {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
      }
    }

    .privacyInfoDetails {
      p {
        text-align: justify;

        a {
          color: #000;
          font-weight: bold;
          text-decoration: none;
        }

        ul {
          padding: 0;

          li {
            list-style: none;
          }
        }
      }
    }
  }
}