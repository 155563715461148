.serviceSuggestionArea {
    margin: 20px 0 10px;

    .serviceText {
        font-size: 14px;
        background-color: #E5E5E5;
        font-weight: 500;
        border-radius: 12px 12px 12px 0;
        padding: 10px 14px;
        border: 1px solid #BEBEBE;
        letter-spacing: .26px;
        text-align: left;
        color: #181818;

        @media (max-width: 412px) {
            font-size: 13px;
        }
    }
}