@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.pageLeftBlock {
    p {
        font-size: 14px !important;

        &:first-child {
            font-family: "Roboto", sans-serif;
            font-size: 14px !important;
            font-weight: bold;
            letter-spacing: normal;
            text-align: left;
            color: #d56939 !important;
        }
    }

    button {
        padding: 0;
        margin-top: 16px;
        font-size: 14px;
        text-transform: none;

        &:hover {
            background-color: transparent;
        }

        svg {
            font-size: 16px;
            margin-left: 5px;
        }
    }
}