.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 60%;
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  color: #000;
  font-weight: bold;
  margin-bottom: 16px;
}

.modal-content p {
  color: #b6b5b5;
  line-height: 26px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.button-container button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #d56939;
  color: white;
}

.button-container button.appsCancelBtn {
  background-color: transparent;
  color: #d56939;
  border: 1px solid #d56939;
}

.button-container button.appsOpenBtn {
  padding: 8px 20px;
  margin-left: 14px;
}




/* // For Responsive // */
@media (min-width: 360px) and (max-width: 767px) {

  .modal-content {
    width: 100%;
  }

}


/* .downloadBtn {
  display: flex;
  align-items: center;

  button {
      background-color: transparent;
      border: none;
      padding: 0;

      img {
          max-width: 200px;
          margin-right: 10px;
          border-radius: 12px;
      }
  }
} */