.rashiCardTable {
  width: auto !important;
  height: auto !important;
  margin-right: 0 !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  border-radius: 0 !important;
  margin-bottom: 1rem !important;

  table {
    background-color: #fff;

    thead {
      tr {
        th {
          border-right: 1px solid #e0e0e0;

          &:last-child {
            border-right: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          border-right: 1px solid #e0e0e0;
          padding: 14px;
          //font-size: 12px;
          //padding: 7px;
          //border-top: 1px solid #e0e0e0;
          &:first-child {
            font-weight: bold;
            width: 70%;

            @media (max-width: 767px) {
              width: 95%;
            }
          }

          &:last-child {
            border-right: 0;
          }

          svg {
            margin-right: 4px;
            color: #383838;
            font-size: 22px;
          }

          .sunIcon {
            animation: rotate 5s linear infinite;
          }

          .moonIcon {
            animation: pulse 1.5s ease-in-out infinite;
          }

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes pulse {
            0%, 100% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(1.1);
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}