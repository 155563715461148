.globalSearchContainer {
    //background-color: #fff;
    margin: 0 10px 24px 10px;
    //padding: 1rem;
    border-radius: 10px;

    @media (max-width: 767px) {
        margin-left: -20px;
        margin-right: -20px;
        margin-top: -20px;
        padding: 16px 16px 20px 16px;
        border-radius: 0;
        background-color: #fff;
    }
}

.searchWrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    .animatedPlaceholder {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 15px;
        font-weight: 500;
        color: #605e5e;
        pointer-events: none;
        display: flex;
        gap: 5px;
        z-index: 100;

        &.leftPosition {
            left: 48px !important;
        }
    }

    .changingText {
        color: #605e5e;
        display: inline;
        position: absolute;
        opacity: 1;
        //  left: 70px;
        min-width: 100px;
        transition: transform 0.5s ease-out, opacity 0.5s;
    }

    .slide-up {
        animation: slideTextUp 0.5s forwards;
    }

    @keyframes slideTextUp {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        100% {
            transform: translateY(-100%);
            opacity: 0;
        }
    }

    .GlobalSearchView {
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        position: relative;

        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
            border-radius: 10px;
        }

        input {
            padding-right: 84px;
            white-space: nowrap;
            border-radius: 10px;
        }
    }

    .micButton {
        position: absolute;
        right: 10px;

        &::before {
            content: "";
            position: absolute;
            right: 34px;
            height: 65%;
            width: 1px;
            background-color: #dbdbdb;
        }

        &:hover {
            background-color: transparent;
        }

        img {
            width: 12px;
            max-width: 100%;
        }

        .micRecordingEffect {
            width: 14px;
            height: 14px;
            background: linear-gradient(90deg, #db4250, #f49e49);
            border-radius: 50%;
            animation: micPulse 1.5s infinite ease-in-out;
        }
    }

    .closeButton {
        position: absolute;
        right: 54px;
        padding: 0;
        z-index: 101;

        &:hover {
            background-color: transparent;
        }

        svg {
            font-size: 22px;
            color: #7d7d7d;
        }
    }

    @keyframes micPulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.3);
            opacity: 0.6;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
}

.speechRecogModal {
    .MuiDialog-container {
        align-items: end;
    }

    .MuiPaper-root {
        width: 500px;
        height: auto;
        margin: 0 32px;
        border-radius: 20px 20px 0 0;

        @media (max-width: 767px) {
            margin: 0;
        }
    }

    .modalInnerContent {
        text-align: center;
        padding: 25px;

        .speechText {
            color: #181818;
            font-size: 18px;
            font-weight: 600;
            margin-top: 8px;
        }

        .audioTrackIcon {
            text-align: center;
            margin-top: 20px;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}