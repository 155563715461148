// For Loading Transition //
.pageEnter {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0.8);
  animation: pulseEffect 600ms ease-out forwards;
}

/* Active state once the animation is triggered */
.pageEnterActive {
  animation: fadeInPulse 600ms ease-out forwards;
}

/* Pulse Animation */
@keyframes pulseEffect {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  25% {
    opacity: 0.6;
    transform: scale(1.05);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.95);
  }
  75% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Fade-In Effect for smoother transition */
@keyframes fadeInPulse {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// END

.globalSearchPage {
  .searchPageTopContent {
    @media (min-width: 340px) and (max-width: 386px) {
      align-items: flex-start;
    }

    svg {
      font-size: 24px;
      cursor: pointer;
    }

    .topTitle {
      text-align: left;
      font-size: 16px;
      color: #181818;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .searchResults {
    margin-top: 14px;

    .searchResultItem {
      height: 97px;
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      padding: 1rem;
      cursor: pointer;

      @media (max-width: 767px) {
        height: auto;
      }

      .imgBox {
        width: 50px;
        height: 50px;
        max-width: 100%;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .infoContent {
        .typeDefineText {
          font-size: 14px;
          font-weight: 500;
          color: #838383;
          text-transform: capitalize;

          span {
            text-transform: lowercase;
          }
        }

        .itemTitle {
          font-size: 15px;
          font-weight: 600;
          color: #181818;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          line-height: 1.5;
          max-height: 4.5em;
          word-break: break-all;
        }

        .templeName {
          font-size: 14px;
          font-weight: 500;
          color: #575757;
        }
      }
    }

    .noResultsText {
      color: #181818;
      text-align: center;

      span {
        font-weight: 600;
      }
    }
  }

  .bottomServices {
    .titleBlock {
      text-align: center;
      position: relative;
      margin: 20px 0;

      &::after {
        width: 100%;
        height: 1px;
        background-color: #707070;
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 0;
        right: 0;
        opacity: 0.2;
      }

      .serviceTitleText {
        color: #181818;
        font-weight: bold;
        position: relative;
        display: inline-block;
        background-color: #f1f0f5;
        z-index: 2;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .serviceButton {
      button {
        box-shadow: none;
        border-radius: 30px;
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: #fff;
        color: #181818;
        border: 1px solid #d9d9d9;
        transition: 0.5s ease-in-out;

        &:hover {
          background-color: #ffece3;
          border: 1px solid #d56939;
        }
      }
    }
  }
}