.chatContainer {
  .pranamBhaktSec {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    position: relative;

    .pranamBhaktLeft {
      .pranamBhaktRightIcon {
        margin-left: 8px;

        .musicicon {
          width: 15px;
          cursor: pointer;
        }
      }

      .pranamBhaktSenderTextArea {
        background-color: #d56939;
        padding: 12px 15px;
        border-radius: 24px 24px 2px 24px;
        position: relative;
        min-width: 80px;

        .senderMgs {
          padding-bottom: 16px;

          .messageText {
            font-size: 15px;
            color: #fff;
            text-align: left;
          }
        }

        .senderTime {
          position: absolute;
          bottom: 7px;
          right: 15px;

          .namestetimestamp {
            font-size: 10px;
            color: #fff;
            opacity: 0.7;
          }
        }

        .namestereadIcon {
          width: 18px;
          max-width: 100%;
        }
      }

      .poweredByText {
        font-size: 11px;
        margin-top: 10px;
        font-weight: 500;

        span {
          color: #0092d5;
        }

        .quarkGenLogo {
          width: 15px;
          max-width: 100%;
          margin-left: 6px;
        }
      }
    }

    .smallValmikiImg {
      img {
        width: 40px;
      }
    }
  }

  .textarea {
    font-size: 12px;
    text-align: left;
    color: #555454;
  }
}