.aIGurujiLoginDialog {
    .MuiPaper-root {
        border-radius: 10px;
        max-width: 570px;
    }

    .aiGuruLoginDialogTitle {
        text-align: center;

        .mainTitle {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            padding-top: 10px;
        }
    }

    .aiGuruLoginDialogContent {
        .subText {
            font-size: 16px;
            letter-spacing: 0.2px;
            text-align: center;
        }
    }

    .bottomBtnContent {
        padding: 10px 24px 24px;
        justify-content: center;

        .loginBtn {
            background: linear-gradient(to right, #ff5858, #ff9e41);
            transition: 0.3s ease;
            box-shadow: none;
            margin-left: 14px;

            &:hover {
                background: linear-gradient(to right, #ff4949, #ff8b2d);
            }
        }
    }
}