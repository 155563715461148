// Define variables for colors used in gradients
$gradient-from: #1331b2;
$gradient-30: #3d5eea;
$gradient-73: #0123ad;
$gradient-to: #000000;

// Common styles
.text {
  font-size: 14px;
  line-height: 1.6;
  text-align: justify;
}

// Margin classes
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

// Heading styles
h1,
h2,
h3,
h4 {
  font-weight: 700;
  margin: 0;
  line-height: 1.4;
}

// Bold text
.fw-bold {
  font-weight: 700 !important;
}

.pageContainer {
  &.privacyPolicy {
    .sectionHead {
      .mainTitle {
        text-align: center;
        font-size: 30px;
        font-weight: 600;

        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(to right, #d56939 0%, #d8a820 50%, #000000 100%);
          background-size: 200%;
          animation: gradientAnimation 6s ease-in-out infinite;
        }
      }
    }

    .privacyContentGrid {
      margin-bottom: 20px;

      .rightPannelList {
        margin-left: 10px;
        background-color: #f8f8f8;
        padding: 1rem;
        border-radius: 8px;
        position: sticky;
        top: 80px;
        height: calc(100vh - 80px);
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
        }
      
        &::-webkit-scrollbar-thumb {
          background-color: #c9c9c9;
          border-radius: 10px;
          min-height: 20px;
          transition: 0.3s ease;
        }
      
        &::-webkit-scrollbar-thumb:hover {
          background-color: #888888;
        }
      
        &::-webkit-scrollbar-track {
          background-color: #f0f0f0;
        }

        h5 {
          border-bottom: 1px solid #cdcdcd;
          padding-bottom: 0.5rem;
          font-weight: 500;

          @media (max-width: 767px) {
            font-size: 20px;
          }
        }

        .listOfPrivacyPolicy {
          li {
            padding: 5px 6px;

            span {
              font-weight: 500;
            }
          }

          a {
            width: fit-content;
            color: #424242;
            font-weight: 500;
            padding: 2px;
            text-decoration: underline;
            transition: 0.3s ease;

            span {
              font-weight: 500;
            }

            &:hover {
              color: #000;
            }
          }

          .active {
            color: #d56939;

            &:hover {
              color: #d56939;
            }
          }
        }
      }
    }

    @keyframes gradientAnimation {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }

    .privacyInfoDetails {
      p {
        text-align: justify;

        a {
          color: #000;
          font-weight: bold;
          text-decoration: none;
        }

        ul {
          padding: 0;

          li {
            list-style: none;
          }
        }
      }
    }
  }
}

// ----- For Native Privacy Policy -----
.pageContainerPrivacyPolicyNative {
  padding: 20px 0;
  background-color: #f1f0f5;
  min-height: calc(100vh - 160px) !important;
  position: relative;

  &.privacyPolicy {
    .sectionHead {
      .mainTitle {
        text-align: center;
        font-size: 30px;
        font-weight: 600;

        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(to right, #d56939 0%, #d8a820 50%, #000000 100%);
          background-size: 200%;
          animation: gradientAnimation 6s ease-in-out infinite;
        }
      }
    }

    .privacyContentGrid {
      margin-bottom: 20px;

      .rightPannelList {
        margin-left: 10px;
        background-color: #f8f8f8;
        padding: 1rem;
        border-radius: 8px;
        position: sticky;
        top: 80px;
        height: calc(100vh - 80px);
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #c9c9c9;
          border-radius: 10px;
          min-height: 20px;
          transition: 0.3s ease;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #888888;
        }

        &::-webkit-scrollbar-track {
          background-color: #f0f0f0;
        }

        h5 {
          border-bottom: 1px solid #cdcdcd;
          padding-bottom: 0.5rem;
          font-weight: 500;

          @media (max-width: 767px) {
            font-size: 20px;
          }
        }

        .listOfPrivacyPolicy {
          li {
            padding: 5px 6px;

            span {
              font-weight: 500;
            }
          }

          a {
            width: fit-content;
            color: #424242;
            font-weight: 500;
            padding: 2px;
            text-decoration: underline;
            transition: 0.3s ease;

            span {
              font-weight: 500;
            }

            &:hover {
              color: #000;
            }
          }

          .active {
            color: #d56939;

            &:hover {
              color: #d56939;
            }
          }
        }
      }
    }

    @keyframes gradientAnimation {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }

    .privacyInfoDetails {
      .nativeSpacing {
        padding-top: 26px !important;
      }

      p {
        text-align: justify;

        a {
          color: #000;
          font-weight: bold;
          text-decoration: none;
        }

        ul {
          padding: 0;

          li {
            list-style: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .sectionHead {
    p {
      font-size: 1.5rem !important;
    }
  }

  .pageContainer {
    &.privacyPolicy {
      .privacyContentGrid {
        flex-direction: column-reverse;

        .rightPannelList {
          margin-left: 0;
          position: static;
          height: auto;
          overflow: visible;
        }
      }
    }
  }

  // Native //
  .pageContainerPrivacyPolicyNative {
    &.privacyPolicy {
      .privacyContentGrid {
        flex-direction: column-reverse;

        .rightPannelList {
          margin-left: 0;
          position: static;
          height: auto;
          overflow: visible;
        }
      }
    }
  }
  // End Native //

  .privacyInfoDetails {
    h5 {
      font-size: 1.2rem !important;
      text-align: left !important;
    }
  }
}