.ManagePujaForm {
    .MuiDialog-paper {
        width: 70%;
        max-width: 100%;
    }

    .ManagePujaFormItem {
        .benefitLists {
            margin-bottom: 16px;
            margin-top: 5px;
        }

        .multiplePujaSec {
            margin-top: 8px;

            .multiplePujaTitle {
                color: #000;
                padding: 6px 10px !important;
                font-weight: 500;
            }
        }

        .multiplePujaSubCategoryTextArea {
            //background-color: #939393;
            padding: 1.5rem;
            border-radius: 6px;
            margin-top: 16px;
            margin-bottom: 20px;
            box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1),
                0 2px 4px rgba(0, 0, 0, 0.1),
                -2px 0 4px rgba(0, 0, 0, 0.1),
                2px 0 4px rgba(0, 0, 0, 0.1);

            .checkboxAndAddpackageInfo {
                margin-top: 0.5rem;

                .checkboxInfo {
                    padding: 0;
                    margin-right: 8px;

                    &:hover {
                        background-color: transparent;
                    }
                }

                .addPackageSec {
                    box-shadow: none;
                }
            }

            .tableDataInfo {
                max-height: 300px;
                margin: 1rem 0;
                border-radius: 4px;
                //overflow-y: auto;
            }
        }
    }

    .managePujaBottomPart {
        &.modalFooter {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            padding: 1rem;
            box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);

            .btnCancel {
                &.grey {
                    color: #696969;
                    border-color: #d8d8d8;
                    border-width: 1px;
                    border-style: solid;

                    &:hover {
                        background-color: #f2f2f2;
                    }
                }
            }

            button {
                margin: 0;
            }
        }
    }
}
