.specialPujaAdmin {
    .topTitle {
        text-align: center;

        .adminTitleText {
            font-size: 20px;
            font-weight: 600;
            color: #d56939;
        }
    }

    .btnAddSpecialPuja {
        display: flex;
        justify-content: flex-end;

        button {
            box-shadow: none;

            svg {
                font-size: 20px;
                margin-right: 6px;
            }
        }
        .search-container {
            width: 100%; // Ensures the search box adapts well in layout
            max-width: 300px; // Set a maximum width for the search box
          }
    }

    .specialPujaItemListAdmin {
        width: 100%;
        margin-top: 10px;

        .specialPujaAdminCard {
            border: 1px solid #ddd;
            border-radius: 8px;
            margin-bottom: 10px;
            padding: 10px;

            .listText {
                .MuiTypography-body1 {
                    font-weight: 600;
                }
            }

            .spPujaEditBtn {
                background: linear-gradient(-45deg,
                        rgba(0, 0, 0, 0) 33%,
                        hsla(0, 0%, 100%, 0.5) 50%,
                        rgba(0, 0, 0, 0) 66%),
                    linear-gradient(-45deg, #d8a820, #d66f35);
                background-size: 250% 250%, 100% 100%;
                animation: gradient 15s ease infinite;
                color: white;
                margin-right: 8px;

                svg {
                    font-size: 18px;
                }

                @keyframes gradient {
                    0% {
                        background-position: 0% 50%;
                    }

                    50% {
                        background-position: 100% 50%;
                    }

                    100% {
                        background-position: 0% 50%;
                    }
                }
            }

            .spPujaDeleteBtn {
                background-color: red;
                color: white;
                transition: 0.5s ease;

                &:hover {
                    background-color: #af1919;
                }

                svg {
                    font-size: 18px;
                }
            }
        }

        .active {
            background-color: #d26a5d;
            color: white;
        }
    }
}

