.searchCardBlock {
    border-radius: 1rem;
    background-color: #fff;
    padding: 1rem;

    .scHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .scImgTitle {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .scProf {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #d56939;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .tempPujaTitle {
                p {
                    font-size: 16px !important;

                }
            }
        }

        .verifyTick {
            width: 20px;
            height: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .ratingBlock {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding-left: 3rem;

        .ratingStarCount {
            color: #d56939;
        }

        p {
            .totalRating {
                color: #b6b7b7;
            }
        }
    }

    .searchCardImg {
        width: 100%;
        height: auto;
        border-radius: 0.8rem;
        overflow: hidden;
        margin-top: 1rem;

        img {
            width: 100%;
            height: auto;
        }
    }

    .tempPujaDescription {
        p {
            margin-top: 1rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}