.chooseProfile {
    width: 100%;
    margin: auto;
    text-align: center;
    h4 {
        font-size: 24px;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .profileBlock {
        width: 600px;
        max-width: 100%;
        margin: auto;
        text-align: center;
        display: inline-block;

        .profilecards {
            width: 33.333%;
            gap: 10px;
            display: inline-block;
            margin-bottom: 20px;

            h5 {
                font-size: 16px;
            }

            .profileImg {
                width: 160px;
                height: 160px;
                border-radius: 50%;
                overflow: hidden;
                display: inline-block;
                background-color: #f2f2f2;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    border: 6px solid #f2f2f2;
                    transition: all 0.7s ease;
                    &:hover {
                        border-top: 6px solid #c94c86;
                        border-right: 6px solid #d95668;
                        border-bottom: 6px solid #d95668;
                        border-left: 6px solid #e45d56;
                    }
                }

                &.selected {
                    img {
                        border-top: 6px solid #c94c86;
                        border-right: 6px solid #d95668;
                        border-bottom: 6px solid #d95668;
                        border-left: 6px solid #e45d56;
                    }
                }

                
            }
        }
    }
    .continueBtn {
        margin-top: 30px;
        .btn-primary {
            font-size: 14px;
            background-color: #d56939;
            color: #fff;
            border: none;
            transition: all 0.7s ease;
            text-transform: uppercase;
            letter-spacing: 2px;
            min-width: 200px;

            &:hover {
                background-color: #b14a1e;
            }

            &.btnRound {
                border-radius: 32px;
                padding: 8px 18px;
            }
        }
    }
}

@media (max-width: 767px) {
    .chooseProfile {
        .profileBlock {
            .profilecards {
                width: 50%;
            }
        }
    }
}