.gurujiChatListDialog {
    .MuiPaper-root {
        border-radius: 14px;
        // width: 90%;
        // max-width: 1000px;
        margin: 16px;
        background-color: #F1F0F5;
    }

    .chatListTopTitle {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        background-color: #fff;

        .topItem {
            .exportBtn {
                box-shadow: none;
                margin-right: 10px;

                svg {
                    font-size: 20px;
                    margin-right: 4px;
                }
            }

            .topCloseIcon {
                background-color: transparent;
                border: 2px solid #d65758;
                border-radius: 50%;
                padding: 2px;
                transition: background-color 0.3s ease, color 0.3s ease;

                svg {
                    color: #d65758;
                    transition: color 0.3s ease;
                    font-size: 16px;
                }

                &:hover {
                    background-color: #f13a3a;
                    border: 2px solid #f13a3a;

                    .MuiSvgIcon-root {
                        color: #fff;
                    }
                }
            }
        }
    }

    .gurujiChatListContent {
        padding: 0;
    }

    .chatListBottomArea {
        justify-content: center;
        background-color: #fff;
        padding: 14px 0;
        box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }
}