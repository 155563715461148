.searchAutoPopulate {
    .searchTempPuja {
        .searchInput {
            width: 100%;
            margin: auto;
            position: relative;

            input {
                width: 100%;
                margin: auto;
                border-radius: 30px;
                height: 50px;
                font-size: 14px;
                padding-left: 42px;
                border: 1px solid #efefef;
            }

            .searchIcon {
                width: 20px;
                height: 20px;
                position: absolute;
                left: 12px;
                top: 12px;
                z-index: 1;

                svg {
                    color: #252425;
                }
            }
        }
    }
}

.searchArea {
    .searchAreaTextField {
        width: 100%;
        border-radius: 30px;
        background-color: #fff;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        border-radius: 30px;
    }
}

.mainCategories {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;

    ul {
        width: 100%;
        margin: auto;
        text-align: center;
        background-color: transparent;

        li {
            width: 95px;
            height: auto;
            float: left;
            padding: 10px;
            text-align: center;
            display: block;
            margin-bottom: 0.5rem;

            .imgCircle {
                width: 75px;
                height: 75px;
                border-radius: 50%;
                overflow: hidden;
                border: 2px solid #d56939;

                img {
                    width: 100%;
                    height: 100%;
                    margin: auto;
                }
            }

            .listTitle {
                text-align: center;
                margin-top: 0.5rem;

                p {
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    color: #181818;
                }
            }
        }
    }

    &.searchMainCategories {
        margin-bottom: 1rem;

        ul {
            &.searchCatgList {
                width: 100%;
                display: flex;
                overflow-x: auto;
                scrollbar-width: none;
                position: relative;
                border-bottom: 1px solid #d6d6d6;

                li {
                    margin-bottom: 0;

                    &.active {
                        position: relative;

                        &::after {
                            width: 100%;
                            height: 6px;
                            margin: auto;
                            background-color: #d56939;
                            border-radius: 3px;
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -4px;
                            top: auto;
                            z-index: 10;
                        }
                    }

                    .listTitle {
                        p {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}

.subCatgCardView {
    .subCatgCardList {
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        ul {
            width: 100%;
            display: inline-block;
            padding-top: 0;
            padding-bottom: 0;

            li {
                width: calc(50% - 1rem);
                display: inline-block;
                float: left;
                background-color: #fff;
                border-radius: 58px;
                padding: 10px 10px 0 10px;
                margin-bottom: 20px;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                text-align: center;

                .imgRounded {
                    width: 100%;
                    height: auto;
                    border-radius: 56px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .subCatgTitle {
                    text-align: center;
                    padding: 1rem 0.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        width: 100%;
                        font-size: 14px;
                        color: #242424;
                        font-weight: 600;
                        // display: inline-block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    .imgRounded {
                        height: 180px;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    .imgRounded {
                        height: 180px;
                    }
                }
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 991px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    .imgRounded {
                        height: 180px;
                    }
                }
            }
        }
    }
}

@media (min-width: 800px) and (max-width: 899px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    border-radius: 60px;

                    .imgRounded {
                        height: 200px;
                        border-radius: 60px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 799px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    border-radius: 60px;

                    .imgRounded {
                        height: 200px;
                        border-radius: 60px;
                    }
                }
            }
        }
    }
}

@media (min-width: 680px) and (max-width: 767px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    border-radius: 60px;

                    .imgRounded {
                        height: 200px;
                        border-radius: 60px;
                    }
                }
            }
        }
    }
}

@media (min-width: 600px) and (max-width: 679px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    border-radius: 60px;

                    .imgRounded {
                        height: 170px;
                        border-radius: 60px;
                    }
                }
            }
        }
    }
}

@media (min-width: 500px) and (max-width: 599px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    border-radius: 44px;

                    .imgRounded {
                        height: 140px;
                        border-radius: 44px;
                    }
                }
            }
        }
    }
}

@media (max-width: 499px) {
    .subCatgCardView {
        .subCatgCardList {
            ul {
                li {
                    border-radius: 32px;

                    .imgRounded {
                        height: 110px;
                        border-radius: 32px;
                    }
                }
            }
        }
    }
}

@media (min-width: 900px) {
    .searchMainCategories {
        ul {
            &.searchCatgList {
                display: block !important;
            }
        }
    }
}

@media (max-width: 399px) {
    .subCatgCardView {
        .subCatgCardList {
            .imgRounded {
                height: 86px !important;
            }
        }
    }
}