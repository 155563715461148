.pushNotiForm .pushNotiTitle {
    font-size: 30px;
    text-align: center;
}

.pushNotiForm .pushNotiBtn {
    padding: 10px 20px;
    width: 70%;
    border-radius: 5px;
    box-shadow: none;
}

.pushNotiForm .advanceBtn {
    padding: 10px 20px;
    width: 30%;
    border-radius: 5px;
    box-shadow: none;
}

.pushNotiForm .advanceBtn:hover {
    box-shadow: none;
    background-color: #fdf4f0;
}

.advanceModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: #fff;
    padding: 16px;
    height: 90vh;
    border-radius: 0 0 8px 8px;
    
}

.advanceModal .advanceModalTitle {
    margin-bottom: 0;
}

.advanceModal .advanceModalCloseBtn {
    position: absolute;
    right: 0;
    background: transparent;
}

.advanceModal .advanceModalInner {
    overflow-y: auto;
    height: 72vh;
}

.advanceModal .advanceModalInner .uploadImgBtn {
    padding: 8px 16px;
    box-shadow: none;
}

.advanceModal .advanceModalInner .advModalUserInfo {
    width: 100%;
    justify-content: flex-start;
    background-color: #f4f4f4;
    padding: 10px 6px;
    border-radius: 5px;
    border-bottom: 2px solid #cfcfcf;
}

.advanceModal .advanceModalSubmitBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.advanceModal .advanceModalSubmitBtn button {
    padding: 8px 0;
}

.pushNotiForm .pushNotiBtn:hover {
    background-color: #b14a1e;
    box-shadow: none;
}