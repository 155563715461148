.loginViewArea {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .leftCarousel {
        text-align: center;
        margin-top: 70px;

        .mainCarousel {
            .carousel-indicators {
                button {
                    background-color: #d56939;
                    width: 30px;
                    height: 8px;
                    border: none;
                    border-radius: 10px;
                }
            }

            .carouselInnerItem {
                height: 27em;

                img {
                    width: auto;
                }

                p {
                    width: 80%;
                    margin: 40px auto;
                    color: #878787;
                    font-weight: 600;
                }
            }
        }
    }

    .rightLoginDetails {
        text-align: center;
        margin-top: 70px;

        .title {
            font-size: 40px;
            font-weight: bold;
            color: #d56939;
            margin-bottom: 5px;
        }

        .subTitle {
            font-size: 20px;
            color: #d56939;
            margin-bottom: 36px;
        }

        .mobileNoInput {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            .phInput-group {
                display: flex;

                .input-group-text {
                    width: auto;
                    border-radius: 30px 0 0 30px;
                    background-color: #ebedf6;
                }
            }

            input {
                background-color: #fff;
                border: none;
                padding: 10px 20px;
                border-radius: 0 30px 30px 0;
                border: 1px solid #ebedf6;
                border-left: none;

                &:focus {
                    outline: none;
                }
            }

            .otpBtn {
                background-color: #d56939;
                border: none;
                border-radius: 30px;
                padding: 10px 20px;
                transition: 0.3s ease;
                margin-left: 10px;

                &:hover {
                    background-color: #c05d33;
                }
            }
        }

        .otherLoginBtn {
            width: 25em;
            background-color: transparent;
            border-radius: 30px;
            padding: 12px 8px;
            border: 1px solid #adadad;
            margin-bottom: 20px;
            transition: 0.3s ease;

            &:hover {
                background-color: #f4f4f4;
            }

            p {
                margin-bottom: 0;
                align-items: center;
                display: flex;
                justify-content: center;
                color: #adadad;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 14px;
                font-weight: 600;

                img {
                    max-width: 22px;
                    margin-right: 6px;
                }
            }
        }

        .signUpBottom {
            p {
                color: #adadad;
                font-weight: 600;
                margin-top: 20px;

                a {
                    color: #d56939;
                    text-decoration: none;
                }
            }
        }
    }
}

.otpCloseIconBtn {
    background-color: transparent !important;
    border: 2px solid #d65758 !important;
    border-radius: 50% !important;
    padding: 5px !important;
    transition: background-color 0.3s ease, color 0.3s ease !important;

    svg {
        color: #d65758;
        transition: color 0.3s ease;
        font-size: 10px !important;
    }

    &:hover {
        background-color: #f13a3a !important;
        border: 2px solid #f13a3a;

        svg {
            color: #fff;
        }
    }
}

.otpScreen {
    padding: 30px;
    text-align: center;

    .veryfyNo {
        font-size: 18px;
        font-weight: 600;
        color: #282c40;
    }

    p {
        font-size: 16px;
        color: #626367;
    }

    .otpImgBlock {
        max-width: 200px;
        text-align: center;
        margin: auto;

        img {
            width: 100%;
            margin: auto;
        }
    }

    .otpFields {
        .MuiOutlinedInput-notchedOutline {
            border: none;
            border-bottom: 1px solid #242424;
            border-radius: 0;
            font-size: 24px;
            font-weight: 600;
        }

        .MuiOtpInput-Box {
            width: 400px;
            max-width: 100%;
            gap: 15px;
        }
    }
    .otpFields input:focus {
        outline: none;
    }

    .otpFields input:focus {
        outline: none;
    }

    .otpFields input:focus {
        outline: none;
    }

    .otpConfirm {
        //margin-top: 30px;
        button {
            padding: 10px 25px !important;

            // Highlighter effect on hover
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -100%;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.2);
                transform: skewX(-20deg);
            }

            &:hover::before {
                animation: slideHighlight 1s ease-in-out forwards;
            }
        }

        // Keyframes for hover highlighter animation
        @keyframes slideHighlight {
            0% {
                left: -100%;
            }

            50% {
                left: 50%;
            }

            100% {
                left: 100%;
            }
        }

        .btn-primary {
            font-size: 14px;
            background-color: #d56939;
            color: #fff;
            border: none;
            transition: all 0.7s ease;
            text-transform: uppercase;
            letter-spacing: 2px;
            min-width: 200px;

            &:hover {
                background-color: #b14a1e;
            }

            &.btnRound {
                border-radius: 32px;
                padding: 8px 18px;
            }
        }
    }

    .resendOtps {
        .resendotp {
            font-size: 14px;
            color: #d56939;
            display: inline-block;
            text-decoration: none;
            margin-top: 20px;

            cursor: pointer;
        }
    }

    .countdownTimer {
        font-size: 14px;
        margin-top: 20px;
        text-align: right;
    }
}


/* ========= Responsive SCSS ========= */

@media (min-width: 330px) and (max-width: 400px) {
    .loginViewArea {
        .loginLeft {
            display: none;
        }

        .loginRight {
            margin-top: 50px;
            margin-bottom: 15px;
        }
    }
}

@media (min-width: 400px) and (max-width: 540px) {
    .loginViewArea {
        //margin: 100px 0 50px;
        height: auto;
        margin-top: 5rem;

        .loginLeft {
            display: none;

            .leftCarousel {
                margin-top: 0;
                padding-top: 15px;

                .carousel-indicators {
                    margin-bottom: 2rem;
                }
            }
        }

        .rightLoginDetails {
            margin-top: 20px;
            //padding-bottom: 50px;

            br {
                display: none;
            }

            .title {
                font-size: 24px;
            }

            .subTitle {
                font-size: 14px;
            }

            .mobileNoInput {
                display: block;
                margin-bottom: 10px;

                .phInput-group {
                    margin-bottom: 10px;
                }

                input {
                    width: 100%;
                }

                button {
                    width: 100% !important;
                    margin: 0 auto !important;
                }
            }


        }
    }

    .loginBlock {
        width: 100% !important;
        margin: 0 auto !important;

        .carousel-inner {
            .carousel-item {
                .carouselInnerItem {
                    padding-left: 5px;
                    padding-right: 5px;

                    img {
                        max-width: 100%;
                    }

                    p {
                        width: 100% !important;
                        margin: 15px auto !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 541px) and (max-width: 899px) {
    .loginViewArea {
        //margin: 100px 0 50px;
        height: auto;
        margin-top: 5rem;

        .loginLeft {
            display: none;

            .leftCarousel {
                margin-top: 0;
                padding-top: 15px;

                .carousel-indicators {
                    margin-bottom: 2rem;
                }
            }
        }

        .rightLoginDetails {
            margin-top: 20px;
            //padding-bottom: 50px;

            br {
                display: none;
            }

            .title {
                font-size: 24px;
            }

            .subTitle {
                font-size: 14px;
            }

            .mobileNoInput {
                display: block;
                margin-bottom: 10px;

                .phInput-group {
                    margin-bottom: 10px;
                }

                input {
                    width: 100%;
                }

                button {
                    width: 100% !important;
                    margin: 0 auto !important;
                }
            }


        }
    }

    .loginBlock {
        width: 100% !important;
        margin: 0 auto !important;

        .carousel-inner {
            .carousel-item {
                .carouselInnerItem {
                    padding-left: 5px;
                    padding-right: 5px;

                    img {
                        max-width: 100%;
                    }

                    p {
                        width: 100% !important;
                        margin: 15px auto !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 1023px) {
    .loginViewArea {
        .loginBlock {
            display: block;

            .loginLeft {
                width: 100%;
                max-width: 90%;
                margin: auto;
            }

            .loginRight {
                width: 100%;
                max-width: 90%;
                margin: auto;
            }
        }
    }
}

@media (max-width: 399px) {
    .loginViewArea {
        height: calc(100vh - 60px);

        .leftCarousel {
            margin-top: 0;
            padding-top: 50px;
            position: relative;

            .carousel-inner {
                .carousel-item {
                    height: 180px;

                    .carouselInnerItem {
                        height: 100%;
                        width: 100%;

                        img {
                            height: 100%;
                            width: auto;
                        }
                    }

                }
            }

            .carousel-indicators {
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1rem;
                margin: auto;
            }
        }

        .rightLoginDetails {
            margin-top: 40px;
            //padding-bottom: 20px;

            br {
                display: none;
            }

            .title {
                font-size: 24px;
            }

            .subTitle {
                font-size: 14px;
            }

            .mobileNoInput {
                display: block;
                margin-bottom: 10px;

                .phInput-group {
                    margin-bottom: 10px;
                }

                input {
                    width: 100%;
                }

                button {
                    width: 100% !important;
                    margin: 0 auto !important;
                }
            }


        }
    }

    .loginBlock {
        width: 100% !important;
        margin: 0 auto !important;

        .carousel-inner {
            .carousel-item {
                .carouselInnerItem {
                    padding-left: 5px;
                    padding-right: 5px;

                    img {
                        max-width: 100%;
                    }

                    p {
                        width: 100% !important;
                        margin: 15px auto !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .loginViewArea {
        .loginBlock {
            align-items: center;

            .loginLeft {}

            .loginRight {}
        }

        .rightLoginDetails {
            margin: auto !important;
        }
    }
}

@media (max-width: 1024px) {
    .otpScreen {
        .otpFields {
            input {
                padding: 5px;
            }
        }
    }

    .loginViewArea {
        .loginLeft {
            display: none;
        }

        // .loginRight {
        //     margin-top: 50px;
        //     margin-bottom: 15px;
        // }
    }
}