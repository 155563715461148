.whatsappInputCard {
  // width: 450px;
  // padding: 20px;
  border-radius: 10px;
  //background-color: rgba(255, 255, 255, 0.3);
  margin: 0 auto;
  text-align: center;
  // margin-top: 60px;
  // border: 1px solid green;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f1f0f5 inset !important;
  }

  .newAccountTitle {
    color: #d56939;
    margin-top: 20px;
    font-weight: bold;
    font-size: 30px;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
  }

  .line {
    width: 10%;
    height: 2px;
    background-color: #d56939;
    // margin: 15px 0;
  }

  .leftAlignText {
    text-align: left;

    .subTitleText {
      margin: 10px 0 0;
      font-size: 18px;
      font-weight: normal;
      text-align: left;
      color: #d56939;

      .boldText {
        font-weight: 500;
      }
    }

    .loginPhNoText {
      font-size: 16px;
      font-weight: bold;
      margin-top: 35px;
      color: #282c40;
    }
  }

  .countryInputArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 8px;
    // border: 1px solid red;
    // margin: 26px 0 50px;
    

    .flagImgBtn {
      padding: 0;
      margin-right: 5px;
      border: 0;
      outline: 0;
      display: flex;
      background-color: transparent;
      &:hover {
        // background-color: transparent;
      }

      img {
        width: 46px;
        height: 30px;
        border-radius: 5px;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }

      .dropdownIcon {
        //margin-left: 5px;
        color: #000;
        font-size: 30px;
      }
    }

    .phNoInput {
      width: 100%;
      font-weight: bold;
      font-size: 15px;
      color: #323232;

      .MuiInputBase-root {
        font-weight: 500;
      }

      label {
        font-size: 16px;
      }
    }
  }

  .bottomButtonGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;

    button {
      width: 100%;
      padding: 14px 0;
      font-size: 14px;
      font-weight: 500;
      border-radius: 30px;
      letter-spacing: 2.1px;
      border: none;
    }

    .proceedBtn {
      background-color: #d56939;
      color: white;
      transition: 0.5s ease;

      &.Mui-disabled {
        color: #914727;
      }

      &:hover {
        background-color: #af552f;
      }
    }

    .guestBtn {
      color: #696c79;
      border: 1px solid #707070;
      transition: 0.5s ease;

      &:hover {
        background-color: #fff;
        color: #5d5d5f;
      }
    }
  }
}

// ----- For Responsive ------
@media (max-width: 768px) {
  .loginCard {
    width: 100%;
    margin-top: 0;
  }
}



/* Ensure the dialog has a fixed size */
// .MuiDialog-paper {
//   width: 550px;
//   height: 650px;
//   max-width: none;
//   max-height: none;
//   overflow: hidden;
//   border-radius: 20px;
// }