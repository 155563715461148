.pageContainer {
    &.managePrasadAdmin {
        .dpDnText {
            font-size: 20px;
            font-weight: 600;
            color: #d56939;
        }

        .prasadItemListAdmin {
            width: 100%;
            margin-top: 10px;

            .listText {
                .MuiTypography-body1 {
                    font-weight: 600;
                }

                .templeDesc {
                    display: block;
                    margin-bottom: 2px;
                }

                .templePujaAmount {
                    color: #000;
                    font-weight: 500;
                }
            }

            .active {
                background-color: #d26a5d;
                color: white;
            }

        }
        
    }
}

/* Day Buttons */
.day {
    padding: 10px 15px;
    border: 2px solid #d26a5d;
    border-radius: 5px;
    text-align: center;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

/* Box and Button Styling */
.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background-color: #f1f0f5;
    border-radius: 10px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
    padding: 32px;
}

.chooseImgInfo {
    img {
        width: 100px;
        max-width: 100%;
    }
}

.day-buttons-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.chadhavaAdminCalendar {
    .MuiDateCalendar-root {
        margin: 0 !important;
        width: 100%;
        background-color: #f4f4f4;
        border: 1px solid #f2ae8d;
        border-radius: 6px;
    }
}

.action-buttons-container {
    display: flex;
    justify-content: space-between;
}

.action-button {
    width: 230px;
    height: 55px;
    font-size: 16px;
}

/* Cancel Button - Remove Hover Effect */
.action-button.cancel {
    background-color: #000;
    color: white;
    border-radius: 50px;
}

.action-button.cancel:hover {
    background-color: #000;
    color: white;
}

.action-button.save {
    border: 1px solid #000;
    color: #000;
    border-radius: 50px;
    margin-left: 5px;
}

.action-button.active {
    background-color: #d26a5d;
    color: white;
}

.active_day {
    background-color: #d26a5d;
    color: white;
}


.prasadForm {
    &.modalFooter {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        padding: 1rem;
    
        .btnCancel {
          &.grey {
            color: #696969;
            border-color: #d8d8d8;
            border-width: 1px;
            border-style: solid;
    
            &:hover {
              background-color: #f2f2f2;
            }
          }
        }
        button {
            margin: 0;
        }
    }
}
