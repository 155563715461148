.pageContainer {
    &.panditBooking {

        .panditBookingBlock {

            .panditBookingLeftBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }

            // .centerBlockTop {
            //     width: 100%;
            //     height: auto;
            //     background-color: #fff;
            //     border-radius: 12px;
            //     padding: 15px;
            //     margin: 0 auto 25px;

            //     .topField {
            //         display: flex;
            //         align-items: center;

            //         .profileImg {
            //             width: 55px;
            //             border-radius: 50%;
            //             overflow: hidden;
            //             margin-right: 10px;

            //             img {
            //                 width: 100%;
            //                 height: 100%;
            //             }
            //         }

            //         .inputBox {
            //             width: 100%;

            //             input {
            //                 border-radius: 35px;
            //                 padding: 14px 16px;
            //                 background-color: #ebedf6;
            //                 border: none;
            //                 box-shadow: none;
            //                 color: rgba(0, 0, 0, 0.6);
            //             }
            //         }
            //     }

            //     .bottomField {
            //         display: flex;
            //         justify-content: space-between;
            //         width: calc(100% - 100px);
            //         margin-left: 80px;
            //         margin-top: 16px;

            //         .mediaButton {
            //             display: flex;
            //             align-items: center;

            //             a {
            //                 text-decoration: none;
            //                 color: #000;
            //                 font-size: 14px;

            //                 img {
            //                     width: 20px;
            //                     margin-right: 10px;
            //                 }
            //             }
            //         }

            //         .dateButton {
            //             display: flex;
            //             align-items: center;

            //             a {
            //                 text-decoration: none;
            //                 color: #000;
            //                 font-size: 14px;

            //                 img {
            //                     width: 18px;
            //                     height: 18px;
            //                     margin-right: 10px;
            //                 }
            //             }
            //         }

            //         .writePostButton {
            //             display: flex;
            //             align-items: center;

            //             a {
            //                 text-decoration: none;
            //                 color: #000;
            //                 font-size: 14px;

            //                 img {
            //                     width: 20px;
            //                     height: 15px;
            //                     margin-right: 10px;
            //                 }
            //             }
            //         }
            //     }
            // }

            .panditBookingCenterBlock {
                .panditBookingBox {
                    width: 100%;
                    background-color: #fff;
                    padding: 12px 15px;
                    // width: 20em;
                    height: auto;
                    border-radius: 12px;
                    margin-bottom: 20px;
                    cursor: pointer;

                    .panditBookingTop {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;

                        .panditBookingProfile {
                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                border: 3px solid #d56939;
                            }
                        }

                        .panditBookingTitle {
                            p {
                                margin-bottom: 0;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }

                    .panditBookingImage {
                        img {
                            border-radius: 20px;
                            width: 100%;
                            height: 448px;
                            margin-top: 16px;
                            object-fit: cover;
                        }
                    }

                    .panditBookingDes {
                        p {
                            font-size: 14px;
                            margin-top: 10px;
                        }
                    }

                    .panditBookingBottom {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .ratings {
                            display: flex;

                            i {
                                color: #d56939;
                                padding-right: 5px;
                            }

                            p {
                                color: #cbcbcb;
                                margin-bottom: 0;

                                span {
                                    color: #d56939;
                                    padding-right: 3px;
                                }
                            }
                        }

                        .likeShare {
                            a {
                                padding-left: 10px;

                                img {
                                    width: 25px;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .panditBookingRightBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }
        }
    }
}




// For Responsive
@media (min-width: 360px) and (max-width: 767px) {

    .pageContainer.panditBooking {
        .panditBookingBlock {

            .panditBookingLeftBlock,
            .panditBookingRightBlock {
                display: none;
            }

            .panditBookingCenterBlock {
                margin-top: 20px;

                .panditBookingBox {
                    margin-bottom: 15px;

                    .panditBookingImage {
                        img {
                            height: 210px;
                        }
                    }
                }
            }
        }
    }
}