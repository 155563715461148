@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.valmikiImg {
  width: 100%;
}

.rotating-image-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 2rem;
}

.animated-image {
  width: 100%;
  height: 100%;
  animation: rotateAndMove 4s ease-in-out infinite alternate;
}

.valmikiImage {
  .gridLeft {
    max-width: 50% !important;
  }

  .gridRight {
    max-width: 50% !important;
  }
}

.valmikiBannerText {
  position: relative;
  padding-top: 2rem;
  right: 2rem;
  text-align: right;

  .betaText {
    font-size: 1rem;
    font-weight: bold;
    color: #a8a7a7;
  }

  .valmikiTitle {
    font-family: "Playfair Display", serif;
    font-size: 4rem;
    font-weight: bold;
    line-height: 0.64;
    color: #d56939;
    margin: 0.5rem 0 0.5rem 0;
  }

  .valmikiSubTitle {
    font-size: 0.9rem;
    font-weight: 600;
    color: #7e7676;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .animatedCircle {
    display: flex;
    justify-content: center;
  }
}

// @keyframes rotateAndMove {
//   0% {
//     transform: rotate(0deg) translateX(0);
//   }
//   25% {
//     transform: rotate(20deg) translateX(-20px);
//   }
//   50% {
//     transform: rotate(0deg) translateX(20px);
//   }
//   75% {
//     transform: rotate(-20deg) translateX(-20px);
//   }
//   100% {
//     transform: rotate(0deg) translateX(0);
//   }
// }

@media (min-width: 769px) {
  .valmikiImage {
    .gridLeft {
      .valmikiImg {
        max-width: 200px;
        margin: auto;
      }
    }
    .gridRight {
      display: flex;
      justify-content: center;
      align-items: center;

      .valmikiBannerText {
        padding-top: 1rem;
        .valmikiTitle {
          font-size: 4rem;
        }
        .valmikiSubTitle {
          margin-bottom: 0;
        }
      }
      .rotating-image-container {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-left: 2rem;
    }
    }
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .valmikiImage {
    .gridLeft {
      .valmikiImg {
        max-width: 300px;
        margin: auto;
      }
    }

    .gridRight {
      display: flex;
      justify-content: center;
      align-items: center;

      .valmikiBannerText {
        .valmikiTitle {
          font-size: 5rem;
        }
      }
      .rotating-image-container {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-left: 2rem;
    }
    }
  }
}

@media (min-width: 481px) and (max-width: 599px) {
  .valmikiImage {
    .gridLeft {
      .valmikiImg {
        max-width: 300px;
        margin: auto;
      }
    }

    .gridRight {
      display: flex;
      justify-content: center;
      align-items: center;

      .valmikiBannerText {
        .valmikiTitle {
          font-size: 4rem;
        }
      }
      .rotating-image-container {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-left: 2rem;
    }
    }
  }
}

@media (max-width: 480px) {
  .valmikiImage {
    .gridLeft {
      .valmikiImg {
        max-width: 300px;
        margin: auto;
      }
    }

    .gridRight {
      display: flex;
      justify-content: center;
      align-items: center;

      .valmikiBannerText {
        padding-top: 1rem;
        .valmikiTitle {
          font-size: 3rem;
        }
        .valmikiSubTitle {
          margin-bottom: 0;
        }
      }
      .rotating-image-container {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-left: 2rem;
    }
    }
  }
}
