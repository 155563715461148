.tdCatgList {
    display: inline-block;
    margin: auto;



    .tdAvatarItem .catgAvatar img {
        transition: transform 0.3s ease-in-out;
      }
      
      .tdAvatarItem .catgAvatar img:hover,
      .tdAvatarItem .catgAvatar img:active {
        transform: scale(1.1);
      }
      
      .tdAvatarItem .catgAvatar img:active {
        opacity: 0.8;
      }
      

    li {
        width: 25%;
        padding: 0.5rem 0.5rem 1rem 0.5rem;
        display: inline-block;
        text-align: center;

        // &:hover {
        //     cursor: pointer;
        // }

        .tdAvatarItem {
            display: flex;
            justify-content: center;

            .catgAvatar {
                text-align: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 0.5rem;

                img {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }

        .catgName {
            text-align: center;

            span {
                // white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
                font-size: 14px;
            }

        }
    }
}



@media (max-width:767px) {
    .tdCatgList {
        li {
            .tdAvatarItem {
                .catgAvatar {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}

