.footerArea {
    padding-top: 15px;
    background-color: #e6e6e6;
    border-top: 1px solid #ebecf1;
    //margin-top: 1rem;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);

    .lgFooter {
        width: 100%;
        max-width: 1920px;
        margin: auto;

        .lgFtrmainGrid {

            .lgMainGrid {
                padding-left: 18px;
                padding-right: 18px;

                .wiget {

                    img {
                        max-width: 110px;
                    }

                    p {
                        font-size: 12px;
                        margin-top: 10px;
                    }

                    h6 {
                        font-size: 14px;
                        font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 14px;
                        color: #000;
                    }

                    .menuFooter {

                        a {
                            font-size: 13px;
                            color: #666666;
                            text-decoration: none;
                            text-decoration: none;
                            font-weight: 600;
                            white-space: nowrap;
                            transition: 0.5s ease;

                            &:hover {
                                color: #d56939;
                            }

                            svg {
                                font-size: 20px;
                            }
                        }

                    }

                    &.fsec1 {
                        .companyInfoList {
                            padding: 0;
                            margin-top: 18px;

                            .infoListItem {
                                padding: 0;
                                align-items: flex-start;
                                padding-bottom: 8px;

                                .iconSec {
                                    min-width: 28px;

                                    i {
                                        color: #666666;
                                    }
                                }

                                .textSec {
                                    margin: 0;

                                    span {
                                        font-size: 13px;
                                        color: #666666;
                                        font-weight: 600;
                                        font-family: 'Lato', sans-serif;
                                    }
                                }
                            }
                        }
                    }

                    &.fsec2 {
                        margin-top: 18px;
                        margin-left: 40px;

                        .menuFooter {
                            display: grid;
                            justify-content: flex-start;

                            a {
                                margin-bottom: 6px;
                            }
                        }
                    }

                    &.fsec3 {
                        margin-top: 18px;
                        margin-left: 45px;

                        .menuFooter {
                            max-width: 100px;
                            display: grid;
                            gap: 4px;
                        }
                    }
                }

                .bottomMenuList2 {
                    margin-top: -2px;

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;

                        li {
                            margin-bottom: 4px;

                            a {
                                font-size: 13px;
                                color: #666666;
                                text-decoration: none;
                                font-weight: 600;
                                white-space: nowrap;
                                transition: 0.5s ease;

                                &:hover {
                                    cursor: pointer;
                                    color: #d56939;
                                }

                                svg {
                                    color: #626367;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .copyright-text {
            font-size: 13px;
            color: #999999;
            text-align: center;
            border-top: 1px solid #cfcfcf99;
            padding-top: 14px;
            margin-top: 14px;
            margin-bottom: 0;
            padding-bottom: 14px;
            word-spacing: 1px;

            a {
                color: #515151;
                font-weight: 600;
                font-family: Roboto;
                transition: 0.5s ease;

                &:hover {
                    color: #d56939;
                }
            }
        }
    }
}

/* ========= Responsive SCSS ========= */

@media (min-width:1200px) {
    .smFooter {
        display: none;
    }
}

@media (max-width:1199px) {
    .footerArea {
        width: 100%;
        height: auto;
        margin: auto;
        border-radius: 30px 30px 0 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        padding: 0;
        box-shadow: none;

        .lgFooter {
            display: none;
        }

        .smFooter {
            border-radius: 30px 30px 0 0;
            background-color: #fff;

            .footerMenu {
                width: 100%;
                margin: auto;
                justify-content: space-around;
                align-items: center;

                .menuItems {
                    text-align: center;
                    padding: 10px;

                    a {
                        white-space: nowrap;
                        text-align: center;
                        color: #767676;
                        text-decoration: none;

                        svg {

                            &.blogIcon {
                                width: 25px;
                                height: auto;
                            }

                            &.darshanIcon {
                                width: 30px;
                                height: auto;
                            }
                        }

                        &.active {
                            color: #242424;

                            svg {
                                path {
                                    fill: #242424 !important;
                                }
                            }
                        }

                        span {
                            display: block;
                        }
                    }

                    .cartBlock {
                        .smCartIcon {
                            position: relative;
                            display: inline;

                            svg {
                                position: relative;
                            }

                            .smBadge {
                                position: absolute;
                                color: #fff;
                                background-color: #d56939;
                                //left: 91% !important;
                                //top: 12px !important;
                                top: 0;
                                right: 0;
                                transform: translate(50%, -50%) !important;
                                z-index: 1;
                            }
                        }
                    }
                }

            }

        }
    }
}

@media (max-width:899px) {

    .pagehdLeftBlock,
    .pagehdRightBlock {
        display: none;
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .footerArea {
        .wiget {
            &.fsec2 {
                .menuFooter {
                    display: inline-block;
                    max-width: 100%;
                    word-wrap: break-word;

                    a {
                        padding: 0 10px 0 0;
                    }

                    // .cartBlock {
                    //     .smBadge {
                    //         left: 40% !important;
                    //         top: 0 !important;
                    //     }
                    // }
                }
            }
        }


    }

}

@media (min-width: 768px) and (max-width:991px) {
    .footerArea {
        .wiget {
            &.fsec2 {
                padding-left: 20px;

                .menuFooter {
                    max-width: 100%;
                    display: inline-block;
                    word-wrap: break-word;

                    a {
                        padding: 0 10px 0 0;
                        display: inline-block;
                    }
                }
            }
        }
    }

}

@media (min-width: 400px) and (max-width:767px) {
    .footerArea {
        .wiget {
            margin-bottom: 15px;

            &.fsec2 {
                padding-left: 0;

                .menuFooter {
                    display: inline-block;
                    max-width: 100%;
                    word-wrap: break-word;

                    a {
                        padding: 0 10px 0 0;
                        display: inline-block;
                    }
                }
            }

            h6 {
                margin-bottom: 0;
            }
        }
    }

}





@media (min-width: 300px) and (max-width:399px) {
    .footerArea {
        .wiget {
            margin-bottom: 0;

            &.fsec2 {
                padding-left: 0;

                .menuFooter {
                    display: inline-block;
                    max-width: 100%;
                    word-wrap: break-word;

                    a {
                        padding: 0 10px 0 0;
                        display: inline-block;
                    }
                }
            }

            h6 {
                margin-bottom: 0;
            }
        }
    }

}