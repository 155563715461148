.pageTopTitle {
    text-align: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d56939;
    font-weight: 500;
}

.pujaAddedArea {
    padding: 20px 20px;
    background-color: #fff;
    border-radius: 12px;
    margin-top: 20px;

    .pujaAddedDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pujaAddedLeft {
            p {
                font-weight: 600;
                font-size: 18px;
            }
        }

        .pujaAddedRight {
            p {
                color: rgba(0, 0, 0, 0.5);
                font-weight: 600;
                display: flex;
                align-items: center;

                svg {
                    color: #7abe2b;
                    font-size: 18px;
                    margin-left: 3px;
                }

                img {
                    width: 40px;
                    max-width: 100%;
                }
            }
        }
    }

    .pujaAddedMember {
        margin-top: 20px;

        .pujaAddedinfo {
            margin-bottom: 20px;

            .title {
                font-size: 16px;
                font-weight: bold;
                color: #000;
            }

            p {
                color: #5d5d5d;
                margin-bottom: 5px;
                font-weight: 500;
            }
        }

        .viewDetailsBtn {
            button {
                padding: 6px 26px;
                background-color: #000;
                box-shadow: none;
                color: #fff;
                border-radius: 20px;
                margin-right: 10px;
            }
        }
    }
}