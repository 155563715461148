.pageContainer {
    &.manageBlogPage {

        .manageBlogContainer {
            width: 100%;
            padding: 20px 30px 25px;
            border-radius: 14px;
            box-shadow: none;
            margin-bottom: 1rem;


            .blogHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.8rem;

                h4 {
                    font-size: 20px;

                    button {
                        margin-right: 12px;
                        padding: 0;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }

            }

            .continueBtn {
                background: rgb(214, 111, 53);
                border-radius: 0.4rem;
                width: calc(100%);
                height: 40px;
                display: inline-block;
                color: #fff;
                margin: 1rem auto 0 auto;
                box-shadow: none;

                &:hover {
                    background-color: #c45414;
                }
            }
        }
    }

    .blogCodeView {
        width: 100%;
        position: relative;
        margin-top: 50px;

        .MuiPaper-root {
            box-shadow: none;
        }

        .btnAddBlog {
            position: absolute;
            top: -50px;
            right: 0;

            z-index: 1;

            button {
                box-shadow: none;
            }
        }

        .blogDataGrid {
            max-width: 100%;
            margin: auto;

            p {
                margin-top: 1rem;
            }
        }
    }
}

.couponDataList {
    .couponDataGrid {
        .MuiDataGrid-columnHeader {
            padding-left: 16px;
            padding-right: 16px;

            &:focus {
                outline: none;
            }
        }

        .MuiDataGrid-cell {
            padding-left: 16px;
            padding-right: 16px;

            .statusPublished {
                background-color: green;
                color: #fff;
                padding: 10px 18px;
                border-radius: 6px;
            }

            .statusUnpublished {
                background-color: rgb(209, 68, 68);
                color: #fff;
                padding: 10px;
                border-radius: 6px;
            }
        }

        .MuiDataGrid-withBorderColor:focus {
            border-color: transparent;
        }
    }
}