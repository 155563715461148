.offlineViewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    text-align: center;
    padding: 0 24px;

    .imageContent {
        .topImg {
            width: 350px;
            max-width: 100%;
        }
    }

    .titleText {
        font-weight: 500;
        font-size: 22px;
    }

    .subTitleText {
        color: #555555;
    }

    .retryBtn {
        width: 62%;
        margin-top: 20px;
        box-shadow: none;
        padding: 8px 25px;

        &:hover {
            box-shadow: none;
        }
    }

    .bottomMessage {
        width: 70%;
        margin: 38px auto;
        position: relative;

        .messageText {
            font-size: 14px;
            background-color: #3b3b3b;
            padding: 0.7rem 1rem;
            border-radius: 10px;
            color: #e4e4e4;
            text-align: left;
            font-weight: 400;
            animation: fadeInUp 0.5s ease-in-out forwards;
        }
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}



@media (min-width: 330px) and (max-width: 400px) {
    .offlineViewContainer {
        .imageContent {
            margin-top: 80px !important;

            .topImg {
                margin-top: 30px !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .offlineViewContainer {
        margin-top: 30px;

        .imageContent {
            margin-top: 150px;

            .topImg {
                width: 275px;
            }
        }

        .retryBtn {
            width: 96%;
        }

        .bottomMessage {
            width: 100%;
            margin-top: 150px;
        }
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .offlineViewContainer {
        .imageContent {
            margin-top: 150px;
        }
    }
}

@media (min-width: 1200px) {
    .offlineViewContainer {
        .imageContent {
            margin-top: 90px;
        }
    }
}