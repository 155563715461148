.pageContainer {
    &.pujaVideo {
        .templeBlock {
            .pujaVideoArea {
                .pujaVideoTitle {
                    font-weight: 600;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}