.adminSideNavView {
    .pagehdLeftBlock {
        .sideNavList {
            width: 100%;
            height: calc(100% - 3.5rem);
            max-width: 290px;
            background: #fff;
            margin-top: 3.8rem;
            padding-bottom: 0;
            box-shadow: inset -2px 0px 8px rgba(0, 0, 0, 0.3);
            border-radius: 0;

            .listHeaderTitle {
                font-size: 20px;
                background: rgba(0, 0, 0, 0.1);
                color: #000;
                margin-bottom: 20px;
            }

            .listItemBtn {
                border-bottom: 1px solid #c7c7c7;
                padding: 10px 16px;

                &:last-child {
                    border: none;
                }

                &.mainItem {
                    .MuiAccordionSummary-contentGutters {
                        margin: 0 !important;
                        align-items: center;
                    }                      
                }

                .listIconSec {
                    min-width: 30px;

                    svg {
                        font-size: 20px;
                        color: #000;
                    }
                }

                span {
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .listAccordion {
                box-shadow: inset -5px 0 5px -2px rgba(0, 0, 0, 0.3);

                &.Mui-expanded {
                    margin: 0;
                }
                .detailsList {
                    padding: 8px 16px;
                }
            }
        }
    }
    
}

// ---- For Responsive ----
@media (max-width: 1199px) {
    .adminSideNavView {
        .mobileDeviceMessage {
            display: block !important;
            margin: 100px 16px 0;
            text-align: center;
            background-color: #fff;
            color: #000;
            padding: 10px;
            font-size: 14px;
            border-radius: 10px;
            border: 1px solid #d56939;
        }

        .pagehdLeftBlock {
            display: none;
        }
    }
}

@media (min-width: 1200px) {
    .adminSideNavView {
        .mobileDeviceMessage {
            display: none !important;
        }

        .pagehdLeftBlock {
            display: block;
        }
    }
}