.exportButton {
  position: relative;

  button {
    text-transform: capitalize;
    border-radius: 25px;
    padding: 6px 15px;
    box-shadow: none;
    font-weight: 500;
    color: #fff;
    overflow: hidden;
    position: relative;
    background: linear-gradient(90deg, #00c6ff, #007bea, #004aad, #00c6ff);
    background-size: 300% 100%;
    animation: gradientLoop 5s linear infinite;
    margin-bottom: 11px;

    &:hover {
      box-shadow: none;
      background-color: #005bb5;
    }

    // Highlighter effect on hover
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.2);
      transform: skewX(-20deg);
    }

    &:hover::before {
      animation: slideHighlight 1s ease-in-out forwards;
    }

    // Icon styling
    span {
      margin-right: 4px;

      svg {
        transform: rotate(180deg);
        color: #fff;
        animation: bounce 1s ease-in-out infinite;
      }
    }
  }
}

// Keyframes for looping gradient animation
@keyframes gradientLoop {
  0% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

// Keyframes for hover highlighter animation
@keyframes slideHighlight {
  0% {
    left: -100%;
  }

  50% {
    left: 50%;
  }

  100% {
    left: 100%;
  }
}