.specialPujaAdminDialog {
    .spAdminDialogTitle {
        box-shadow: 0 4px 6px #0000001a;
    }

    .innerContent {
        .weightageBtnRow {
            display: flex;
            align-items: center;
            justify-content: left;
            gap: 10px;
            margin-bottom: 14px !important;

            button {
                width: 100%;
            }
        }

        .itemList {
            margin-bottom: 20px;
            margin-top: 0;

            &:first-child {
                margin-top: 24px;
            }
        }
    }

    .spAdminModalFooter {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        padding: 16px 24px;
        box-shadow: 0 -4px 6px #0000001a;

        .btnCancel {
            &.grey {
                color: #696969;
                border-color: #d8d8d8;
                border-width: 1px;
                border-style: solid;

                &:hover {
                    background-color: #f2f2f2;
                }
            }
        }

        .saveBtn {
            background: linear-gradient(90deg, #db4250, #f49e49);
            box-shadow: none;
            color: #fff;
            transition: .3s ease;

            &:hover {
                background: linear-gradient(90deg, #ff4949, #ff8b2d);
            }
        }

        button {
            margin: 0;
            padding: 5px 18px;
        }
    }
}
