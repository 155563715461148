.chadhavaContainer {
    .chadhavaBanner {
        width: 100%;
        margin: auto;
        border-radius: 1.25rem;
        overflow: hidden;
        margin-bottom: 1rem;

        img {
            width: 100%;
        }
    }

    .pujaDescription {
        margin-left: -6px;
        position: relative;

        ul {
            li {
                padding: 0;

                .squreShape {
                    border-radius: 0;
                    background-color: transparent;

                    &.temple {
                        img {
                            width: auto;
                            height: 34px;
                        }
                    }

                    &.location {
                        img {
                            width: 22px;
                            height: auto;
                        }
                    }

                    &.pricetag {
                        img {
                            width: 24px;
                            height: auto;
                        }
                    }
                }

                span {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    line-height: 1.5;
                    max-height: 4.5em;
                    font-size: 16px;
                    color: #181818;
                    font-weight: 500;

                }

                .dflexBdr {
                    display: flex;
                    justify-content: flex-start;
                    margin-left: 0.5rem;
                    padding: 0.5rem 0;
                    border-top: 1px solid rgba(98, 99, 103, 0.36);
                    border-bottom: 1px solid rgba(98, 99, 103, 0.36);

                    .highlight {
                        display: inline-block;
                        color: #d56939;
                        margin-right: 5px;
                        white-space: nowrap;
                        text-overflow: unset;
                    }
                }
            }
        }
    }


}

.chadhavaAddOverlay {
    width: 100vw;
    height: 100vh;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1101;

    .addChadhavadialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        max-width: 100%;
        background-color: #f5f6fa;
        box-shadow: 0.5 0.5 0.5 #181818;
        border-radius: 20px;
        padding: 1.5rem;
        margin: auto;

        .addChadhavaConts {
            h6 {
                font-size: 16px;
                font-weight: 600;
            }

            .chadhavaProduct {
                width: 100%;
                height: auto;
                text-align: center;
                margin-top: 1rem;
                margin-bottom: 1rem;

                img {
                    max-width: 100%;
                    margin: auto;
                }
            }

            .chadhavaDetails {
                p {
                    margin-bottom: 0.5rem;

                    &:first-child {
                        font-weight: 500;
                    }

                    &:last-child {
                        font-size: 1.3rem !important;
                        color: #ca5261;
                        font-weight: 600;
                        margin-top: 1rem;
                        margin-bottom: 1.5rem;
                    }

                    .offerItem {
                        font-size: 0.8rem;
                        background-color: #ca5261;
                        color: #fff;
                        padding: 0.2rem 0.6rem;
                        border-radius: 0.8rem;
                        margin-left: 0.4rem;
                    }
                }
            }

            .addItemsBtns {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                align-items: center;
                margin: auto;

                .btnsGroup {
                    border-radius: 0.4rem;
                    overflow: hidden;
                    font-weight: 600;

                    button {
                        border-radius: 0;
                        padding: 0.5rem 0.6rem;
                        box-shadow: none;
                        min-width: auto !important;
                        font-weight: bolder;

                        svg {
                            font-size: 12px !important;
                            font-weight: bold;
                        }
                    }
                }

                .btnAddChadhava {
                    width: calc(100% - 106px);
                    text-transform: uppercase;
                    font-size: 16px;
                    color: #fff;
                    box-shadow: none;
                    animation: animateBg 5s linear infinite;
                    background-image: linear-gradient(90deg, #ef6572, #f49e49, #ef6572, #f49e49);
                    background-size: 300% 100%;

                    @keyframes animateBg {
                        0% {
                            background-position: 100% 0%;
                        }

                        100% {
                            background-position: 0% 0%;
                        }
                    }
                }
            }
        }

        .btnClose {
            width: 40px;
            height: 40px;
            background-color: #3572de;
            position: absolute;
            right: 0;
            top: -60px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                color: #fff !important;

                svg {
                    fill: #fff !important;
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .chadhavaAddOverlay {
        .addChadhavadialog {
            width: 100% !important;
            transform: none !important;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .btnClose {
                right: 1rem;
            }
        }
    }
}


//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------
.pageContainer {
    &.pujaPage {


        .chadhavaDetailsBlock {
            border-bottom: 3px solid #fff;
            padding-top: 0rem;
            padding-bottom: 1rem;

            &.templeOverview {
                padding-bottom: 0;

                p {
                    font-size: 1rem !important;
                    margin-bottom: 1rem;
                }
            }

            &.aboutTD {
                padding-bottom: 1.5rem;
                border-bottom: 3px solid #fff;

                p {
                    margin-bottom: 1rem;
                    font-size: 1rem !important;
                }

                .btnBlock {
                    .btnChadhavaVideo {
                        width: 100%;
                        font-size: 16px;
                        background: linear-gradient(230deg, #474554, #afaeb9, #474554);
                        background-size: 600% 600%;
                        -webkit-animation: AnimationName 17s ease infinite;
                        -moz-animation: AnimationName 17s ease infinite;
                        -o-animation: AnimationName 17s ease infinite;
                        animation: AnimationName 17s ease infinite;
                        color: #fff;
                        border-radius: 6px;
                    }

                    @-webkit-keyframes AnimationName {
                        0% {
                            background-position: 0% 7%
                        }

                        50% {
                            background-position: 100% 94%
                        }

                        100% {
                            background-position: 0% 7%
                        }
                    }

                    @-moz-keyframes AnimationName {
                        0% {
                            background-position: 0% 7%
                        }

                        50% {
                            background-position: 100% 94%
                        }

                        100% {
                            background-position: 0% 7%
                        }
                    }

                    @-o-keyframes AnimationName {
                        0% {
                            background-position: 0% 7%
                        }

                        50% {
                            background-position: 100% 94%
                        }

                        100% {
                            background-position: 0% 7%
                        }
                    }

                    @keyframes AnimationName {
                        0% {
                            background-position: 0% 7%
                        }

                        50% {
                            background-position: 100% 94%
                        }

                        100% {
                            background-position: 0% 7%
                        }
                    }
                }
            }

            &.chadhavaItems {
                border: none;

                p {
                    margin-bottom: 1rem;
                }

                .chadhavaItem {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-bottom: 1.5rem;
                    gap: 5px;
                    border-bottom: 1px solid rgba(112, 112, 112, 0.2);

                    @media (max-width: 767px) {
                        padding-bottom: 0;
                    }

                    .chadhavaLeft {
                        .mostOfferTag {
                            margin-bottom: 6px;

                            .offerItem {
                                display: flex;
                                width: fit-content;
                                font-size: 0.8rem;
                                background-color: #ca5261;
                                color: #fff;
                                padding: 0.1rem 0.6rem;
                                border-radius: 0.8rem;
                                //margin-left: 0.4rem;
                            }
                        }

                        p {
                            margin-bottom: 0.5rem;

                            &:first-child {
                                font-weight: 500;

                                .itemNameInfo {
                                    margin-right: 0.4rem;
                                }
                            }

                            &:last-child {
                                font-size: 1.2rem !important;
                                color: #ca5261;
                                font-weight: 600;
                                margin-top: 1rem;
                            }
                        }
                    }

                    .chadhavaRight {
                        position: relative;
                        text-align: center;

                        .itemImg {
                            width: 100px;
                            height: 100px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 8px;
                                background-color: #e2e2e2;
                            }
                        }

                        .btnAdd {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -1rem;
                            margin: auto;
                            text-align: center;

                            .chadhavaAdd {
                                background-color: #ffece3;
                                color: #d56939;
                                line-height: 1.2rem;
                                padding-left: 1.5rem;
                                padding-right: 1.5rem;
                            }
                        }

                        .addItemsBtns {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -1rem;
                            margin: auto;
                            text-align: center;

                            .btnsGroup {
                                border-radius: 0.2rem;
                                overflow: hidden;
                                font-weight: 600;

                                button {
                                    border-radius: 0;
                                    padding: 0.2rem 0.5rem;
                                    box-shadow: none;
                                    min-width: auto !important;

                                    svg {
                                        font-size: 12px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: 3px solid #fff;

                &.chadhavaItems {
                    .chadhavaItem {
                        border-bottom: none;
                    }
                }
            }
        }

        .pujaBlock {

            .pujaLeftBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }

            .centerBlockTop {
                width: 100%;
                height: auto;
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
                margin: 0 auto 25px;

                .topField {
                    display: flex;
                    align-items: center;

                    .profileImg {
                        width: 55px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inputBox {
                        width: 100%;

                        input {
                            border-radius: 35px;
                            padding: 14px 16px;
                            background-color: #ebedf6;
                            border: none;
                            box-shadow: none;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }

                .bottomField {
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 100px);
                    margin-left: 80px;
                    margin-top: 16px;

                    .mediaButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 20px;
                                margin-right: 10px;
                            }
                        }
                    }

                    .dateButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 18px;
                                height: 18px;
                                margin-right: 10px;
                            }
                        }
                    }

                    .writePostButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 20px;
                                height: 15px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .pujaCenterBlock {
                .pujaCardBox {
                    width: 100%;
                    height: auto;
                    border-radius: 12px;
                    background-color: #fff;
                    padding: 0;
                    overflow: hidden;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: 448px;
                        object-fit: cover;
                    }

                    .card-body {
                        min-height: 120px;
                        padding: 1rem;

                        .title {
                            color: #901414;
                            font-size: 16px;
                            font-weight: bold;
                            text-transform: uppercase;
                            margin: 0;
                            text-align: left;
                        }

                        .content {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0.5rem 0 0 0;

                            p {
                                color: #000;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 1.5;
                                margin: 0;
                                text-align: left;
                            }

                            a {
                                text-transform: uppercase;
                                text-decoration: none;
                                color: #fff;
                                background-color: #d56939;
                                padding: 8px 15px;
                                border-radius: 20px;
                                font-size: 14px;
                                font-weight: 600;
                                display: inline-block;
                                transition: all 0.7s ease;

                                &:hover {
                                    background-color: #b14a1e;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .pujaRightBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }
        }
    }
}

// Puja Info
.pageContainer {
    &.pujaPage {
        .pujainfoCenterBlock {
            .pujaCard {
                p {
                    //font-size: 18px !important;
                    //font-weight: 700;
                    //margin-bottom: 1rem;
                }

                .pujaCardBlock {
                    background-color: transparent;
                    border-radius: 38px;
                    margin-bottom: 1rem;
                    padding-bottom: 0.2rem;
                }

                .pujaImgBlock {
                    border-radius: 38px;
                    overflow: hidden;
                    //height: auto;
                    height: 36vh;

                    img {
                        width: 100%;
                        height: 100%;
                        margin: auto;
                        object-fit: cover;
                    }
                }

                .pujaDescription {
                    .pujaNameInfo {
                        padding: 1rem 0rem;
                        padding-bottom: 0;

                        p {
                            margin-bottom: 0;
                            font-size: 18px;
                            font-weight: 700;
                        }
                    }

                    .chadhavaNameLocPrice {
                        //margin-bottom: 1rem;
                        position: relative;

                        ul {
                            padding: 1rem 0.5rem 1rem 0;

                            .twoItem {
                                .shareIcon {
                                    cursor: pointer;

                                    svg {
                                        background: linear-gradient(-45deg, #d66f35, #d8a820, #d66f35, #d8a820);
                                        background-size: 200% 200%;
                                        animation: gradient 15s ease infinite;
                                        border-radius: 50%;
                                        padding: 3px;
                                        font-size: 26px;
                                        color: #fff;
                                        transform: scaleX(-1);
                                    }
                                }
                            }

                            li {
                                padding: 0;

                                .squreShape {
                                    width: fit-content;
                                    border-radius: 0;
                                    background-color: transparent;

                                    &.pricetag {
                                        img {
                                            width: 24px;
                                            height: auto;
                                        }
                                    }

                                    &.temple {
                                        img {
                                            width: auto;
                                            height: 34px;
                                            margin-left: 3px;
                                        }
                                    }

                                    &.location {
                                        img {
                                            width: 22px;
                                            height: auto;
                                        }
                                    }

                                    &.pricetag {
                                        img {
                                            width: 24px;
                                            height: auto;
                                        }
                                    }
                                }

                                span {
                                    font-size: 16px;
                                    color: #434242;
                                    font-weight: 500;
                                }

                                .pujaPriceInfo {
                                    span {
                                        display: inline-block;
                                        padding: 6px 12px;
                                        border-radius: 20px;
                                        background-color: #ca5261;
                                        color: #fff;
                                    }
                                }

                                .couponDiscountCode {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    padding: 6px 12px;
                                    border-radius: 20px;
                                    background-color: #bf60d5;
                                    font-size: 16px !important;
                                    font-weight: 500;
                                    margin-bottom: 0;
                                    margin-left: 10px;
                                    text-transform: uppercase;
                                    color: #fff;

                                    svg {
                                        color: #bf60d5;
                                        background-color: #fff;
                                        font-size: 20px;
                                        padding: 4px;
                                        border-radius: 50%;
                                        margin-right: 5px;
                                    }
                                }

                                .disclaimerText {
                                    width: 100%;
                                    display: flex;
                                    color: #333333;
                                    background-color: #fff6f2;
                                    border: 1px solid #c75b2b;
                                    padding: 8px 10px;
                                    font-size: 12px !important;
                                    font-weight: 500;
                                    border-radius: 6px;
                                    margin: 10px 0 0 0;

                                    svg {
                                        color: #c75b2b;
                                        font-size: 18px;
                                        margin-right: 5px;
                                    }
                                }
                            }

                            .devoteeInfomation {
                                width: 100%;
                                border-top: 1px solid #cfcfcf;
                                border-bottom: 1px solid #cfcfcf;
                                padding: 10px 0;
                                margin: 0.5rem 0;
    
                                .countText {
                                    color: #434242;
                                    font-size: 16px !important;
                                    font-weight: 500;
                                    margin-bottom: 0 !important;
                                    padding-left: 8px;
    
                                    @media (min-width: 360px) and (max-width: 384px) {
                                        font-size: 14px !important;
                                    }
    
                                    @media (min-width: 385px) and (max-width: 407px) {
                                        font-size: 15px !important;
                                    }
    
                                    span {
                                        color: #d56939;

                                        @media (min-width: 360px) and (max-width: 450px) {
                                            font-size: 14px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }


                }

            }

            .overViewText {
                border-bottom: 3px solid #fff;
                padding-bottom: 1rem;
                padding-top: 1rem;

                p {
                    &:first-child {
                        margin-bottom: 1rem;
                    }

                    &:last-child {
                        font-size: 16px !important;
                        white-space: pre-line;
                    }
                }
            }

            .templeDekhoVideo {
                padding-bottom: 1.5rem;
                border-bottom: 3px solid #fff;
                padding-top: 1rem;

                p {
                    margin-bottom: 1rem;

                    span {
                        font-weight: 600;
                    }
                }

                .ChadhavSeeVideoBtn {
                    width: 100%;
                    font-size: 14px;
                    background: linear-gradient(230deg, #474554, #afaeb9, #474554);
                    background-size: 600% 600%;
                    animation: AnimationName 17s ease infinite;
                    color: #fff;
                    border-radius: 6px;
                    box-shadow: none;
                }
            }

            .SelectPackage {
                padding-bottom: 1.5rem;
                border-bottom: 3px solid #fff;

                p {
                    &:first-child {
                        margin-bottom: 1rem;
                    }
                }

                .packageItem {
                    .packageCard {
                        background-color: #fff;
                        border: 1px solid #cccbcb;
                        text-align: center;
                        padding: 14px;
                        border-radius: 12px;
                        position: relative;
                        margin-right: 14px;

                        &.active {
                            background-color: #fff6f2;
                            border: 1px solid #ffcdb5;
                        }

                        img {
                            width: 85px;
                            max-width: 100%;
                        }

                        p {
                            font-weight: 500;
                            margin-top: 8px;
                        }

                        h6 {
                            font-size: 16px;
                            font-weight: bold;
                            margin-top: 3px;
                        }

                        span {
                            padding: 0;
                            margin-top: 4px;
                        }

                        .nwlyAddedbadge {
                            width: auto;
                            height: auto;
                            padding: 6px 10px;
                            position: absolute;
                            top: 10px;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            white-space: nowrap;
                            //display: inline-table;
                            bottom: auto;
                            background-color: #ca5261;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                            border-radius: 5px;
                        }
                    }
                }

                .packagePujaInfo {
                    background-color: #fff6f2;
                    border: 1px solid #ffcdb5;
                    padding: 14px;
                    border-radius: 12px;
                    margin-top: 16px;

                    .packagePujaTitle {
                        img {
                            width: 70px;
                            max-width: 100%;
                        }

                        h6 {
                            color: #d56939;
                            font-size: 16px;
                            font-weight: bold;
                        }

                        p {
                            color: #000;
                            font-weight: bold;
                            font-size: 14px;
                        }
                    }

                    .packagePujaSteps {
                        margin-top: 6px;

                        ul {
                            max-width: 100%;
                            background-color: transparent;
                            padding-bottom: 0;

                            li {
                                padding: 0;
                                align-items: flex-start;

                                .MuiListItemAvatar-root {
                                    min-width: 40px;

                                    .MuiAvatar-root {
                                        background-color: transparent;
                                        width: 30px;

                                        img {
                                            width: 20px;
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .loadingCircle {
                display: flex;
                justify-content: center;
                margin: 50px 0;
            }

            .pujaBenefits {
                padding-top: 1rem;
                margin-top: 0;

                .benefitsCard {
                    margin-top: 1rem;
                    margin-bottom: 1rem;

                    .benefitCardImg {
                        border-radius: 20px;
                        overflow: hidden;
                        margin-bottom: 1rem;

                        img {
                            width: 100%;
                        }
                    }

                    p {
                        &:first-child {
                            margin-bottom: 1rem;
                        }
                    }

                    p {
                        &:last-child {
                            font-size: 16px !important;
                        }
                    }
                }
            }
        }
    }
}

.chadhavaProccessSteps {
    padding-top: 1rem;
    border-top: 3px solid #fff;

    ul {
        li {
            position: relative;
            padding: 0.5rem 0;

            .listCounter {
                display: flex;
                align-items: baseline;

                .pujaStepsListCount {
                    margin-left: 0;
                    color: #fff;
                    background-color: #000;
                    border-radius: 50%;
                    padding: 1px 7px;
                    margin-right: 14px;
                    font-size: 14px;
                }
            }

            // &::before {
            //     width: 22px;
            //     height: 22px;
            //     content: "";
            //     position: absolute;
            //     left: -21px;
            //     top: 14px;
            //     background-color: #000;
            //     border-radius: 50%;
            //     color: #fff;
            //     //border: 1px solid #000;
            //     //transform: translateY(-50%);
            //     z-index: -1;
            // }
        }
    }
}

.chadhavaTestimonial {
    padding-top: 1rem;
    border-top: 3px solid #fff;
    margin-bottom: 20px;

    .react-multi-carousel-dot button {
        border: none;
        width: 10px;
        height: 10px;
        background: rgba(213, 105, 57, 0.3);
    }

    .react-multi-carousel-dot--active button {
        background-color: #d56939;
    }

    .react-multiple-carousel__arrow {
        min-width: 26px;
        min-height: 26px;
        border-radius: 50%;
        background-color: rgba(213, 105, 57, 0.3); //For Arrow Color

        &:hover {
            background-color: #d56939;
        }

        &::before {
            font-size: 14px;
        }
    }

    .react-multiple-carousel__arrow--right {
        right: calc(2% + 1px);
    }

    .react-multiple-carousel__arrow--left {
        left: calc(2% + 1px);
    }
}

.chadhavaFaq {
    padding-top: 1rem;
    border-top: 3px solid #fff;
    margin-bottom: 60px;

    .chadhavaFaqList {
        margin-top: 4px;
        font-family: Roboto;

        .accordionInfo {
            background-color: transparent;
            box-shadow: none;
        }
    }
}

.viewPujaSteps {
    img {
        width: 100%;
        cursor: pointer;
    }
}

.chadhavaContinueBtn {
    &.desktopOnly {
        background: linear-gradient(-45deg, #0000 33%, hsla(0, 0%, 100%, .5) 50%, #0000 66%), linear-gradient(-45deg, #d8a820, #d66f35);
        background-size: 250% 250%, 100% 100%;
        //background: linear-gradient(-45deg, #d66f35, #d8a820, #d66f35, #d8a820);
        //background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        border-radius: 0.8rem 0.8rem 0 0;
        padding: 10px 18px;
        width: -webkit-fill-available;
        display: inline-block;
        font-size: 18px;
        font-weight: 600;

        //For Bottom
        //position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;

        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }

            50% {
                background-position: 100% 50%;
            }

            100% {
                background-position: 0% 50%;
            }
        }

        p {
            font-size: 16px;
            color: #fff;
            font-weight: 600;
        }

        .desktopContinueBtn {
            background-color: #fff;
            color: #d56939;
            padding: 0.5rem 1.5rem;
            border-radius: 20px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
        }
        .desktopContinueBtnGrey {
            background-color: #fff;
            color: #b2b2b2;
            padding: 0.5rem 1.5rem;
            border-radius: 20px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}

.blessContinueChadhava {
    position: relative;

    .viewBless {
        padding-top: 50px;

        img {
            width: 100%;
        }
    }

    .continueCart {
        background: rgb(214, 111, 53);
        background: linear-gradient(90deg, #d66f35 0%, #d8a820 100%);
        border-radius: 1rem 1rem 0 0;
        padding: 1rem;
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;

        .continueCenterBlock {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .continueLt {
                font-size: 16px;
                color: #fff;
                font-weight: 600;
            }

            .continueBtn {
                background-color: #fff;
                color: #d56939;
                padding: 0.5rem 1.5rem;
                border-radius: 20px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                
            }

            .continueBtnDsbl {
                background-color: #fff;
                color: #b2b2b2 !important;
                padding: 0.5rem 1.5rem;
                border-radius: 20px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
}


// Responsive SCSS ============================================= //

@media (min-width: 1200px) {
    .continueCart {
        display: none;
    }

    .pujainfoCenterBlock {
        .blessContinueChadhava {
            .viewBless {
                display: none;
            }
        }
    }
}

@media (max-width: 1200px) {
    .pujainfoCenterBlock {
        .blessContinueChadhava {
            padding-bottom: 38px;
        }
    }
}

//Puja Info Banner Img Height
@media (min-width: 330px) and (max-width: 376px) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaImgBlock {
                        height: 27vh !important;
                        //height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 377px) and (max-width: 436px) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaImgBlock {
                        height: 22vh !important;
                        //height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 437px) and (max-width: 768px) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaImgBlock {
                        height: 37vh !important;
                        //height: auto;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 820px) and (max-device-width: 820px) and (min-device-height: 1180px) and (max-device-height: 1180px) and (orientation: portrait) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaImgBlock {
                        // height: 23vh !important;
                        //height: auto;
                    }
                }
            }
        }
    }
}

//Puja Info Banner Img Height END



@media (max-width: 1199px) {
    .chadhavaContinueBtn {
        &.desktopOnly {
            display: none !important;
        }
    }

    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaDescription {
                        .chadhavaNameLocPrice {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .blessContinueChadhava {
        margin-bottom: 30px;
    }
}

@media (min-width:901px) and (max-width:1199px) {
    .blessContinueChadhava {
        .continueCart {
            position: fixed !important;
            bottom: 70px !important;
            width: calc(68% - 42px) !important;
        }
    }
}

@media (max-width:900px) {
    .continueCart {
        position: fixed !important;
        bottom: 70px !important;
        width: calc(100% - 40px) !important;
    }

    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaDescription {
                        .chadhavaNameLocPrice {
                            .couponSmDiscountCode {
                                display: block;
                                width: calc(100% - 5rem);
                                display: flex;
                                align-items: center;
                                padding: 6px 12px;
                                border-radius: 20px;
                                background-color: #bf60d5;
                                font-size: 16px !important;
                                font-weight: 500;
                                margin-bottom: 0;
                                margin-top: 10px;
                                margin-left: 50px;
                                text-align: center;
                                text-transform: uppercase;
                                color: #fff;

                                svg {
                                    color: #bf60d5;
                                    background-color: #fff;
                                    font-size: 20px;
                                    padding: 4px;
                                    border-radius: 50%;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


}

@media (min-width: 900px) {
    .pujainfoCenterBlock {
        .blessContinueChadhava {
            .viewBless {
                img {
                    display: none;
                }
            }
        }
    }
}

@media (min-width: 360px) and (max-width: 767px) {

    .pageContainer.pujaPage {
        .pujaBlock {

            .pujaLeftBlock,
            .pujaRightBlock {
                display: none;
            }

            .pujaCenterBlock {

                .pujaCardBox {
                    margin-bottom: 15px;

                    img {
                        height: 210px;
                    }
                }
            }
        }
    }


    //Puja Info
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .SelectPackage {
                    .packageItem {
                        .packageCard {
                            .nwlyAddedbadge {
                                padding: 5px 10px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 340px) and (max-width: 400px) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .SelectPackage {
                    .packageItem {
                        .packageCard {
                            p {
                                font-size: 13px;
                            }

                            h6 {
                                font-size: 14px;
                            }

                            .nwlyAddedbadge {
                                padding: 5px 10px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.boxDisable{
    width: 300;
    height: 100;
    background-color: 'lightgray';
    opacity: 0.5;         
    pointer-events:'none'; 
    cursor:'not-allowed';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    pointer-events: none;
  }