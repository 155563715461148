.pageContainer {
    &.templeDetailsPage {

        .templeDetailsBlock {

            .templeLeftDetailsBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }

            .templeDetailsCenterBlock {
                position: relative;

                .shareIcon {
                    cursor: pointer;
                    position: absolute;
                    top: 24px;
                    right: 24px;

                    svg {
                        background-color: #fff;
                        border-radius: 50%;
                        padding: 3px;
                        font-size: 26px;
                        color: #000;
                        transform: scaleX(-1);
                    }
                }



                .templeDetailTopBanner {
                    width: 100%;
                    height: 40vh;
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: relative;
                    border-radius: 12px 12px 0 0;

                    .bannerContent {
                        position: absolute;
                        bottom: -110px;
                        left: 7%;

                        img {
                            width: 120px;
                            height: 120px;
                            border-radius: 50%;
                            object-fit: cover;
                            border: 5px solid #d56939;
                        }
                    }

                    .carouselImages {
                        img {
                            width: 100%;
                            height: 50vh;
                            object-fit: cover;
                            border-radius: 12px 12px 0 0;
                        }

                        .react-multi-carousel-list{
                            button {
                                &.react-multiple-carousel__arrow {
                                    min-width: 30px;
                                    min-height: 30px;
                                    &::before {
                                        font-size: 14px;
                                    }
                                }
                            }
                            .react-multi-carousel-dot-list  {
                                bottom: 1rem;
                                li {
                                    &.react-multi-carousel-dot {
                                        button {
                                            width: 9px;
                                            height: 9px;
                                            border: none;
                                            border-width: 1px;
                                            border-radius: 50% !important;
                                            margin-left: 3px;
                                            margin-right: 3px;
                                        }
                                    }
                                    &.react-multi-carousel-dot--active {
                                        button {
                                            background-color: #ff6724 !important;
                                        }
                                    } 
                                }
                            }
                        }
                    }
                }

                .ganpatiTempleDetails {
                    background-color: #fff;
                    padding: 20px 30px;
                    border-radius: 0 0 12px 12px;

                    .templeDetailsTitle {
                        font-size: 22px;
                        font-weight: bold;
                        margin-top: 110px;
                        // margin-bottom: 14px;

                        @media (max-width: 767px) {
                            margin-top: 44px;
                        }

                        img {
                            width: 16px;
                            margin-left: 10px;
                            position: relative;
                            top: -2px;
                        }
                    }

                    .ratings {
                        display: flex;
                        margin: 6px 0 14px;

                        @media (max-width: 1199px) {
                            margin: 6px 0 10px;
                        }

                        i {
                            color: #d56939;
                            padding-right: 5px;
                            font-size: 14px;
                        }

                        p {
                            color: #cbcbcb;
                            margin-bottom: 0;
                            font-weight: 400;

                            span {
                                color: #d56939;
                                padding-right: 3px;
                            }
                            .ratingCount {
                                color:#181818;
                            }
                        }
                    }

                    .disclaimerText {
                        display: none;
                    }

                    .templeDetailsContent {
                        .aboutTemple {
                            text-align: justify;
                            border-bottom: 1px solid #ebedf6;
                            padding-bottom: 14px;

                            .MuiPaper-root {
                                white-space: pre-line;
                            }

                            p {
                                border-bottom: 1px solid #ebedf6;
                                padding-bottom: 15px;
                            }
                        }

                        .addressTemple {
                            padding-top: 14px;
                            display: flex;
                            gap: 0.7rem;

                            i {
                                color: #000;
                                padding-right: 10px;
                            }
                        }

                        .openingPujaTime {
                            margin-bottom: 1rem;
                            display: flex;
                            align-items: baseline;
                            justify-content: space-between;
                            font-size: 14px;

                            .openingTime {
                                display: flex;
                                gap: 0.7rem;

                                i {
                                    color: #000;
                                    padding-right: 10px;
                                }

                            }

                            .pujaTime {
                                display: flex;
                                gap: 0.7rem;

                                i {
                                    color: #000;
                                    padding-right: 10px;
                                }
                            }
                        }

                        .phnTemple {
                            font-size: 14px;
                            display: flex;
                            gap: 0.7rem;

                            i {
                                color: #000;
                                padding-right: 10px;
                            }
                        }

                        .googleMap {
                            margin-top: 15px;
                            border-radius: 10px;
                            overflow: hidden;

                            iframe {
                                width: 100%;
                                height: 45vh;
                                border-radius: 5px;
                            }
                        }

                        .likeSharebtn {
                            display: flex;
                            align-items: center;
                            margin-top: 10px;

                            .likeBtn {
                                img {
                                    max-width: 30px;
                                    margin-right: 10px;
                                }
                            }

                            .shareBtn {
                                img {
                                    max-width: 30px;
                                }
                            }
                        }
                    }

                    .bottomTitle {
                        text-align: center;

                        h6 {

                            text-transform: uppercase;
                            padding-bottom: 0;
                            margin-bottom: 0;
                            margin-top: 10px;
                            color: #000;
                            font-weight: bold;
                            position: relative;
                            background-color: #fff;
                            z-index: 2;
                            padding-left: 15px;
                            padding-right: 15px;

                            &::after {
                                width: 100%;
                                height: 1px;
                                background-color: #707070;
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 0;
                                right: 0;
                                opacity: 0.2;
                            }
                        }
                    }

                    .commentInputBox {
                        width: 100%;
                        margin-top: 3em;

                        .recentComment {
                            .topTitle {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                img {
                                    max-width: 20px;
                                }
                            }

                            .topComments {
                                margin-top: 15px;

                                .commentList {
                                    display: flex;

                                    img {
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 50%;
                                        margin-right: 10px;
                                    }

                                    p {
                                        a {
                                            color: #a3a3a3;
                                            text-decoration: none;
                                        }

                                        h6 {
                                            margin-bottom: 0;
                                            font-weight: bold;

                                            span {
                                                color: #a3a3a3;
                                                margin-left: 10px;
                                                font-weight: normal;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .commentBox {
                            height: 10em;
                            margin-top: 15px;
                            box-shadow: none;
                            border: 1px solid #d0d0d0;
                        }

                        button {
                            color: #fff;
                            text-transform: capitalize;
                            background-color: #d56939;
                            padding: 8px 30px;
                            border-radius: 20px;
                            font-size: 14px;
                            font-weight: 600;
                            display: inline-block;
                            margin-top: 20px;
                            transition: all 0.7s ease;

                            &:hover {
                                background-color: #b14a1e;
                            }
                        }
                    }
                }
            }

            .templeRightDetailsBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }
        }
    }
}


// Responsive SCSS ============================================= //

@media (min-width: 1200px) {
    .templeDetailsButton {
        width: 100%;
        margin-top: 5rem;
        display: flex;

        .bookYourPujaBtn {
            background: linear-gradient(-45deg, 
                #0000 30%, 
                hsla(45, 100%, 55%, 1) 50%,  
                #0000 70%
            ), 
            linear-gradient(-45deg, #ff9f00, #d66f35);
            background-size: 250% 250%, 100% 100%;
            animation: gradient 10s ease infinite, glow 1.5s infinite alternate;
            border-radius: 1rem 1rem 0 0;
            padding: 0.8rem 1.2rem 1rem;
            height: auto;
            display: inline-block;
            color: #fff;
            margin: 0 auto;
            cursor: default;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1000;

            @keyframes gradient {
                0% { background-position: 0% 50%; }
                50% { background-position: 100% 50%; }
                100% { background-position: 0% 50%; }
            }

            @keyframes glow {
                0% { box-shadow: 0 0 10px rgba(255, 200, 50, 0.6), 0 0 20px rgba(255, 170, 50, 0.5); }
                100% { box-shadow: 0 0 20px rgba(255, 200, 50, 0.9), 0 0 40px rgba(255, 170, 50, 0.8); }
            }

            &.templeClosedBtn {
                color: rgba(0, 0, 0, 0.26);
                box-shadow: none;
                background: #e0e0e0;
                text-align: center;
                text-transform: uppercase;
                animation: gradient 10s ease infinite;
            }

            .desktopBookingBtnRow {
                button {
                    width: 26%;
                    background-color: #fff;
                    color: #d56939;
                    border-radius: 30px;
                    padding: 6px 14px;
                }
            }

            .mobileFabContainer {
                display: none;
            }
        }
    }
}

@media (max-width: 1199px) {
    .pageContainer{
        &.templeDetailsPage {
            .templeDetailsBlock {
                .templeDetailsCenterBlock {
                    .ganpatiTempleDetails {
                        .templeDetailsButton {
                            width: 100%;
                            margin-top: 3rem;
                            display: flex;
                    
                            .bookYourPujaBtn {
                                background: linear-gradient(-45deg, #d66f35, #d8a820, #d66f35, #d8a820);
                                background-size: 400% 400%;
                                animation: gradient 15s ease infinite;
                                width: calc(100% - 40px) !important;
                                position: fixed;
                                font-size: 20px;
                                left: 0;
                                right: 0;
                                //bottom: 68px;
                                height: auto;
                                padding: 0.8rem 1.2rem 1rem;
                                display: inline-block;
                                color: #fff;
                                margin: 0 auto !important;
                                z-index: 100;
                                border-radius: 16px 16px 0px 0px;
                    
                                @keyframes gradient {
                                    0% {
                                        background-position: 0% 50%;
                                    }
                    
                                    50% {
                                        background-position: 100% 50%;
                                    }
                    
                                    100% {
                                        background-position: 0% 50%;
                                    }
                                }

                                &.templeClosedBtn {
                                    color: rgba(0, 0, 0, 0.26);
                                    box-shadow: none;
                                    background: #e0e0e0;
                                    text-align: center;
                                    text-transform: uppercase;
                                    bottom: 68px;
                                }
                    
                                .desktopBookingBtnRow {
                                    display: none;

                                    button {
                                        width: 30%;
                                        background-color: #fff;
                                        color: #d56939;
                                        border-radius: 30px;
                                        padding: 6px 14px;
                                    }
                                }

                                .mobileFabContainer {
                                    position: fixed;
                                    bottom: 120px;
                                    right: 20px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-end;

                                    // Background overlay using ::before
                                    &.openBg::before {
                                        content: "";
                                        position: fixed;
                                        top: 0;
                                        left: 0;
                                        width: 100vw;
                                        height: 100vh;
                                        background: rgba(0, 0, 0, 0.75);
                                        z-index: -1;
                                        opacity: 1;
                                        transition: opacity 0.3s ease;
                                    }

                                    // Show buttons when open
                                    &.openBg .fab-action {
                                        opacity: 1;
                                        transform: translateY(0);
                                    }

                                    .fabMainAdd {
                                        background: linear-gradient(-45deg, 
                                            #0000 30%, 
                                            hsla(39, 100%, 55%, 0.9) 50%,
                                            #0000 70%
                                        ), 
                                        linear-gradient(360deg, #db4250, #f49e49);

                                        background-size: 250% 250%, 100% 100%;
                                        animation: gradient 10s ease infinite;
                                        position: relative;
                                        overflow: hidden;

                                        @keyframes gradient {
                                            0% { background-position: 0% 50%; }
                                            50% { background-position: 100% 50%; }
                                            100% { background-position: 0% 50%; }
                                        }
                                      
                                        svg {
                                          color: #fff;
                                          font-size: 30px;
                                        }
                                    }

                                    .fabAction {
                                        background-color: #d56939;
                                        color: #fff;
                                        border-radius: 30px;
                                        border: none;
                                        margin-bottom: 10px;
                                        padding: 6px 16px;
                                        font-size: 16px;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }

                        .disclaimerText {
                            display: block;
                            margin-bottom: 14px;

                            p {
                                width: 100%;
                                display: flex;
                                border-radius: 6px;
                                color: #333333;
                                background-color: #fff6f2;
                                border: 1px solid #c75b2b;
                                padding: 8px 10px;
                                font-size: 12px !important;
                                font-weight: 500;

                                svg {
                                    color: #c75b2b;
                                    font-size: 18px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .pageContainer {
        &.templeDetailsPage {
            .templeDetailsBlock {
                .templeDetailsCenterBlock {
                    .ganpatiTempleDetails {
                        padding-bottom: 40px;
                    }
                }
            }
        }
    }

    .templeDetailsButton {
        .bookYourPujaBtn {
            width: calc(68% - 40px) !important;
        }
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .pageContainer {
        &.templeDetailsPage {
            padding-top: 36px;

            .templeDetailsBlock {
                .templeDetailsCenterBlock {
                    .templeDetailTopBanner {
                        .carouselImages {
                            img {
                                height: 46vh;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 420px) and (max-width: 767px) {
    .pageContainer {
        &.templeDetailsPage {
            padding-top: 40px;
        }
    }
}

@media (min-width: 330px) and (max-width: 767px) {

    .pageContainer.templeDetailsPage {
        .templeDetailsBlock {

            .templeLeftDetailsBlock,
            .templeRightDetailsBlock {
                display: none;
            }

            .templeDetailsCenterBlock {

                .templeDetailTopBanner {
                    height: 25vh;

                    .bannerContent {
                        bottom: -46px;

                        img {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .carouselImages {
                        img {
                            height: 25vh;
                        }
                    }
                }

                .ganpatiTempleDetails {
                    padding: 20px;

                    .templeDetailsContent {
                        .openingPujaTime {
                            .openingTime {
                                margin-right: 7px;
                            }

                            .pujaTime {
                                margin-left: 7px;
                            }
                        }
                    }

                    .templeDetailsButton {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

@media (min-width: 1400px) and (max-width: 2400px) {
    .pageContainer {
        &.templeDetailsPage {

            .templeDetailsBlock {
                .templeDetailsCenterBlock {
                    .templeDetailTopBanner {
                        .carouselImages {
                            img {
                                height: 48vh;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1400px) and (max-width: 2400px) {
    .pageContainer {
        &.templeDetailsPage {

            .templeDetailsBlock {
                .templeDetailsCenterBlock {
                    .templeDetailTopBanner {
                        .carouselImages {
                            img {
                                height: 48vh;
                            }
                        }
                    }
                }
            }
        }
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
}