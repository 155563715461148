.numerology-container {
  .numerology-title {
    font-size: 14px;
    color: #181818;
    text-align: left;
    font-weight: bold !important;
  }

  .numerology-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    margin-top: 10px;
    border-radius: 13px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    .card-text {
      flex: 1;
      padding-left: 6px;
  
      .numerology-heading {
        color: #d56939;
        font-weight: bold;
        font-size: 14px;
      }
  
      .numerology-equation {
        color: #181818;
        font-size: 14px;
      }
    }
  
    .card-number {
      background-color: #ca5261;
      color: #ffffff;
      border-radius: 20px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      h3 {
        font-size: 26px;
      }
    }
  }
}