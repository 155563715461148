// .religiousChatContainer {
//   .valmikiImage {
//     margin-bottom: 6px;
//     margin-left: -1.2rem;
//     margin-right: -1.2rem;
//   }

//   .messageContainer {
//     .chatItemSuggestionBtn {
//       margin-top: 6px;
//       margin-bottom: 10px;

//       .messageBox {
//         width: fit-content;
//         cursor: pointer;

//         &.whiteBox {
//           background-color: #fff;
//           color: #000;
//           border-radius: 25px;
//           padding: 8px 15px;
//           margin: 10px 0;

//           p {
//             font-size: 14px;
//           }
//         }
//       }
//     }
//   }
// }

.chatBackgroundInfo {
  .valmikiImage {
    margin-bottom: 6px;
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    background-color: #fff;
    border-radius: 0 0 30px 30px;
    overflow: hidden;
    box-shadow: 0px 5px 30px 0 rgba($color: #000000, $alpha: 0.16);
    position: relative;
  }

  .messageContainer {
    .chatItemSuggestionBtn {
      margin-top: 15px;
      margin-bottom: 15px;
      justify-content: flex-start;
      //gap: 10px;

      &.gapAdded {
        gap: 10px;
      }

      .messageBox {
        width: fit-content;
        cursor: pointer;
        animation: bubble 0.6s ease-out;
        animation-delay: 0.5s;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &.fullWidthCenter {
          width: 100%;
          text-align: center;
          margin: 0 !important;
        }

        &.whiteBox {
          background-color: #fff;
          color: #000;
          border-radius: 25px;
          padding: 8px 15px;
          margin: 5px 0;

          &:hover {
            transform: scale(1.05);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
          }

          .message-text {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }
          }
        }
      }

      @keyframes bubble {
        0% {
          transform: scale(0) translateX(-100px);
          opacity: 0;
        }

        50% {
          transform: scale(1.1) translateX(10px);
          opacity: 0.8;
        }

        100% {
          transform: scale(1) translateX(0);
          opacity: 1;
        }
      }
    }
  }
}

.dNone{
  display: none !important;
}
