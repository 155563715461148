.birthDetailsContainer {
  margin: 20px auto;

  .description {
    font-size: 14px;
    font-weight: bold;
    color: #181818;
    margin-bottom: 16px;
  }

  .editDetailsBtn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;

    .editBtn {
      background-color: #181818;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      border: 1px solid #181818;
      text-transform: capitalize;
      padding: 5px 10px;
      border-radius: 6px 6px 0 0;

      span {
        margin-left: 6px;

        svg {
          font-size: 16px;
        }
      }
    }
  }

  .detailsOfTable {
    width: 100%;
    //max-width: 400px;
    margin: 0 auto;
    border: 1px solid #e0e0e0;
    height: auto !important;
    //max-height: 270px;
    border-radius: 0;
    background-color: #fff;
    box-shadow: none;

    .MuiTableCell-root {
      font-size: 14px;
      padding: 12px 16px;

      &:first-child {
        font-weight: bold;
        border-right: 1px solid #e0e0e0;
      }
    }
  }
}