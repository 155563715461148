.liveDarshanPage {
    .pageCenterBlock {
        .liveDarshanView {
            .liveDarshanCardDesign {
                .liveDarshanCardLt {
                    display: flex;
                    align-items: center;

                    .mandirImg {

                        .mandirImgB {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            overflow: hidden;
                            background-color: #767676;
                            margin-right: 1rem;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                    }

                    .mandirDetails {
                        p {
                            font-size: 14px;
                            color: #242424;

                            span {
                                font-weight: 500;
                            }
                        }

                    }
                }

                .liveDarshanCardRt {
                    text-align: right;
                }
            }
        }
    }
}



/* ========= Responsive SCSS ========= */

@media (min-width:350px) and (max-width: 767px) {
    .liveDarshanPage {
        .pageCenterBlock {
            .liveDarshanView {
                .liveDarshanCardDesign {
                    margin: 0 !important;
                    margin-bottom: 10px !important;

                    .liveDarshanCardLt {
                        .mandirImg {
                            .mandirImgB {
                                width: 50px;
                                height: 50px;
                            }
                        }
    
                        .mandirDetails {
                            p {
                                font-size: 12px;
                            }
                        }
                    }

                    .liveDarshanCardRt {
                        button {
                            padding: 8px 20px;
                            margin: 0 !important;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}