.pageContainer {
    &.pujaPage {

        .pujaBlock {

            .pujaLeftBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }

            .centerBlockTop {
                width: 100%;
                height: auto;
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
                margin: 0 auto 25px;

                .topField {
                    display: flex;
                    align-items: center;

                    .profileImg {
                        width: 55px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inputBox {
                        width: 100%;

                        input {
                            border-radius: 35px;
                            padding: 14px 16px;
                            background-color: #ebedf6;
                            border: none;
                            box-shadow: none;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }

                .bottomField {
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 100px);
                    margin-left: 80px;
                    margin-top: 16px;

                    .mediaButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 20px;
                                margin-right: 10px;
                            }
                        }
                    }

                    .dateButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 18px;
                                height: 18px;
                                margin-right: 10px;
                            }
                        }
                    }

                    .writePostButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 20px;
                                height: 15px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .pujaCenterBlock {
                .pujaCardBox {
                    width: 100%;
                    height: auto;
                    border-radius: 12px;
                    background-color: #fff;
                    padding: 0;
                    overflow: hidden;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: 448px;
                        object-fit: cover;
                    }

                    .card-body {
                        min-height: 120px;
                        padding: 1rem;

                        .title {
                            color: #901414;
                            font-size: 16px;
                            font-weight: bold;
                            text-transform: uppercase;
                            margin: 0;
                            text-align: left;
                        }

                        .content {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0.5rem 0 0 0;

                            p {
                                color: #000;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 1.5;
                                margin: 0;
                                text-align: left;
                            }

                            a {
                                text-transform: uppercase;
                                text-decoration: none;
                                color: #fff;
                                background-color: #d56939;
                                padding: 8px 15px;
                                border-radius: 20px;
                                font-size: 14px;
                                font-weight: 600;
                                display: inline-block;
                                transition: all 0.7s ease;

                                &:hover {
                                    background-color: #b14a1e;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .pujaRightBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }
        }
    }
}

// Puja Info
.pageContainer {
    &.pujaPage {
        .pujainfoCenterBlock {
            .pujaCard {
                p {
                    // font-size: 18px !important;
                    // font-weight: 700;
                    //margin-bottom: 1rem;
                }

                .pujaCardBlock {
                    background-color: #fff;
                    border-radius: 38px;
                    margin-bottom: 2rem;
                    padding-bottom: 0.2rem;
                }

                .pujaImgBlock {
                    border-radius: 38px;
                    overflow: hidden;
                    height: auto;
                    // height: 36vh;

                    img {
                        width: 100%;
                        margin: auto;
                    }
                }

                .pujaDescription {
                    .pujaNameInfo {
                        padding: 1rem 0.2rem;
                        padding-bottom: 0;

                        @media (max-width: 767px) {
                            padding: 1rem 0 !important;
                        }

                        p {
                            margin-bottom: 0;
                            font-size: 18px;
                            font-weight: 700;
                        }
                    }

                    .nameLocPrice {
                        margin-bottom: 1rem;
                        position: relative;

                        ul {
                            padding: 1rem 0;

                            @media (max-width: 767px) {
                                padding: 0;
                            }

                            @media (max-width: 767px) {
                                padding: 0;
                            }

                            .twoItem {
                                .shareIcon {
                                    cursor: pointer;

                                    svg {
                                        background: linear-gradient(-45deg, #d66f35, #d8a820, #d66f35, #d8a820);
                                        background-size: 200% 200%;
                                        animation: gradient 15s ease infinite;
                                        border-radius: 50%;
                                        padding: 3px;
                                        font-size: 26px;
                                        color: #fff;
                                        transform: scaleX(-1);
                                    }
                                }
                            }

                            li {
                                padding: 0;

                                .squreShape {
                                    width: fit-content;
                                    border-radius: 0;
                                    background-color: transparent;

                                    &.pricetag {
                                        img {
                                            width: 24px;
                                            height: auto;
                                        }
                                    }

                                    &.temple {
                                        img {
                                            width: auto;
                                            height: 34px;
                                            margin-left: 3px;
                                        }
                                    }

                                    &.location {
                                        img {
                                            width: 22px;
                                            height: auto;
                                        }
                                    }

                                    &.pricetag {
                                        img {
                                            width: 24px;
                                            height: auto;
                                        }
                                    }
                                }

                                span {
                                    font-size: 16px;
                                    color: #434242;
                                    font-weight: 500;
                                }

                                .pujaPriceInfo {
                                    span {
                                        display: inline-block;
                                        padding: 6px 12px;
                                        border-radius: 20px;
                                        background-color: #ca5261;
                                        color: #fff;
                                    }
                                }

                                .infoContainer {
                                    margin-left: 8px;
                                    position: relative;
                                  
                                    .infoIcon {
                                        cursor: pointer;
                                    }
                                
                                    .popupBox {
                                        background-color: #fff;
                                        padding: 10px;
                                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
                                        position: absolute;
                                        top: 46px;
                                        left: 30px;
                                        transform: translateY(-50%);
                                        border-radius: 8px;
                                        z-index: 1000;
                                        min-width: 180px;

                                        @media (min-width: 350px) and (max-width: 390px) {
                                            top: 55px;
                                            min-width: 122px;
                                        }

                                        @media (min-width: 391px) and (max-width: 450px) {
                                            min-width: 160px;
                                        }
                                
                                        .messageTitle {
                                            color: #181818;
                                            font-weight: 600;
                                            font-size: 14px;
                                        }
                                
                                        .messageText {
                                            color: #5f5e5e;
                                            font-size: 12px;
                                        }
                                    }
                                }
                                
                                

                                .couponDiscountCode {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    padding: 6px 12px;
                                    border-radius: 20px;
                                    background-color: #bf60d5;
                                    font-size: 16px !important;
                                    font-weight: 500;
                                    margin-bottom: 0;
                                    text-transform: uppercase;
                                    color: #fff;

                                    svg {
                                        color: #bf60d5;
                                        background-color: #fff;
                                        font-size: 20px;
                                        padding: 4px;
                                        border-radius: 50%;
                                        margin-right: 5px;
                                    }
                                }

                                .disclaimerText {
                                    width: 100%;
                                    color: #333333;
                                    background-color: #fff6f2;
                                    border: 1px solid #c75b2b;
                                    padding: 8px 10px;
                                    font-size: 12px !important;
                                    font-weight: 500;
                                    border-radius: 6px;
                                    margin: 10px 0 0 0;

                                    svg {
                                        color: #c75b2b;
                                        font-size: 18px;
                                        margin-right: 5px;
                                    }
                                }
                            }

                            .devoteeInfomation {
                                width: 100%;
                                border-top: 1px solid #cfcfcf;
                                border-bottom: 1px solid #cfcfcf;
                                padding: 10px 0;
                                margin: 0.6rem 0 0.3rem;
    
                                .countText {
                                    color: #434242;
                                    font-size: 16px !important;
                                    font-weight: 500;
                                    margin-bottom: 0 !important;
                                    padding-left: 8px;
    
                                    @media (min-width: 360px) and (max-width: 384px) {
                                        font-size: 14px !important;
                                    }
    
                                    @media (min-width: 385px) and (max-width: 407px) {
                                        font-size: 15px !important;
                                    }
    
                                    span {
                                        color: #d56939;
    
                                        @media (min-width: 360px) and (max-width: 450px) {
                                            font-size: 14px !important;
                                        }
                                    }
                                }
                            }

                            .pujaCloseTimer {
                                margin: .6rem 0 0.3rem;
                            }
                        }

                        .continueBtn {
                            background: linear-gradient(-45deg, #0000 33%, hsla(0, 0%, 100%, .5) 50%, #0000 66%), linear-gradient(-45deg, #d8ad1d, #d56939);
                            background-size: 250% 250%, 100% 100%;
                            animation: gradient 15s ease infinite;
                            height: 100vh;
                            border-radius: 2rem;
                            padding: 0.5rem 1rem;
                            width: 100%;
                            height: 46px;
                            display: inline-block;
                            color: #fff;
                            margin: auto 0;

                            &.Mui-disabled {
                                color: rgba(0, 0, 0, 0.26);
                                box-shadow: none;
                                background: rgba(0, 0, 0, 0.12);
                            }

                            @keyframes gradient {
                                0% {
                                    background-position: 0% 50%;
                                }

                                50% {
                                    background-position: 100% 50%;
                                }

                                100% {
                                    background-position: 0% 50%;
                                }
                            }

                        }
                    }


                }

            }

            .overViewText {
                border-bottom: 3px solid #fff;
                padding-bottom: 1rem;

                p {
                    &:first-child {
                        margin-bottom: 1rem;
                    }

                    &:last-child {
                        font-size: 16px !important;
                    }
                }
            }

            .templeDekhoVideo {
                padding-bottom: 1.5rem;
                border-bottom: 3px solid #fff;
                padding-top: 1rem;

                p {
                    margin-bottom: 1rem;

                    span {
                        font-weight: 600;
                    }
                }

                .seeVideoBtn {
                    width: 100%;
                    font-size: 14px;
                    background: linear-gradient(230deg, #474554, #afaeb9, #474554);
                    background-size: 600% 600%;
                    animation: AnimationName 17s ease infinite;
                    color: #fff;
                    border-radius: 6px;
                    box-shadow: none;
                }
            }

            .SelectPackage {
                padding-bottom: 1.5rem;
                border-bottom: 3px solid #fff;

                p {
                    &:first-child {
                        margin-bottom: 1rem;
                    }
                }

                .packageItem {

                    .pujaPackCarousel {
                        overflow: hidden !important;
                    }

                    .react-multiple-carousel__arrow--left {
                        left: calc(1% + 0px);
                    }
        
                    .react-multiple-carousel__arrow--right {
                        right: calc(2% + 5px);
                    }
        
                    .react-multiple-carousel__arrow {
                        min-width: 26px;
                        min-height: 26px;
                        border-radius: 50%;
        
                        &::before {
                            font-size: 16px;
                        }
                    }

                    .packageCard {
                        background-color: #fff;
                        border: 1px solid #cccbcb;
                        text-align: center;
                        padding: 14px;
                        border-radius: 12px;
                        position: relative;
                        margin-right: 14px;

                        &.active {
                            background-color: #fff6f2;
                            border: 1px solid #ffcdb5;
                        }

                        img {
                            width: 85px;
                            max-width: 100%;
                        }

                        p {
                            font-weight: 500;
                            margin-top: 8px;
                        }

                        h6 {
                            font-size: 16px;
                            font-weight: bold;
                            margin-top: 3px;
                        }

                        span {
                            padding: 0;
                            margin-top: 4px;
                        }

                        .nwlyAddedbadge {
                            width: auto;
                            height: auto;
                            padding: 6px 10px;
                            position: absolute;
                            top: 10px;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            white-space: nowrap;
                            //display: inline-table;
                            bottom: auto;
                            background-color: #ca5261;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                            border-radius: 5px;
                        }
                    }
                }

                .packagePujaInfo {
                    background-color: #fff6f2;
                    border: 1px solid #ffcdb5;
                    padding: 14px;
                    border-radius: 12px;
                    margin-top: 16px;

                    .packagePujaTitle {
                        img {
                            width: 70px;
                            max-width: 100%;
                        }

                        h6 {
                            color: #d56939;
                            font-size: 16px;
                            font-weight: bold;
                        }

                        p {
                            color: #000;
                            font-weight: bold;
                            font-size: 14px;
                        }
                    }

                    .packagePujaSteps {
                        margin-top: 6px;

                        ul {
                            max-width: 100%;
                            background-color: transparent;
                            padding-bottom: 0;

                            li {
                                padding: 0;
                                align-items: flex-start;

                                .MuiListItemAvatar-root {
                                    min-width: 40px;

                                    .MuiAvatar-root {
                                        background-color: transparent;
                                        width: 30px;

                                        img {
                                            width: 20px;
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .pujaBenefits {
                padding-top: 1rem;
                margin-top: 0;

                .benefitsCard {
                    margin-top: 1rem;
                    margin-bottom: 1rem;

                    .benefitCardImg {
                        border-radius: 20px;
                        overflow: hidden;
                        margin-bottom: 1rem;

                        img {
                            width: 100%;
                        }
                    }

                    p {
                        &:first-child {
                            margin-bottom: 1rem;
                        }
                    }

                    p {
                        &:last-child {
                            font-size: 16px !important;
                        }
                    }
                }
            }

            .pujaProccessSteps {
                padding-top: 1rem;
                border-top: 3px solid #fff;
            
                ul {
                    li {
                        position: relative;
                        padding: 0.5rem 0;
            
                        .listCounter {
                            display: flex;
                            align-items: baseline;
            
                            .pujaStepsListCount {
                                margin-left: 0;
                                color: #fff;
                                background-color: #000;
                                border-radius: 50%;
                                padding: 1px 7px;
                                margin-right: 14px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .viewPujaSteps {
                margin-top: -10px;
                
                img {
                    width: 100%;
                }
            }

            .chadhavaFaq {
                padding-top: 1rem;
                border-top: 3px solid #fff;
                margin-top: 1.5rem;
            
                .chadhavaFaqList {
                    margin-top: 4px;
                    font-family: Roboto;
            
                    .accordionInfo {
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
            }

            .blessContinue {
                position: relative;
                //padding-bottom: 38px;
            
                .viewBless {
                    //padding-top: 100px;
                
                    img {
                        width: 100%;
                    }
                }
            
                .continueCart {
                    background: linear-gradient(90deg, #d66f35 0%, #d8a820 100%);
                    border-radius: 1rem 1rem 0 0;
                    padding: 1rem;
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    right: 0;
                    width: 100%;
                    margin: auto;
            
                    .continueCenterBlock {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
            
                        .continueLt {
                            font-size: 16px;
                            color: #fff;
                            font-weight: 600;
                        }
            
                        .continueBtn {
                            background-color: #fff;
                            color: #d56939;
                            padding: 0.5rem 1.5rem;
                            border-radius: 20px;
                            font-size: 14px;
                            text-transform: uppercase;
                            font-weight: 600;
                        }
            
                        .continueBtnDsbl {
                            color: #b2b2b2;
                            background-color: #fff;
                            border-radius: 20px;
                            font-size: 14px;
                            font-weight: 600;
                            padding: .5rem 1.5rem;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}



// --- Booking Calendar Modal // ---
.dateTimingSelection {
    width: 420px;
    max-width: 100%;
    margin: auto;

    .dateTimePopup {
        padding: 1rem;

        p {
            font-size: 18px !important;
            font-weight: 600 !important;
        }

        .calendarTopInfo {
            .topCloseIconBtn {
                background-color: transparent;
                border: 2px solid #d65758;
                border-radius: 50%;
                padding: 2px;
                transition: background-color 0.3s ease, color 0.3s ease;
    
                .MuiSvgIcon-root {
                    color: #d65758;
                    transition: color 0.3s ease;
                    font-size: 16px;
                }
    
                &:hover {
                    background-color: #f13a3a;
                    border: 2px solid #f13a3a;
    
                    .MuiSvgIcon-root {
                        color: #fff;
                    }
                }
            }
        }

        .pujaReminderSec {
            color: #ff0033;
            padding: 10px 14px;
            margin-top: 0.7rem;
            border: 1px solid #ff0033;
            border-radius: 22px;

            .pujaReminderText {
                font-size: 14px !important;
                font-weight: 400 !important;
            }

            svg {
                margin-right: 5px;
                font-size: 20px;
            }
        }

        .MuiDateCalendar-root {
            width: 100%;
        }

        & .MuiPickersDay-today {
            border: none;
        }


        .timeBtnBlock {
            margin: 1rem 0;

            button {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }

        .btnContinue {
            width: 100%;
            margin: 1.5rem 0 0.5rem 0;

            button {
                width: 100% !important;
                margin: 0 auto !important;
            }
        }
    }
}
// --- Booking Calendar Modal End // ---

// Responsive SCSS ============================================= //

@media (min-width: 1200px) {
    .continueCart {
        display: none;
    }

    .pujainfoCenterBlock {
        .blessContinue {
            .viewBless {
                display: none;
            }
        }
    }
}

//Puja Info Banner Img Height
@media (min-width: 330px) and (max-width: 376px) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaImgBlock {
                        // height: 27vh !important;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 377px) and (max-width: 436px) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaImgBlock {
                        // height: 22vh !important;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 437px) and (max-width: 768px) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaImgBlock {
                        // height: 37vh !important;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 820px) and (max-device-width: 820px) and (min-device-height: 1180px) and (max-device-height: 1180px) and (orientation: portrait) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaImgBlock {
                        // height: 23vh !important;
                        height: auto;
                    }
                }
            }
        }
    }
}

//Puja Info Banner Img Height END



@media (max-width: 1199px) {
    .continueBtn {
        &.desktopOnly {
            display: none !important;
        }
    }

    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaDescription {
                        .nameLocPrice {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:901px) and (max-width:1199px) {
    .blessContinue {
        padding-bottom: 0;
        .continueCart {
            position: fixed !important;
            bottom: 70px !important;
            width: calc(68% - 42px) !important;
        }
    }
}

@media (max-width:900px) {
    .continueCart {
        position: fixed !important;
        bottom: 70px !important;
        width: calc(100% - 40px) !important;
    }

    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .pujaCard {
                    .pujaDescription {
                        .nameLocPrice {
                            .couponSmDiscountCode {
                                display: block;
                                width: calc(100% - 5rem);
                                display: flex;
                                align-items: center;
                                padding: 6px 12px;
                                border-radius: 20px;
                                background-color: #bf60d5;
                                font-size: 16px !important;
                                font-weight: 500;
                                margin-bottom: 0;
                                margin-top: 10px;
                                margin-left: 50px;
                                text-align: center;
                                text-transform: uppercase;
                                color: #fff;

                                svg {
                                    color: #bf60d5;
                                    background-color: #fff;
                                    font-size: 20px;
                                    padding: 4px;
                                    border-radius: 50%;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


}

@media (min-width: 900px) {
    .pujainfoCenterBlock {
        .blessContinue {
            .viewBless {
                img {
                    display: none;
                }
            }
        }
    }
}

@media (min-width: 360px) and (max-width: 767px) {

    .pageContainer.pujaPage {
        .pujaBlock {

            .pujaLeftBlock,
            .pujaRightBlock {
                display: none;
            }

            .pujaCenterBlock {

                .pujaCardBox {
                    margin-bottom: 15px;

                    img {
                        height: 210px;
                    }
                }
            }
        }
    }

    //Puja Info
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .SelectPackage {
                    .packageItem {
                        .packageCard {
                            .nwlyAddedbadge {
                                padding: 5px 10px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 340px) and (max-width: 400px) {
    .pageContainer {
        &.pujaPage {
            .pujainfoCenterBlock {
                .SelectPackage {
                    .packageItem {
                        .packageCard {
                            p {
                                font-size: 13px;
                            }

                            h6 {
                                font-size: 14px;
                            }

                            .nwlyAddedbadge {
                                padding: 5px 10px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}