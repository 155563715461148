@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
    box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-family: 'Lato', sans-serif;
}

.analyticsDashboard {
    padding-top: 70px;
}

.analyticsDashboard h4.chakra-heading {
    font-size: 22px;
    font-weight: 600;
    color: #171725;
    margin-bottom: 0;
}

.sbscoHeadingRow {
    justify-content: space-between;
    margin: 10px 50px 15px 50px;
}

.sbscoHeadingRight {
    width: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.sbscoBtnGroup {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 20px;
}

.sbscoBtnGroup button {
    border-radius: 4px;
    border-color: #e2e2e3;
    font-size: 13px;
    font-weight: 600;
    color: #131523;
}

.sbscoBtnGroup button.css-16f2guw {
    color: #fff;
    background-color: #d56939;
    border-color: #d56939;
}

.sbscoBtn button {
    border-radius: 4px;
    border-color: #e2e2e3;
    font-size: 14px;
    font-weight: normal;
}

.sbscoBtnGroup button:first-child {
    border-radius: 4px 0px 0px 4px !important;
}

.sbscoBtnGroup button:nth-child(2) {
    border-radius: 0px 0px 0px 0px !important;
    border-left: none;
    border-right: none;
}

.sbscoBtnGroup button:last-child {
    border-radius: 0px 4px 4px 0px !important;
}

.sbscoAnalyticsDBContainer {
    height: 100%;

}

.sbscoAnalyticsDBContainer .dbBlock {
    height: 36vh;
    border-radius: 6px;
    padding: 10px;
}

.dbBlock h6.chakra-heading {
    font-size: 18px;
    font-weight: 600;
}

.donutChartContent {
    width: calc(100% - 165px);
    margin-left: 0 !important;
}

.donutChartContent p {
    font-size: 14px;
}

.dchartBlock {
    display: flex;
    align-items: top;
    justify-content: left;
    margin-bottom: 5px !important;
}

.dchartBlock div {
    width: 14px;
    height: 14px;
    position: relative;
    top: 4px;
    margin-right: 6px;
    border-radius: 3px;
}

.dchartBlock p {
    line-height: 20px;
}

.dchartBlock .text1 {
    padding-right: 15px;
    word-wrap: break-word;
}

.dchartBlock p:last-child {
    margin-left: 5px;
}

.radialChart {
    position: relative;
}

.radialText {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 80px;
    height: 52px;
    text-align: center;
}

.radialText p {
    font-weight: 500;
    color: #000;
}

.radialText h4 {
    font-size: 26px;
    font-weight: bold;
    color: #000;
    line-height: 26px;
}

.dbBlock.smBlock {
    padding-top: 15px;
}

.dbBlock.smBlock p {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    font-weight: normal;
    margin: 6px;
}

.dbBlock.smBlock h6 {
    font-size: 40px;
    color: #000;
    font-weight: bold;
    margin: 6px;
    bottom: 8px;
}

.dbBlock.smBlock svg {
    bottom: 22px;
}

/* ========= Table CSS ========= */
.sbscoTable {
    /* padding-top: 20px; */
}

.sbscoTable p {
    font-size: 18px;
    color: #000;
    font-weight: bold;
    margin-bottom: 0;
}

.sbscoTable .css-ffg7t2 {
    border-radius: 6px;
}

.sbscoTable .analyticsTable {
    width: 100%;
    border: solid 1px #e2e2e3;
    border-radius: 4px;
    border-collapse: collapse;
    overflow: auto;
}

.sbscoTable .analyticsTable .chakra-table {
    margin: 0;
    border-collapse: collapse;
    min-width: 2000px;
    table-layout: fixed;
}

.sbscoTable .analyticsTable .chakra-table thead {
    width: 100%;
    display: table;
}

.sbscoTable .analyticsTable .chakra-table tbody {
    width: calc(100% + 170px);
    display: block;
    height: 358px;
    overflow-y: auto;
    overflow-x: hidden;
}

.sbscoTable .analyticsTable.settleTable .chakra-table tbody {
    width: auto;
    display: block;
    height: 358px;
    overflow-y: auto;
    overflow-x: hidden;
}

.sbscoTable .analyticsTable .chakra-table tbody tr td svg {
    height: 18px;
}

.sbscoTable .analyticsTable.unAnsTable .chakra-table {
    min-width: 1400px;
}

.sbscoTable .chakra-table thead tr th {
    font-size: 14px !important;
    text-transform: capitalize;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8) !important;
    letter-spacing: 0 !important;
    margin-bottom: 6px;
    background-color: #f9fafb;
    padding-top: 15px;
    padding-bottom: 15px;
}

.sbscoTable .ucaTable .chakra-table thead tr th:last-child {
    min-width: 170px;
}

.sbscoTable .ucaTable .chakra-table tbody tr td:last-child {
    min-width: 170px;
}

.sbscoTable .ecaTable .chakra-table thead tr th:last-child {
    min-width: 170px;
}

.sbscoTable .ecaTable .chakra-table tbody tr td:last-child {
    min-width: 170px;
}


.sbscoTable .chakra-table thead tr th .css-1t27dul,
.sbscoTable .chakra-table thead tr th div {
    max-width: 120px;
    display: block;
    font-size: 14px !important;
    text-transform: capitalize;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8) !important;
    letter-spacing: 0 !important;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sbscoTable .chakra-table thead tr th span .chakra-input {
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    color: rgba(121, 121, 121, 0.8);
    padding: 4px 8px;
    border: 1px solid #c9c9c9;
}
.sbscoTable .chakra-table thead tr th span .chakra-input:focus {
    border: 1px solid #d56939;
    outline: none !important;
}
.sbscoTable .chakra-table thead tr,
.sbscoTable .chakra-table tbody tr {
    width: 100%;
    display: block;
    border-bottom: 1px solid #eaeaea;
}
.sbscoTable .chakra-table tbody tr:last-child {
    border-bottom: none;
}
.sbscoTable .chakra-table tbody tr td {
    height: 34px;
}

.sbscoTable .chakra-table thead tr th,
.sbscoTable .chakra-table tbody tr td {
    width: 10%;
    min-width: 180px;
    max-width: 180px;
    border-collapse: collapse;
    padding-left: 15px;
    padding-right: 0;
}

.sbscoTable .mcaTable .chakra-table thead tr th,
.sbscoTable .mcaTable .chakra-table tbody tr td {
    width: auto;
}

.sbscoTable .mcaTable .chakra-table thead tr th:last-child,
.sbscoTable .mcaTable .chakra-table tbody tr td:last-child {
    min-width: 180px;
}

.sbscoTable .ecaTable .chakra-table thead tr th,
.sbscoTable .ecaTable .chakra-table tbody tr td {
    width: 12%;
    min-width: 140px;
    max-width: 140px;
    border-collapse: collapse;
}

.sbscoTable .ucaTable .chakra-table thead tr th,
.sbscoTable .ucaTable .chakra-table tbody tr td {
    width: 17%;
    min-width: 140px;
    max-width: 140px;
    border-collapse: collapse;
}

.sbscoTable .chakra-table tbody tr td,
.sbscoTable .chakra-table tbody tr td p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
}

.sbscoTable .chakra-table tbody tr td p {
    max-width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sbscoTable .chakra-table tbody tr td {
    border-top: solid 1px #e2e2e3;
    border-bottom: solid 1px #e2e2e3;
}

.sbscoTable .chakra-table tbody tr td .editText {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sbscoTable .chakra-table tbody tr td .editText svg {
    width: 16px;
    height: 16px;
    border-bottom: 1px solid #d7d7d7;
}

.sbscoTable .chakra-table tbody tr td .editText svg:hover {
    cursor: pointer;
}

.sbscoTable .chakra-table tbody tr:nth-child(odd) td {
    background-color: #fafafb;
}

.sbscoTable .chakra-table thead tr th:last-child {
    position: relative;
}

.sbscoTable .chakra-table thead tr th:last-child::after {
    width: 14px;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    right: -14px;
    background-color: #f9fafb;
    border-bottom: 1px solid #e5e5e5;
}

.sbscoTable .pagination {
    margin: 10px 30px 0px 30px;
}

.sbscoTable .pagination .chakra-stack button {
    background-color: #E2E8F0;
    border-radius: 4px;
}

.sbscoTable .pagination .chakra-stack p {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.sbscoTable .pagination .chakra-stack input {
    background-color: #ffffff;
    border: 1px solid #d2d7de;
    border-radius: 3px;
    padding: 5px 10px;
    margin-left: 5px;
}

.sbscoTable .pagination .chakra-stack select {
    background-color: #ffffff;
    border: 1px solid #d2d7de;
    font-size: 14px;
    border-radius: 3px;
    padding: 6px 10px;
}

/* ========= Calendar CSS Start ========= */
.css-14rgsq0 {
    zoom: 1.4;
    right: -55px;
    left: auto;
    margin-left: auto !important;
    margin-top: 24px !important;
    border-radius: 6px !important;
    border: 1px solid #bababa !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
}


/* ========= Calendar CSS End ========= */

/* ========= Edit Audio Modal ========= */
.sbscoTableContainer .window-container {
    background-color: #fff;
    border: none !important;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    padding: 0 20px 20px;
}

.editAudioModal .editAudio {
    margin-bottom: 20px;
}

.sbscoTableContainer .window-container .title-bar {
    background-color: #3965ff;
    margin-left: -20px;
    margin-right: -20px;
}

.infoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.infoHeader .phoneNumb,
.infoHeader .phoneNumb span,
.infoHeader .phoneNumb,
.infoHeader .timeCircle span {
    color: #767676;
}

.infoHeader .phoneNumb,
.infoHeader .timeCircle {
    display: flex;
    align-items: center;
}

.sbscoTableContainer .window-container .title-bar .windowTitle {
    background-color: transparent;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    padding: 0 0 0 10px;
}

.sbscoTableContainer .window-container .title-bar:hover {
    cursor: move;
}

.sbscoTableContainer .window-container .title-bar .buttonContainer .windowButton {
    text-align: center;
    line-height: 25px;
    color: #fff;
    background-color: #fc5b5b;
}

.sbscoTableContainer .window-container .title-bar .buttonContainer .windowButton:hover {
    cursor: pointer;
}

.sbscoTableContainer .window-container .content {
    overflow: hidden;
    padding: 10px 0 0 0;
}

.editAudioModal {
    padding-top: 15px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.formGroup {
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 8px;
}

.rowBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowBlock .colLeft {
    width: 30%;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    color: #767676;
}

.rowBlock .colRight {
    width: 70%;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.rowBlock .colRight .form-control {
    width: 100%;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    color: #65719b;
    padding: 10px 15px;
    height: 40px;
    border-radius: 4px;
}

.textAlignRight {
    text-align: right;
    margin-bottom: 0;
}

.textAlignRight button {
    margin-right: 0;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-weight: normal;
    border-radius: 4px;
    padding: 10px 30px;
}









@media (min-width: 1920px) {
    /* .dchartBlock .text1 {
        padding-right: 15px;
        word-wrap: break-word;
    } */
}

@media (min-width: 1921px) {
    .dbBlock .chakra-stack {
        zoom: 1.4;
    }

    .dbBlock h6.chakra-heading {
        zoom: 1.4;
    }
}

@media (min-width: 2561px) {
    .dbBlock .chakra-stack {
        zoom: 1.6;
    }

    .dbBlock h6.chakra-heading {
        zoom: 1.6;
    }
}
