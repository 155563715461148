.templeTiming {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
  
    .titleBlock {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  
    .checkBoxList {
      margin-bottom: 20px;
    }
  
    .templeTimingCard {
      background: #fff;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 10px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  
      .dayName {
        font-weight: bold;
        margin-bottom: 8px;
      }
  
      .cardLeftContent {
        flex: 1;
      }
  
      .cardRightContent {
        position: relative;
        text-align: right;
  
        .changeButton {
          text-transform: none;
          color: #0056d2;
          font-size: 14px;
        }
  
        .timePicker {
          //position: absolute;
          //z-index: 10;
          background: white;
          //padding: 8px;
          border-radius: 5px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  