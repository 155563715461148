.deleteConfirmModal {
    .MuiPaper-root {
        border-radius: 14px;
        width: 500px;
        max-width: 100%;
    }

    .deleteConfirmModal {
        font-weight: 600;
    }

    .titleDivider {
        background-color: #a8a8a8;
    }

    .confirmBtn {
        padding: 16px 24px 20px;

        .confirmBtnCancel {
            color: #4e4e4e;
            border-color: #c5c5c5;
            transition: 0.3s ease;

            &:hover {
                background-color: #f2f2f2;
            }
        }

        .confirmBtnDelete {
            box-shadow: none;
            background-color: #d11a2a;
            margin-left: 10px;
            transition: 0.3s ease;

            &:hover {
                background-color: #a11420;
            }
        }
    }
}