.pujaNotAvaliableDialog {
    .MuiPaper-root {
        width: 100%;
        max-width: 400px;
        border-radius: 16px;
        padding: 20px 16px;
        background: linear-gradient(to bottom, #fee2e2, #ffffff);
        position: relative;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    }

    .alertImage {
        width: 90px;
        height: 90px;
        background-color: #ffbebe;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        padding: 12px;
        margin: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .title {
        text-align: center;
        color: #ee404c;
        font-size: 24px;
        font-weight: 600;
    }

    .notAvaliableDescription {
        p {
            color: #636363;
            margin-bottom: 0;
            text-align: center;
        }
    }

    .okBtn {
        width: 90px;
        box-shadow: none;
        border-radius: 30px;
        padding: 8px 0;
        margin: auto;
        font-size: 15px;
        background: linear-gradient(90deg, #db4250, #f49e49);
        transition: 0.3s ease;

        &:hover {
            background: linear-gradient(90deg, #ff4949, #ff8b2d);
        }
    }
}