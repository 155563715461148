.birthChartFormInfo {
  background-color: #fff;
  padding: 1rem 1.2rem 1.5rem;
  //border-radius: 12px;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  margin-bottom: 2rem;

  .formTitle {
    color: #181818;
    font-size: 18px;
    font-weight: 600;

    span {
      color: red !important;
      margin-left: 6px;
    }
  }

  .innerFormDetails {
    margin-top: 6px;

    label {
      color: #626367;
      font-size: 14px;

      span {
        color: red;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    input {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }

    button {
      &:hover {
        background-color: transparent;
      }

      svg {
        font-size: 22px;
      }
    }

    .genderSelection {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .generateBtn {
    margin-top: 28px;
    width: 100%;
    box-shadow: none;
    padding: 8px 16px;
    background: linear-gradient(to right, #ff5858, #ff9e41);
    transition: 0.3s ease;

    &.Mui-disabled {
      color: #fff;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover {
      box-shadow: none;
      background: linear-gradient(to right, #ff4949, #ff8b2d);
    }

    span {
      margin-left: 4px;

      svg {
        font-size: 22px;
      }
    }
  }
}