.pageContainer {
    &.donationPage {
        min-height: calc(100vh - 60px);

        .donationBlock {

            .donationLeftBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }

            .centerBlockTop {
                width: 100%;
                height: auto;
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
                margin: 0 auto 25px;

                .topField {
                    display: flex;
                    align-items: center;

                    .profileImg {
                        width: 55px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inputBox {
                        width: 100%;

                        input {
                            border-radius: 35px;
                            padding: 14px 16px;
                            background-color: #ebedf6;
                            border: none;
                            box-shadow: none;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }

                .bottomField {
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 100px);
                    margin-left: 80px;
                    margin-top: 16px;

                    .mediaButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 20px;
                                margin-right: 10px;
                            }
                        }
                    }

                    .dateButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 18px;
                                height: 18px;
                                margin-right: 10px;
                            }
                        }
                    }

                    .writePostButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 20px;
                                height: 15px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .donationCenterBlock {}

            .donationRightBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }
        }
    }
}