.vastuSection {
  .vastuTitle {
    font-size: 14px;
    color: #181818;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .uploaderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .uploadTitle {
      font-size: 16px;
      color: #181818;
      font-weight: 500;
      text-align: left;
      margin-bottom: 0.7rem;
    }

    .uploaderBox {
      width: 100%;
      border: 2px dashed #626367;
      border-radius: 6px;
      text-align: center;
      background-color: transparent;
      cursor: pointer;
      transition: border-color 0.3s ease;

      &:hover {
        border-color: #d56939;
      }

      .previewBox {
        .previewImage {
          width: 100%;
          border-radius: 6px;
        }
      }

      .askToUpload {
        .uploaderIcon {
          margin-bottom: 1rem;

          img {
            width: 70px;
            max-width: 100%;
          }
        }

        .uploadLink {
          color: #181818;
          font-size: 14px;
          text-decoration: underline;
          margin-bottom: 0.2rem;
        }

        .supportedFormats {
          color: #4b4b4b;
          font-size: 12px;
        }

        .errorShow {
          color: #ff2c2c;
          margin-top: 8px;

          svg {
            font-size: 18px;
            margin-right: 4px;
          }
        }
      }
    }

    .uploaderFooter {
      margin-top: 0.6rem;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .maxSize {
        color: #4b4b4b;
        font-size: 12px;
        font-weight: 500;
      }

      .referSample {
        color: #4b4b4b;
        font-weight: 500;

        .sampleLink {
          color: #d56939;
          background: linear-gradient(90deg, #db4250, #f49e49, #db4250);
          background-size: 200%;
          background-clip: text;
          text-decoration: underline;
          font-size: 12px;
          font-weight: 600;
          margin-left: 2px;
          cursor: pointer;
          animation: gradientAnimation 3s infinite linear;
        }
        
        @keyframes gradientAnimation {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 100% 50%;
          }
        }
      }
    }
  }

  .imageDeleteSec {
     position: relative;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    margin-top: 1.2rem;
    margin-left: -18px;
    margin-right: -18px;
    padding: 12px 20px;

    .imageName {
      font-size: 14px;
      color: #181818;
      font-weight: 500;
    }

    .imageDeleteBtn {
      background-color: transparent;
      border-radius: 5px;
      border: 1px solid #868483;
      padding: 2px 4px;
      transition: background-color 0.3s ease, svg color 0.3s ease;

      svg {
        color: #bc4731;
        font-size: 20px;
        transition: color 0.3s ease;
      }

      &:hover {
        background-color: #bc4731;
        border-color: #bc4731;

        svg {
          color: #fff;
        }
      }
    }
  }

  .generateBtn {
    margin-top: 28px;
    margin-bottom: 26px;
    width: 100%;
    box-shadow: none;
    padding: 8px 16px;
    background: linear-gradient(to right, #db4250, #f49e49);
    transition: 0.3s ease;

    &.Mui-disabled {
      color: #fff;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover {
      box-shadow: none;
      background: linear-gradient(to right, #ff4949, #ff8b2d);
    }

    span {
      margin-left: 4px;

      svg {
        font-size: 22px;
      }
    }
  }

  .vastuSummary {
    .summaryTitle {
      font-size: 14px;
      font-weight: 600;
      color: #d56939;
      margin-bottom: 12px;
    }

    .summaryReport {
      background-color: #fff;
      padding: 12px 14px;
      border-radius: 20px;
      box-shadow: 0 5px 10px 0 rgba(80, 92, 98, 0.15);

      p {
        font-size: 14px;
        color: #181818;
      }
    }
  }
}
