.templeCenterBlock .userAnalyticBtn {
  padding: 8px 15px;
  border-radius: 20px;
  width: 100%;
}

.templeCenterBlock .userAnalyticBtn svg {
  font-size: 18px;
  margin-right: 3px;
}

.btngroupanalytics {
  padding: "12px"
}


.error-details {
  margin-bottom: 20px;
}

.error-details h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.member-details h2 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.error-details ul {
  list-style-type: none;
  padding: 0;
  text-align: justify;
}

.error-details li {
  margin-bottom: 5px;
}

.error-details li strong {
  margin-right: 5px;
}

.promotionPujaCard {
  max-width: 345px;
  border-radius: 15px !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.promotionPujaCard .promotionPujaTemple svg {
  font-size: 8px;
  margin-right: 5px;
}

.promotionPujaAccordion {
  margin-top: 10px;
  box-shadow: none !important;
  border-bottom: 1px solid #d56939;
}

.promotionPujaAccordion::before {
  display: none;
}

.viewAllDetailsModal {
  height: 90%;
}

.viewAllDetailsModal .viewAllDetailsTitle {
  color: #d56939;
}

.viewAllDetailsModal .orderDetails {
  width: 100%;
  height: 100%;
  max-height: 80%;
}

.viewAllDetailsModal .orderDetails .mainDetailList .orderInfoText p {
  background-color: #f4f4f4;
  padding: 10px;
  margin-top: 5px;
  border-radius: 6px;
}

.viewAllDetailsModal .bottomCloseBtn {
  width: 100%;
  position: absolute;
  right: 8px;
  bottom: 8px;
}

/* .bottomRightBtn{
    position: absolute;
    bottom: 10%;
    right: 5%;
  } */

/* ========= User Analytics ========= */

.userAnalyticsFilter {
  .btngroupanalytics {
    text-transform: unset;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;

    &.MuiButton-colorInherit {
      border-color: #b8b8b8;
    }

    &.MuiButton-colorPrimary {
      border-top-color: #d56939;
      border-bottom-color: #d56939;
      border-left-color: #d56939;
      border-right-color: #d56939;
      background-color: #d56939;
      color: #fff;
    }

    &:first-child {
      border-right-color: #d56939 !important;
    }

    &:last-child {
      &.MuiButton-colorPrimary {
        border-left-color: #d56939;
      }

    }
  }
}

.userAnalyticsBlock {
  .filterSelect {
    .MuiSelect-select {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.analyticsModal {
    .modalContents {
      width: 550px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      box-shadow: none;
      padding: 0;
      border-radius: 4px;

      .modalHeader {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        padding: 1rem;

        h6 {
          font-weight: 500;
          font-size: 1.25rem;
          line-height: 1.6;
          letter-spacing: 0.0075em;
          flex: 0 0 auto;
          margin: 0px;
        }

        .btnClose {
          color: rgb(158, 158, 158);
          padding: 0;
          border-radius: 50%;
          width: 2rem !important;
          height: 2rem !important;
          max-width: 2rem !important;
          max-height: 2rem !important;
          min-width: 2.5rem !important;
          min-height: 2.5rem !important;
          border-color: transparent;

          &:hover {
            background-color: #f3f3f3;
          }
        }
      }

      .modalBody {
        padding: 1rem;
        border-bottom: 1px solid #e0e0e0;
        .mainDetailList {
          .orderInfoText {
            width: 100%;
            display: inline-block;
            .orderInfoLabel {
              display: inline-block;
              margin-left: -0.2rem;
              margin-right: -0.2rem;
              p {
                width: calc(50% - 0.4rem);
                margin-left: 0.2rem;
                margin-right: 0.2rem;
                float: left;
                background-color: #f4f4f4;
                padding: 0.5rem;
                margin-top: 5px;
                border-radius: 4px;
              }
            }
            
          }
          .cartInfoCard {
            box-shadow: none;
            border: 1px solid #d8d8d8;
            margin-bottom: 1rem;
            .cartInfoCardHeader {
              background-color: transparent;
              border-bottom: 1px solid #d8d8d8;
              span {
                font-size: 1.2rem;
                font-weight: 500;
                line-height: 20px;
              }
            }
            .infoCardBody {
              .member-details {
                h6 {
                  font-size: 1rem;
                  margin-bottom: 0.5rem;
                }
                .tableContents {
                  .infoTable {
                    thead {
                      tr {
                        th {
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .modalFooter {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        padding: 1rem;

        .btnCancel {
          &.grey {
            color: #696969;
            border-color: #d8d8d8;
            border-width: 1px;
            border-style: solid;

            &:hover {
              background-color: #f2f2f2;
            }
          }
        }
      }

      &.infoModal {
        width: 60%;
        //height: 85vh;
        height: auto;

        .modalBody {
          height: 65vh;
          overflow-y: auto;

          p {
            font-size: 14px;
            line-height: 1.8rem;
          }

          .accordionList {
            margin-top: 1rem;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            overflow: hidden;

            .accordHead {
              min-height: unset;
              height: 40px;
              max-height: unset;
              border-bottom: 1px solid #dedede;

              p {
                font-weight: 600;
              }
            }

            .Mui-expanded {
              margin-bottom: 0 !important;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
}