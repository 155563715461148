.cartDetails {
    .addressArea {
        padding: 1.2rem;
        background-color: #fff;
        border-radius: 12px;

        .titleHead {
            font-size: 22px;
            margin-bottom: 1rem;
            line-height: 24px;
        }

        .addressDetail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;

            .addressLeft {
                display: flex;
                align-items: baseline;
                max-width: 70%;

                .addressIcon {
                    margin-right: 10px;

                    svg {
                        color: #d56939;
                        font-size: 35px;
                    }
                }

                .addressText {
                    h6 {
                        margin-bottom: 0;
                        font-size: 16px;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 16px !important;
                        font-weight: 400 !important;
                        color: #505050 !important;
                    }
                }
            }

            .addressRight {
                button {
                    border: 0;
                    background-color: transparent;
                    padding: 0;

                    svg {
                        color: #383838;
                    }
                }
            }
        }
    }

    .logoutCartArea {
        padding: 30px;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logoutCartAreaLeft {
            p {
                margin-bottom: 0;
                color: #6c6c6c;
                line-height: 24px;
            }
        }

        .logoutCartAreaRight {
            button {
                border: none;
                padding: 10px 16px;
                background: linear-gradient(-45deg, #d66f35, #d8a820, #d66f35, #d8a820);
                background-size: 400% 400%;
                animation: gradient 15s ease infinite;
                border-radius: 40px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }

    .pujaAddedArea {
        padding: 1.2rem;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 20px;

        p {
            font-size: 22px !important;
            font-weight: 400 !important;
            margin-bottom: 1rem;
            line-height: 22px;
        }

        .pujaAddedDetails {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .pujaAddedLeft {
                p {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                    margin-bottom: 10px;
                    line-height: 18px !important;

                    &:first-child {
                        color: #d56939;
                    }

                    &:last-child {
                        margin-top: 2rem;
                        color: #505050 !important;
                    }
                }
            }

            .pujaAddedRight {
                display: flex;
                align-items: center;

                p {
                    font-size: 14px !important;
                    margin-bottom: 0;
                    margin-right: 14px;
                    padding: 2px 12px;
                    background-color: #faebe4;
                    border: 1px solid #d56939;
                    border-radius: 5px;
                    font-weight: 600;
                }

                button {
                    border: 1px solid #97989d;
                    background-color: transparent;
                    border-radius: 5px;

                    &:last-child {
                        margin-left: 14px;
                    }

                    svg {
                        font-weight: normal;
                        color: #97989d;
                    }
                }
            }
        }

        .pujaAddedMember {
            margin-top: 20px;

            p {
                font-size: 16px !important;
                font-weight: 400;
                margin-bottom: 10px;
            }

            .memberName {
                display: flex;
                align-items: center;

                p {
                    font-size: 16px !important;
                    padding: 6px 16px;
                    background-color: #000;
                    color: #fff;
                    border-radius: 20px;
                    margin-right: 10px;
                    margin-bottom: 5px !important;
                }
            }
        }
    }

    .billDetails {
        padding: 1.2rem;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 20px;

        .topTitle {
            margin-bottom: 30px;

            p {
                font-size: 22px !important;
                font-weight: 400 !important;
            }
        }

        .billTopPart {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #d4d4d4;
            padding-bottom: 16px;
        }

        .billMiddlePart {
            padding-top: 16px;
            border-bottom: 1px solid #d4d4d4;
            padding-bottom: 16px;

            .firstPart {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 8px;
            }

            .secondPart {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 8px;

                p {
                    button {
                        border: none;
                        background-color: transparent;
                        padding: 0;

                        svg {
                            color: #6c6c6c;
                            font-size: 20px;
                        }
                    }
                }
            }

            .thirdPart {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // padding-bottom: 8px;

                p {
                    button {
                        border: none;
                        background-color: transparent;
                        padding: 0;

                        svg {
                            color: #6c6c6c;
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .discountPart {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0;
            border-bottom: 1px solid #d4d4d4;
        }

        .billBottomPart {
            padding-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-weight: bold;
                margin-bottom: 0;
            }
        }
    }

    .offerZone {
        padding: 1.2rem;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 20px;

        .couponAppliedText {
            p {
                font-weight: 500;
            }

            button {
                padding: 0;
                background-color: transparent;
                display: contents;

                svg {
                    color: #000;
                }
            }
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
        }

        .offerZoneDetails {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .offerZoneLeft {
                p {
                    margin-bottom: 0;
                    font-weight: 600;

                    svg {
                        color: #fff;
                        background-color: #f44336;
                        font-size: 24px;
                        padding: 4px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                }
            }

            .offerZoneRight {
                button {
                    border: none;
                    background-color: transparent;
                    padding: 0;

                    svg {
                        color: #383838;
                    }
                }
            }
        }
    }

    .userDetails {
        padding: 1.2rem;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 20px;

        .topTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;

            p {
                font-size: 22px !important;
                font-weight: 400 !important;
                margin-bottom: 1rem !important;
                line-height: 22px;
            }

            button {
                border: none;
                background-color: transparent;
                padding: 0;

                svg {
                    color: #383838;
                    font-size: 30px;
                }
            }
        }

        .mainDetailsArea {
            p {
                border-bottom: 1px solid #d4d4d4;
                padding: 14px 0;
                font-weight: 600;
            }

            .checkBoxArea {
                margin-bottom: 0;

                .checkBoxItem {
                    background-color: transparent;
                    border: none;
                    padding: 10px 0;

                    input {
                        width: 20px;
                        height: 20px;
                        border: 1px solid #d56939;
                        border-radius: 0;
                        background-color: transparent;
                        box-shadow: none;

                        &:checked {
                            background-color: #d56939;
                            box-shadow: none;
                        }
                    }

                    label.onlyDesktop {
                        padding-left: 20px;
                        color: #000;
                        font-weight: 600;
                        display: block;
                    }

                    label.onlyMobile {
                        display: none;
                    }
                }
            }
        }
    }

    .cancelPolicy {
        padding: 1.2rem;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 20px;

        .title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
        }

        .cancelPolicyDetails {
            p {
                margin-bottom: 0;
            }
        }
    }

    .placeOrderArea {
        padding: 1.2rem;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .placeOrderLeft {
            display: flex;
            align-items: center;

            img {
                max-width: 80px;
            }

            .payUsingText {
                margin-left: 0;

                p {
                    margin-bottom: 0;
                    color: #6c6c6c;
                    line-height: 18px;

                    button {
                        border: none;
                        background-color: transparent;
                        padding: 0;

                        svg {
                            color: #6c6c6c;
                        }
                    }
                }

                .upi {
                    color: #000;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }

        .placeOrderRight {
            button {
                border: none;
                background-color: transparent;

                p {
                    margin-bottom: 0;
                    padding: 20px;
                    background-color: #d56939;
                    border-radius: 40px;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }
}

//Success Modal CSS
.OrderResult {
    border-radius: 20px !important;

    .successCard {
        width: 400px;
        height: auto;
        max-width: 100%;
        padding: 30px 16px;
        text-align: center;

        .successIcon {
            img {
                width: 70px;
                max-width: 100%;
            }
        }

        p {
            font-weight: bold;
        }

        button {
            margin-top: 20px;
            box-shadow: none;
            border: 1px solid #d56939;
            border-radius: 20px;
        }
    }
}

//Error Modal CSS
.errorMsg {
    border-radius: 20px !important;

    .errorCard {
        width: 400px;
        height: auto;
        max-width: 100%;
        padding: 30px 16px;
        text-align: center;

        .errorIcon {
            margin-bottom: 20px;

            img {
                width: 70px;
                max-width: 100%;
            }
        }

        p {
            font-weight: bold;
        }

        button {
            margin-top: 20px;
            box-shadow: none;
            border: 1px solid #d56939;
            border-radius: 20px;
        }
    }
}

//Warning Modal CSS
.warnMsg {
    .warnCard {
        width: 430px;
        height: auto;
        max-width: 100%;
        padding: 20px 16px;
        text-align: center;
        background-color: #fff3f3;

        svg {
            color: #ff0002;
            margin-right: 5px;
        }

        .warnItem {
            font-size: 16px;
            color: #000;
            font-weight: 500;
            text-align: left;
        }

        button {
            box-shadow: none;
            border: 1px solid #d56939;
            border-radius: 20px;
            padding: 6px 14px;
        }
    }
}

//Apply Coupon Modal CSS
.applyCouponPopup {
    .couponModal {
        width: 100%;
        padding: 20px;

        .couponModalTitle {
            margin-bottom: 20px;
            font-weight: bold;
        }

        .validCoupon {
            color: #6aa809;
            margin-top: 5px;
            margin-left: 5px;
            font-size: 14px;
        }

        .invalidCoupon {
            color: #ff0909;
            margin-top: 5px;
            margin-left: 5px;
            font-size: 14px;
        }

        .applyCouponBtn {
            width: 100%;
            border-radius: 20px;
            box-shadow: none;
            margin-top: 30px;
            padding: 8px;
        }
    }
}


// No Item Cart CSS
.noItemCart {
    .noItemText {
        text-align: center;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #d56939;
    }

    .redirectBtn {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        button {
            box-shadow: none;
            align-items: flex-start;
            padding: 10px 20px;
            border-radius: 25px;
            margin-right: 10px;

            img {
                width: 20px;
                max-width: 100%;
                margin-left: 5px;
            }
        }
    }
}




// ----- Puja Booking Checkout Cart SCSS -----
.pujaBookingDetails {
    margin-top: 20px;

    .cardDesign {
        margin-top: 1rem;
        background-color: #fff;
        padding: 1rem;
        border-radius: 20px;

        p {
            font-size: 14px;
        }

        &.pujaInformation {
            .pujaInfoTop {
                border-bottom: 2px solid #eaeeff;
                padding-bottom: 1rem;

                .pujaNameDate {
                    .pujaName {
                        color: #d56939;
                        font-size: 16px;
                        letter-spacing: 0.2px;
                    }

                    p {
                        font-weight: bold;
                        margin: 4px 0;
                    }
                }

                .pujaImage {
                    width: 55px;
                    height: 55px;
                    max-width: 100%;
                    border-radius: 6px;
                    background-color: #707070;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 6px;
                    }
                }
            }

            .pujaInfoBottom {
                margin-top: 1rem;

                .templeName {
                    margin-bottom: 8px;

                    img {
                        width: auto;
                        height: 30px;
                        margin-right: 2px;
                    }

                    p {
                        font-weight: 600;
                        letter-spacing: 0.2px;
                    }
                }

                .templeAddress {
                    img {
                        width: 16px;
                        height: auto;
                    }

                    p {
                        font-weight: 600;
                        letter-spacing: 0.2px;
                    }
                }
            }
        }

        &.addressSce {
            .addressTitle {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            .addressText {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                // margin-top: 10px;

                svg {
                    font-size: 30px;
                    color: #d56939;
                    margin-right: 10px;
                }

                .areaInfo {
                    font-weight: 500;
                    color: #000;
                }

                .locationInfo {
                    color: #626367;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }

                button {
                    padding-right: 0;
                }
            }

            .arrowBtn {
                button {
                    padding: 0;
                    justify-content: end;

                    &:focus {
                        outline: none;
                        border: none;
                    }

                    &:hover {
                        background-color: transparent;
                    }

                    &:active {
                        outline: none;
                        box-shadow: none;
                        border: none;
                    }

                    &:focus-visible {
                        outline: none;
                        border: none;
                    }

                    svg {
                        color: #242424;
                    }
                }
            }
        }

        &.devoteeDetails {
            .devoteeTitle {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            .devoteeNameSec {
                .devoteeNameTitle {
                    font-weight: 600;
                    margin-top: 10px;
                }

                .nameArea {
                    margin-top: 8px;

                    .boxColor {
                        width: -webkit-fill-available;
                        height: auto;
                        background-color: #000;
                        color: #fff;
                        padding: 6px 8px;
                        font-size: 14px;
                        border-radius: 20px;
                        // margin-right: 6px;
                    }
                }

                .devoteeBottom {
                    margin-top: 40px;

                    .devoteeCount {
                        background-color: #ffece3;
                        border: 1px solid #f2ae8d;
                        border-radius: 5px;
                        padding: 2px 10px;
                        font-weight: 500;
                        color: #000;
                    }

                    button {
                        padding: 2px 10px;
                        background-color: #4c7fcf;
                        color: #fff;
                        text-transform: capitalize;
                        font-weight: 500;
                        font-size: 13px;
                        box-shadow: none;

                        svg {
                            font-size: 16px;
                        }
                    }

                    .deleteCart {
                        background-color: transparent;
                        padding: 4px 6px;
                        border: 1px solid #97989d;
                        font-size: 10px;
                        border-radius: 5px;
                        transition: 0.5s ease;

                        &:hover {
                            background-color: #bc4731;
                            color: #fff;
                            border: 1px solid #bc4731;

                            svg {
                                color: #fff;
                            }
                        }

                        svg {
                            color: #bc4731;
                            font-size: 18px;
                            transition: 0.5s ease;
                        }
                    }
                }
            }
        }

        &.offerZone {
            .offerZoneTitle {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            .couponCodeTop {
                // border-bottom: 2px solid #eaeeff;
                // padding-bottom: 1rem;
                margin-top: 8px;

                .couponApplySec {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    img {
                        width: 22px;
                        max-width: 100%;
                        margin-right: 8px;
                    }

                    .couponText {
                        font-weight: 500;
                        color: #000;

                        span {
                            color: #ca5261;
                            font-weight: 600;
                        }
                    }
                }
            }

            .couponCodeBottom {
                margin-top: 12px;
                width: 100%;

                .couponApplySec {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    img {
                        width: 22px;
                        max-width: 100%;
                        margin-right: 8px;
                    }

                    .couponText {
                        font-weight: 500;
                        color: #000;
                    }
                }

                .arrowBtn {
                    button {
                        padding-right: 0;
                        justify-content: end;

                        &:focus {
                            outline: none;
                            border: none;

                        }

                        &:hover {
                            background-color: transparent;
                        }

                        &:active {
                            outline: none;
                            box-shadow: none;
                            border: none;
                        }

                        &:focus-visible {
                            outline: none;
                            border: none;
                        }

                        svg {
                            color: #242424;
                        }
                    }
                }
            }
        }

        &.billDetails {
            .billDetailsTitle {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            .detailList {
                margin-top: 10px;

                .listItem {
                    border-bottom: 2px solid #eaeeff;
                    padding-bottom: 10px;
                    padding-top: 10px;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }

                    p {
                        span {
                            font-weight: 600;
                        }

                        .couponCode {
                            color: #ca5261;
                        }

                        .couponCodeDiscount {
                            color: #ca5261;
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }

        &.userDetails {
            .userDetailsTitle {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            .userDetailList {
                margin-top: 10px;

                p {
                    border-bottom: 2px solid #eaeeff;
                    padding-bottom: 10px;
                    padding-top: 10px;

                    &:first-child {
                        padding-top: 0;
                    }


                }
            }

            .mailAcceptDetail {
                padding-top: 10px;

                .checkBox {
                    padding: 0;
                }
            }
        }

        &.cancellationPolicy {

            //For Payment BTN Spacing
            @media (max-width: 900px) {
                margin-bottom: 5rem;
            }

            .cancelPolicyTitle {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            .policyText {
                margin-top: 10px;

            }
        }
    }

    .paymentBtn {
        margin-top: 1rem;
        padding: 1rem;
        border-radius: 20px 20px 0 0;
        background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 33%, hsla(0, 0%, 100%, 0.5) 50%, rgba(0, 0, 0, 0) 66%), linear-gradient(-45deg, #d8a820, #d66f35);
        background-size: 250% 250%, 100% 100%;
        animation: gradient 15s ease infinite;

        p {
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }

        button {
            background-color: #fff;
            box-shadow: none;
            border-radius: 30px;
            color: #d56939;
            font-size: 16px;
            font-weight: 600;
            padding: 8px 24px;
            text-transform: uppercase;
        }
    }

}

@media (max-width: 900px) {
    .paymentBtn {
        bottom: 70px !important;
        position: fixed !important;
        width: calc(100% - 40px) !important;
    }
}

@media (min-width: 330px) and (max-width: 400px) {
    .paymentBtn {
        p {
            font-size: 16px !important;
        }

        button {
            font-size: 14px !important;
        }
    }
}

// ----- Puja Booking Checkout Cart END SCSS -----





// For Responsive
@media (min-width: 300px) and (max-width: 767px) {

    .cartDetails {

        .pujaAddedArea,
        .billDetails,
        .offerZone,
        .userDetails,
        .cancelPolicy {
            margin-top: 15px;
            padding: 20px;
        }

        .userDetails {
            .mainDetailsArea {
                .checkBoxArea {
                    .checkBoxItem {
                        label.onlyDesktop {
                            display: none;
                        }

                        label.onlyMobile {
                            display: block;
                            padding-left: 20px;
                            color: #000;
                            font-weight: 600;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .addressArea {
            padding: 20px;
        }

        .logoutCartArea {
            padding: 20px;
            display: inline-block;
            width: 100%;

            .logoutCartAreaLeft {
                margin-bottom: 15px;
            }
        }

        .placeOrderArea {
            padding: 20px;
            display: inline-block;
            width: 100%;

            .placeOrderLeft {
                margin-bottom: 15px;
            }
        }

        .pujaAddedArea {
            .pujaAddedDetails {
                .pujaAddedRight {
                    font-size: 12px;
                    display: inline-block;

                    p {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }

                    button {
                        padding: 6px;

                        svg {
                            font-size: 20px;
                        }

                        &:last-child {
                            margin-left: 16px;
                        }
                    }
                }
            }

            .pujaAddedMember {
                .memberName {
                    p {
                        padding: 8px 16px;
                    }
                }
            }
        }
    }

    // No Item Cart Responsive
    .noItemCart {
        .redirectBtn {
            display: block;

            button {
                width: 100%;
                margin: 5px 0;
            }
        }
    }
}