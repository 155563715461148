.kundliCardListSec {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  //max-width: 420px;
  margin: 10px auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  .cardTopImg {
    text-align: center;
  
    .kundliCardImg {
      width: 50px;
      max-width: 100%;
    }
  }

  .cardTopTitle {
    font-size: 14px !important;
    margin-top: 5px !important;
    font-weight: bold !important;
    color: #d56939;
    text-align: center;
  }

  .listArea {
    margin-top: 8px;
  
    .highlightedPart {
      width: 85%;
      text-align: center;
      margin: auto;
      font-size: 14px;
      font-weight: 500;
      //letter-spacing: 0.4px;
      color: #fff;
      background-color: #ca5261;
      border-radius: 5px;
      margin-bottom: 6px;
      padding: 16px;

      .titlePart {
        font-size: 14px;
        font-weight: 500;
      }

      .capitalText {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
      }

      ul {
        list-style-position: inside;
        margin: 0;
        display: inline-block;
        text-align: center;
        padding: 0;

        li {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .pNone {
        padding: 0;
      }
    }

    .descriptionInfo {
      font-size: 14px;
      color: #181818;
      margin-top: 14px;
    }
  }
}