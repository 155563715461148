.pageContainer {
    &.horoscopePage {
        .horoscopeBlock {
            .horoscopeCenterBlock {
                .calendarBtn {
                    width: 60%;
                    margin: auto;
                    margin-bottom: 30px;

                    .calendarBlock {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        justify-content: flex-start;
                    }

                    .middleTextBtn {
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 6px;
                        box-shadow: none;
                        max-width: 300px;
                        transition: all 0.7s ease;
                        &:hover {
                            background-color: #cb5f31;
                        }
                        &:focus {
                            background-color: #d56939;
                        }
                    }
                }

                .rashiItem {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .mainCategories {
                        width: 100%;
                        margin: auto;
                        display: flex;
                        justify-content: center;

                        ul {
                            width: 100%;
                            margin: auto;
                            text-align: center;
                            background-color: transparent;

                            li {
                                width: 95px;
                                height: auto;
                                float: left;
                                padding: 10px;
                                text-align: center;
                                display: block;
                                margin-bottom: 0.5rem;

                                .imgCircle {
                                    width: 75px;
                                    height: 75px;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    background-color: #efe9e0;
                                    border: 2px solid #d56939;
                                    cursor: pointer;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        margin: auto;
                                        object-fit: contain;
                                    }
                                }

                                .listTitle {
                                    text-align: center;
                                    margin-top: 0.5rem;

                                    p {
                                        font-size: 14px;
                                        font-weight: 500;
                                        text-align: center;
                                        color: #181818;
                                    }
                                }
                            }
                        }

                        &.searchMainCategories {
                            margin-bottom: 1rem;

                            ul {
                                &.searchCatgList {
                                    width: 100%;
                                    display: flex;
                                    overflow-x: auto;
                                    scrollbar-width: none;
                                    position: relative;
                                    border-bottom: 1px solid #d6d6d6;

                                    li {
                                        margin-bottom: 0;

                                        &.active {
                                            position: relative;

                                            &::after {
                                                width: 100%;
                                                height: 6px;
                                                margin: auto;
                                                background-color: #d56939;
                                                border-radius: 3px;
                                                content: "";
                                                position: absolute;
                                                left: 0;
                                                right: 0;
                                                bottom: -8px;
                                                top: auto;
                                                z-index: 10;
                                            }
                                        }

                                        .listTitle {
                                            p {
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .horoscopeDetails {
                    // background-color: #fff;
                    // padding: 16px 20px;
                    // border-radius: 12px;
                    margin-top: 10px;

                    .detailsListText {
                        margin-bottom: 1.25rem;
                        text-align: justify;

                        h6 {
                            font-size: 20px;
                            margin-bottom: 12px;
                        }

                        p {
                            font-size: 16px;
                            margin-bottom: 0.8rem;
                        }
                    }
                }

                .bottomBtn {
                    .submitBtn {
                        box-shadow: none;
                        padding: 8px 20px;
                    }

                    .clearBtn {
                        box-shadow: none;
                        padding: 8px 20px;
                    }
                }
            }
        }
    }
}



// For Responsive
@media (min-width: 1200px) and (max-width: 1417px) {
    .pageContainer {
        &.horoscopePage {
            .horoscopeBlock {
                .horoscopeCenterBlock {
                    .rashiItem {
                        .mainCategories {
                            ul {
                                li {
                                    width: auto;
                                    padding: 8px;

                                    .imgCircle {
                                        width: 65px;
                                        height: 65px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}