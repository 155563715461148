.numerologyListSec {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  //max-width: 420px;
  margin: 10px auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  .cardTopImg {
    text-align: center;
  
    .numerologyImg {
      width: 50px;
      max-width: 100%;
    }
  }

  .cardTopTitle {
    font-size: 14px !important;
    margin-top: 5px !important;
    font-weight: bold !important;
    color: #d56939;
    text-align: center;
  }

  .listArea {
    margin-top: 6px;
  
    .cardListItem {
      .dotIcon {
        svg {
          font-size: 10px;
        }
      }
  
      p {
        font-size: 14px;
        color: #181818;
      }
    }
  }
}


// .numerology-stack {
//   align-items: flex-start;
//   margin-top: 20px;
// }

// .numerology-item {
//   display: flex;
//   // align-items: center;
//   font-size: 0.9rem;
//   color: #181818;
// }

// .dot-icon {
//   display: flex;
//   // align-items: ;
//   justify-content: center;
// }

// .numerology-text {
//   flex: 1;
//   font-size: 0.9rem;
//   color: #181818;
// }