.pageContainer.templePage .templeBlock .calendarBtn {
    margin-bottom: 20px;
}

.pageContainer.templePage .templeBlock .calendarBtn .middleTextBtn {
    padding: 12px;
    border-radius: 30px;
    background-color: #000;
    box-shadow: none;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard {
    width: 100%;
    background-color: #fff;
    padding: 20px 15px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 12px;
    cursor: pointer;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .CardtopTitle {
    color: #d56939;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .tithiList {
    margin-bottom: 15px;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .tithiList:last-child {
    margin-bottom: 0;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .tithiList .tithiHeading,
.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .auspiciousList .tithiHeading,
.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .inuspiciousList .tithiHeading {
    color: #000;
    font-weight: bold;
    margin-bottom: 8px;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .tithiList p,
.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .auspiciousList p,
.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .inuspiciousList p {
    color: #5d5d5d;
    font-weight: 500;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .greenRound {
    width: 20px;
    height: 20px;
    background-color: #64a816;
    border-radius: 50%;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .redRound {
    width: 20px;
    height: 20px;
    background-color: #f44336;
    border-radius: 50%;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .auspiciousList {
    border-right: 1px solid #626367;
    margin-right: 15px;
    padding-right: 10px;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .inuspiciousList {
    border-right: 1px solid #626367;
    margin-right: 14px;
    padding-right: 10px;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .auspiciousList:last-child,
.pageContainer.templePage .templeBlock .panchangContent .panchangCard .tithiDetails .inuspiciousList:last-child {
    border: none;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .timingCard {
    border: 1px solid #d2d2d2;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .timingCard svg {
    color: #a8a8a8;
    font-size: 45px;
    margin-bottom: 8px;
}

.pageContainer.templePage .templeBlock .panchangContent .panchangCard .timingCard p {
   color: #5d5d5d;
   font-weight: bold;
}