.blurImgcontainer {
                 
    width: 100%; /* Set the width you need */
    height: 40vh; /* Set the height you need */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 35px;

  }
  .backgroundBlurImgcontainer {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(30px); /* Apply 30% blur */
  }
  .foregroundImgcontainer {
    position: absolute;
    width: fit-content;
    height: 40vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 25px;
}

  // For Responsive
@media (min-width: 360px) and (max-width: 767px) {
    
        .blurImgcontainer {
            height: 25vh;
        }
        .foregroundImgcontainer {
            height: 25vh;
        }
    
}