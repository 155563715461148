.chooseDeliveryAddressArea {
    width: 100%;
    padding: 20px;

    .topHeader {
        margin-bottom: 30px;

        .deliveryAddressTitle {
            color: #181818;
            font-size: 18px;
            font-weight: 600;
        }

        .topCloseIcon {
            background-color: transparent;
            border: 2px solid #d65758;
            border-radius: 50%;
            padding: 2px;
            transition: background-color 0.3s ease, color 0.3s ease;

            svg {
                color: #d65758;
                transition: color 0.3s ease;
                font-size: 16px;
            }

            &:hover {
                background-color: #f13a3a;
                border: 2px solid #f13a3a;

                .MuiSvgIcon-root {
                    color: #fff;
                }
            }
        }
    }

    .addressArea {
        padding-bottom: 30px;
        background-color: #fff;

        .addressDetail {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .addressLeft {
                display: flex;
                align-items: center;
                cursor: pointer;

                .addressIcon {
                    margin-right: 10px;

                    svg {
                        color: #d56939;
                        font-size: 30px;
                    }
                }

                .addressText {
                    margin-right: 20px;

                    @media (max-width: 1200px) {
                        margin-right: 0;
                    }

                    .addressHeading {
                        .title {
                            font-size: 16px;
                            color: #181818;
                            font-weight: 500;
                            margin-bottom: 0;
                        }
                    }

                    .detailAddress {
                        p {
                            font-size: 14px;
                            color: #626367;
                            width: 95%;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            display: -webkit-box;
                            line-height: 1.5;
                            max-height: 4.5em;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                        }
                    }
                }
            }

            .addressRight {
                button {
                    border: 0;
                    background-color: transparent;
                    padding: 0;
                    justify-content: end;

                    svg {
                        color: #383838;
                    }
                }
            }
        }
    }

    .addAddressArea {
        border-top: 1px solid #ebebeb;
        padding-top: 20px;

        button {
            text-transform: none;
            color: #181818;
            align-items: flex-start;
            font-size: 16px;

            span {
                margin-right: 6px;

                svg {
                    color: #d56939;
                    font-size: 26px;
                }
            }
        }
    }
}