@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

.headerMainNav {
    nav {
        background-color: #fff;

        .headerContainer {
            width: 1920px;
            margin: auto;
            max-width: 100%;
        }

    }

}

.templeApp {
    nav {
        box-shadow: none;
    }
}


.headerContainer {
    background-color: #fff;
    padding: 10px 16px;
    border-bottom: 1px solid #ebedf6;

    .headerLeft {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .logo a img {
            max-width: 18px;
            margin-right: 10px;
        }

        .logoText {
            color: #d56939;
            margin-left: 4px;

            a {
                text-decoration: none;
                color: #d56939;

                .logoTextTitle {
                    font-weight: bold;
                    font-family: 'Raleway', sans-serif;
                }
    
                .logoTextSubTitle {
                    font-weight: 500;
                    font-family: 'Raleway', sans-serif;
                    font-size: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .headerCenter {
        width: fit-content;
        max-width: 100%;
        margin: auto;

        .navLink ul {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            position: relative;
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                padding: 0;

                a {
                    width: 100%;
                    text-decoration: none;
                    color: #d56939;
                    font-weight: 600;
                    padding: 0 16px;

                    @media (min-width: 900px) and (max-width: 940px) {
                        padding: 0 5px;
                        font-size: 14px;
                    }

                    @media (min-width: 941px) and (max-width: 986px) {
                        padding: 0 6px;
                        font-size: 14px;
                    }

                    @media (min-width: 987px) and (max-width: 1130px) {
                        padding: 0 8px;
                        font-size: 14px;
                    }

                    @media (min-width: 1131px) and (max-width: 1226px) {
                        padding: 0 10px;
                    }

                    @media (min-width: 1227px) and (max-width: 1292px) {
                        padding: 0 12px;
                    }

                    // @media (min-width: 900px) and (max-width: 1083px) {
                    //     padding: 0 5px;
                    //     font-size: 14px;
                    // }

                    &.active {
                        position: relative;

                        &::after {
                            width: 100%;
                            height: 3px;
                            content: "";
                            position: absolute;
                            bottom: -20px;
                            left: 0;
                            right: 0;
                            background-color: #d56939;
                        }
                    }
                }
            }
        }
    }

    .headerRight {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        .notificationBlock {
            margin-right: 15px;

            a {
                color: #d56939;
                text-decoration: none;
            }
        }

        .displayNone {
            display: none;
        }

        .appDownloadInfo {
            .downloadBtnInfo {
                margin-right: 8px;
                padding: 8px 12px 8px 10px;
                box-shadow: none;

                .downloadBtnText {
                    text-decoration: none;
                    color: #fff;
                    font-weight: 500;
                    white-space: nowrap;
                    font-weight: 600;
                    text-transform: capitalize;

                    svg {
                        font-size: 20px;
                    }
                }
            }
        }

        .languageSelect {
            margin-right: 8px;
 
            .langDpdn {
                min-width: 96px;
                font-size: 14px;
                background-color: #fff;
                color: #1f2937;
                border-radius: 6px;
                border: 1px solid #e5e7eb;
                transition: 0.5s ease;

                &:hover {
                    background-color: #f9fafb;
                }
 
                & .MuiOutlinedInput-notchedOutline {
                    border: none;
                }

                svg {
                    font-size: 22px;
                }
            }
        }

        .cartBlock {
            margin-right: 3px;
            display: flex;
            align-items: center;
            position: relative;

            .cartProfile {
                span {
                    position: relative;
                    color: #d56939;
                    background-color: transparent;
                    margin-right: 4px;

                    svg {
                        font-size: 24px;
                    }
                }

                &:hover {
                    background-color: transparent;
                }
            }

            span {
                position: absolute;
                color: #fff;
                background-color: #d56939;
            }

            a {
                color: #d56939;
                text-decoration: none;
            }
        }

        .profileBlock {
            border-radius: 50%;
            overflow: hidden;

            .logoutProfile {
                padding: 0 0 0 8px;

                &:hover {
                    background-color: transparent;
                }
            }

            a {
                width: 100%;
                height: 100%;
                display: inline-block;
                text-decoration: none;
                border-radius: 50%;

                div {
                    letter-spacing: -2px;
                    font-size: 14px;
                    width: 34px;
                    height: 34px;
                    padding: 0;
                    padding-right: 2px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        border-radius: 50%;
                    }
                }

                img {
                    border-radius: 50%;
                }
            }
        }
    }

    .toggleBtn {
        display: none;

        svg {
            color: #fff;
            font-size: 30px;
        }
    }

    .searchBoxPopUp {
        background-color: #fff;
        z-index: 100000000;
        width: 18em;
        height: auto;
        position: absolute;
        padding: 15px;
        border-radius: 8px;
        border: 1px solid #ebedf6;
        left: 44px;
        top: 61px;

        .searchMenu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // padding: 15px;

            .recent {
                h6 {
                    color: #000;
                }
            }

            .edit {
                a {
                    text-decoration: none;
                }
            }
        }

        .searchItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 0;

            .recentProfile {
                display: flex;
                align-items: center;

                .rpImage {
                    margin-right: 10px;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
                }

                .rpName {
                    h6 {
                        color: #000;
                        margin: 0;
                    }
                }
            }

            .closeIcon {
                margin-left: 4px;

                i {
                    color: #4e4e4e;
                    font-size: 20px;
                }
            }
        }
    }

    .notificationPannel {
        width: 320px;
        height: auto;
        background-color: #fff;
        border: 1px solid #ebedf6;
        z-index: 1000;
        margin-left: 70%;
        margin-top: 10px;
        position: fixed;
        border-radius: 12px;

        .notificationDetails {
            display: flex;
            align-items: baseline;
            padding: 15px 20px;

            .unreadMark {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #d56939;
                margin-right: 25px;
            }

            .messageText {
                display: flex;

                h6 {
                    margin-bottom: 0;
                    line-height: 20px;
                    color: #000;

                    p {
                        font-size: 14px;
                        margin-bottom: 0;
                        color: #b6b6b6;
                        font-weight: normal;
                    }
                }
            }
        }

        .solidLine {
            width: 100%;
            height: 1px;
            background-color: #eaeaea;
        }

        .moreBtn {
            text-align: center;

            button {
                border: none;
                background-color: transparent;
                padding: 10px;
                font-weight: 600;

                svg {
                    color: #d56939;
                }
            }
        }
    }
}

/* ========= Small Device header ========= */
.headerSm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.6rem 1rem;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 101;

    .headerSmLeft {
        width: 70%;

        .addressText {
            p {
                &:first-child {
                    font-size: 16px;
                    color: #d56939;
                    font-weight: 600;
                }

                &:last-child {
                    font-size: 14px;
                    color: #181818;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                svg {
                    color: #d56939;
                    font-size: 16px;
                    margin-left: 8px;
                }
            }
        }
    }

    .headerSmRight {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;

        .headLang {
            .btn {
                background-color: transparent;
                border: none;
            }
        }

        .languageSelect {
            margin-right: 2px;

            .langDpdn {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #fff;
                color: #1f2937;
                font-size: 15px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #e5e7eb;
                padding: 8px !important;
                transition: 0.3s ease;
            
                &:hover {
                    background-color: #f9fafb;
                }
            
                & .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            
                svg {
                    display: none;
                }

                .MuiSelect-select {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    padding: 0 !important;
                }
            }
        }
          

        .headProfile {
            .profileBlock {
                overflow: hidden;
                border-radius: 50%;

                a {
                    width: 100%;
                    height: 100%;
                    // display: inline-block;
                    text-decoration: none;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.headLang {
    .btn {
        background-color: transparent;
        border: none;
    }
}



/* ========= Language Section ========= */
.langWindow {
    .MuiPaper-rounded {
        border-radius: 16px;
    }

    .langContainer {
        width: 420px;
        max-width: 100%;
        height: auto;
        margin: auto;
        padding: 2rem;
        border-radius: 20px !important;

        .langFormControl {
            width: 100%;

            .langRadioGroup {
                width: 100%;

                label {
                    font-size: 18px;
                    color: #181818;
                    margin: 0;
                    font-weight: 600;
                    margin-bottom: 1rem;
                }

                .langBtn {
                    width: 100%;
                    margin: 0.5rem 0;
                    position: relative;
                    background-color: #f1f0f5 !important;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 16px !important;
                    overflow: hidden;

                    label {
                        width: 100%;
                        margin: 0 auto !important;
                        display: inline-block;
                        background-color: #f1f0f5 !important;
                        text-align: left;
                        color: #181818;
                        text-transform: capitalize !important;
                        font-size: 16px !important;
                        padding: 1rem !important;
                    }

                    .langLetter {
                        font-size: 60px;
                        font-weight: bold;
                        color: #d7d8df;
                        line-height: 40px;
                        margin-right: 1rem;
                        font-weight: 600;
                    }

                }
            }
        }


    }
}

/* ========= Responsive SCSS Start ========= */
@media (min-width: 1200px) {
    .hdRightGrid {
        padding-left: 0 !important;
    }

    .headerRight {
        .appDownloadInfo {
            .downloadBtnInfo {
                margin-right: 10px !important;
            }
        }

        .cartBlock {
            //margin-right: 15px !important;

            a {
                button {
                    span {
                        margin-right: 0px !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .headerRight {
        .appDownloadInfo {
            .downloadBtnInfo {
                a {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .hdRightGrid {
        padding-left: 0 !important;
    }

    .headerRight {
        .appDownloadInfo {
            .downloadBtnInfo {
                margin-right: 8px !important;
                padding: 8px 10px 8px 8px !important;
            }
        }

        .cartBlock {
            margin-right: 0px !important;

            a {
                button {
                    span {
                        margin-right: 0px;
                    }
                }
            }
        }

        .profileBlock {
            margin-left: 14px;
        }
    }
}

@media (min-width:900px) and (max-width: 991px) {
    .mainGrid {
        .hdLeftGrid {
            padding-bottom: 10px;
        }
    }

    .headerContainer {
        .headerCenter {
            .navLink {
                ul {
                    li {
                        a {
                            &.active {
                                &::after {
                                    bottom: -16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:768px) and (max-width:991px) {
    .headerMainNav {
        .headerContainer {
            position: relative;
            padding-bottom: 0;

            .headerRight {
                position: absolute;
                top: 0px;
                right: 15px;
            }
        }
    }

    .blessingCard {
        .MuiGrid-container {
            flex-wrap: nowrap;
        }
    }
}

@media (max-width: 767px) {

    .rightHeader {
        display: none;
    }

    .toggleBtn {
        display: block;
    }

    .headerContainer .headerLeft .searchAutoPopulate .searchBlock {
        width: 220px;
    }

    .headerContainer .notificationPannel {
        width: 310px;
        margin-left: 0;
        margin-top: 20px;
    }

    .headerContainer .notificationPannel .notificationDetails {
        padding: 10px 15px;
    }

    .headerContainer .searchBoxPopUp {
        top: 80px;
        left: 50px;
    }
}

// For App-Download Icon
.displayNone {
    display: none;
}

@media (min-width:400px) and (max-width:767px) {
    .headerMainNav {
        display: inline-block;

        .headerContainer {
            position: relative;

            .headerCenter {
                max-width: 320px;
            }

            .mainGrid {

                .hdRightGrid {
                    padding: 0;
                }
            }

            .headerLeft {
                .searchAutoPopulate {
                    width: 90%;

                    .searchBlock {
                        .searchInput {
                            padding-right: 0;
                        }

                        .btnSearch {
                            i {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .headerCenter {
                .navLink {
                    .links {
                        li {
                            a {
                                font-size: 14px;
                                padding-left: 5px;
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }

            .headerRight {
                position: absolute;
                top: 0px;
                right: 15px;
            }
        }
    }

    .blessingCard {
        .MuiGrid-container {
            flex-wrap: nowrap;
        }
    }



    .footerArea {
        .wiget {
            &.fsec2 {
                .menuFooter {}
            }
        }
    }
}

@media (min-width:300px) and (max-width:399px) {
    .headerMainNav {
        display: inline-block;

        .headerContainer {
            position: relative;
            padding-top: 20px;

            .headerCenter {
                max-width: 320px;
            }

            .mainGrid {
                .hdLeftGrid {
                    padding-top: 15px;
                }

                .hdCenterGrid {
                    padding: 0;
                }

                .hdRightGrid {
                    padding: 0;
                }
            }

            .headerLeft {
                .searchAutoPopulate {
                    width: 90%;

                    .searchBlock {
                        .searchInput {
                            padding-right: 0;
                        }

                        .btnSearch {
                            i {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .headerCenter {
                .navLink {
                    .links {
                        li {
                            a {
                                font-size: 14px;
                                padding-left: 5px;
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }

            .headerRight {
                position: absolute;
                top: 0px;
                right: 15px;
            }
        }

        .headerContainer {
            .headerRight {
                .notificationBlock {
                    margin-right: 4px;
                }
            }
        }
    }

    .blessingCard {
        .MuiGrid-container {
            flex-wrap: nowrap;
        }
    }
}


/* ========= Responsive SCSS End ========= */