.pageContainer {
    &.blogCardPage {
        .blogArea {
            .blogCenterBlock {
                margin-bottom: 2rem;

                .blogCardTopContent {
                    .blogTitle {
                        margin-left: 8px;
                        font-size: 18px;
                        color: #181818;
                        font-weight: 600;
                    }

                    button {
                        text-transform: capitalize;

                        &:hover {
                            background-color: transparent;
                        }

                        span {
                            margin-left: 6px !important;
                        }
                    }
                }

                .blogCardCarousel {
                    padding-bottom: 20px;

                    .react-multi-carousel-dot-list {
                        .react-multi-carousel-dot {
                            button {
                                border: none;
                                width: 10px;
                                height: 10px;
                                background: rgba(213, 105, 57, 0.3);
                            }
                        }

                        .react-multi-carousel-dot--active button {
                            background: #d56939;
                        }
                    }

                    .react-multiple-carousel__arrow--left {
                        left: calc(2% + 1px);
                    }

                    .react-multiple-carousel__arrow--right {
                        right: calc(2% + 1px);
                    }

                    .react-multiple-carousel__arrow {
                        min-width: 26px;
                        min-height: 26px;
                        border-radius: 50%;

                        &::before {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

// -- Native View -- //
.pageContainerBlogNative {
    padding: 10px 20px 20px;
    background-color: #f1f0f5;
    min-height: calc(100vh - 160px) !important;
    position: relative;

    &.blogCardPage {
        .blogArea {
            .blogCenterBlock {
                margin-bottom: 2rem;

                .blogCardTopContent {
                    .blogTitle {
                        margin-left: 8px;
                        font-size: 18px;
                        color: #181818;
                        font-weight: 600;
                    }

                    button {
                        text-transform: capitalize;

                        &:hover {
                            background-color: transparent;
                        }

                        span {
                            margin-left: 6px !important;
                        }
                    }
                }

                .blogCardCarousel {
                    padding-bottom: 20px;

                    .react-multi-carousel-dot-list {
                        .react-multi-carousel-dot {
                            button {
                                border: none;
                                width: 10px;
                                height: 10px;
                                background: rgba(213, 105, 57, 0.3);
                            }
                        }

                        .react-multi-carousel-dot--active button {
                            background: #d56939;
                        }
                    }

                    .react-multiple-carousel__arrow--left {
                        left: calc(2% + 1px);
                    }

                    .react-multiple-carousel__arrow--right {
                        right: calc(2% + 1px);
                    }

                    .react-multiple-carousel__arrow {
                        min-width: 26px;
                        min-height: 26px;
                        border-radius: 50%;

                        &::before {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}