.socialMediaAddSec {
    padding: 1.5rem;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    background-color: #f8f8f8;
    margin-bottom: 20px;

    .socialMediaList {
        border-right: 1px solid #bebebe;
        margin-right: 2px;
        text-decoration: none;
        color: inherit;

        svg {
            font-size: 36px;
            color: #d56939;
        }

        .socialMediaText {
            h6 {
                font-size: 16px;
                font-weight: 600;
                line-height: 25px;
            }

            p {
                font-size: 12px;
                color: #666;
            }
        }

        &.bNone {
            border: none !important;
        }
    }
}


@media (max-width:1200px) {
    .socialMediaAddSec {
        margin-bottom: 20px;
        
        .socialMediaList {
            border: none;
            margin-bottom: 6px;
            
        }
    }
}