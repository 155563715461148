.pageContainer {
    &.refundCancellation {
      .sectionHead {
        .mainTitle {
          text-align: center;
          font-size: 30px;
          font-weight: 600;

          span {
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(to right, #d56939 0%, #d8a820 50%, #000000 100%);
            background-size: 200%;
            animation: gradientAnimation 6s ease-in-out infinite;
          }
        }
      }

      @keyframes gradientAnimation {
        0% {
          background-position: 0% 50%;
        }
  
        50% {
          background-position: 100% 50%;
        }
  
        100% {
          background-position: 0% 50%;
        }
      }
  
      .refundCancellationInfo {
        p {
          text-align: justify;
        }
  
        a {
          color: #000;
          font-weight: bold;
          text-decoration: none;
        }
  
        ul {
          padding: 0;
  
          li {
            list-style: none;
            padding: 0;

            .primaryText {
              display: inline;
              font-weight: 600;
              margin-right: 0.5rem;
            }
  
            .secondaryText {
              display: inline;
              font-size: 1rem;
              font-weight: 400;
              color: #2b2b2b;
            }
  
            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  }