.couponCodeDialogTop {
    // border-bottom: 2px solid #eaeeff;
    padding-bottom: 1rem;
    //margin-top: 8px;

    .couponApplySec {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        img {
            width: 22px;
            max-width: 100%;
            margin-right: 8px;
        }

        .couponText {
            font-weight: 500;
            color: #000;

            span {
                color: #ca5261;
                font-weight: 600;
            }
        }
    }
}

.CouponNotValidText {
    margin-bottom: 1rem;

    p {
        color: red;

        svg {
            font-size: 20px;
            margin-right: 4px;
        }
    }
}

.applyBtn {
    margin-top: 8px;

    button {
        width: 100%;
        box-shadow: none;
        padding: 8px 24px;
        border-radius: 6px;
    }
}

.existingCouponCode {
    margin-top: 20px;
    border-top: 1px solid #cdcdcd;
    padding-top: 18px;

    .existingCodeTitle {
        font-size: 14px;
        padding-left: 0.5rem;
    }

    .existingCodeList {
        .codeText {
            span {
                font-weight: 600;
            }
        }

        .existingCodeApplyBtn {
            padding: 0;
            justify-content: end;
            display: contents;

            &:hover {
                background-color: transparent;
            }

            &.Mui-focusVisible {
                background-color: transparent;
            }

            p {
                text-transform: capitalize;
            }
        }
    }
}