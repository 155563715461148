.locationSelectDialog {
    .topTitle {
        p {
            font-size: 20px;
            font-weight: 500;
        }

        .btnClose {
            background-color: transparent;
            border: 2px solid #d65758;
            border-radius: 50%;
            padding: 2px;
            transition: background-color 0.3s ease, color 0.3s ease;

            .MuiSvgIcon-root {
                color: #d65758;
                transition: color 0.3s ease;
                font-size: 16px;
            }

            &:hover {
                background-color: #f13a3a;
                border: 2px solid #f13a3a;

                .MuiSvgIcon-root {
                    color: #fff;
                }
            }
        }
    }

    .innerContent {
        .templeNameInfo {
            margin: 20px 0 24px;

            .templeName {
                font-size: 18px;
                margin-bottom: 6px;
                color: #252525;
            }

            .cityName {
                font-weight: 600;
                margin-bottom: 6px;

                span {
                    svg {
                        color: #d56939;
                        margin-right: 2px;
                    }
                }
            }
        }



        .addressSelection {
            .messageText {
                p {
                    width: 100%;
                    color: #930c0c;
                    background-color: #fff6f2;
                    border: 1px solid #c75b2b;
                    padding: 8px 10px;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 6px;
                }
            }

            .textFillBox {
                width: 100%;
                margin: 0;
                margin-bottom: 1rem;

                .textAreaSec {
                    width: 100%;
                    margin-top: 0.6rem;
                }
            }
        }
    }

    .okBtn {
        margin-top: 14px;
        text-align: center;

        button {
            box-shadow: none;
            padding: 8px 30px;
            border-radius: 30px;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}