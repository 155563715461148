.pageContainer {
    &.templePage {

        .templeBlock {

            .templeLeftBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }

            .centerBlockTop {
                width: 100%;
                height: auto;
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
                margin: 0 auto 25px;

                .topField {
                    display: flex;
                    align-items: center;

                    .profileImg {
                        width: 55px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inputBox {
                        width: 100%;

                        input {
                            border-radius: 35px;
                            padding: 14px 16px;
                            background-color: #ebedf6;
                            border: none;
                            box-shadow: none;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }

                .bottomField {
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 100px);
                    margin-left: 80px;
                    margin-top: 16px;

                    .mediaButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 20px;
                                margin-right: 10px;
                            }
                        }
                    }

                    .dateButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 18px;
                                height: 18px;
                                margin-right: 10px;
                            }
                        }
                    }

                    .writePostButton {
                        display: flex;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-size: 14px;

                            img {
                                width: 20px;
                                height: 15px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .searchView {
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                    border-radius: 30px;
                }
            }

            .templeCenterBlock {
                .templeCardBox {
                    width: 100%;
                    background-color: #fff;
                    padding: 12px 15px;
                    // width: 20em;
                    height: auto;
                    margin-bottom: 20px;
                    border-radius: 12px;
                    position: relative;
                    cursor: pointer;

                    .templeTop {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 12px;

                        .templeProfile {
                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }

                        .templeTitle {
                            overflow: hidden;

                            p {
                                margin-bottom: 0;
                                font-size: 15px;
                                font-weight: bold;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                img {
                                    width: 14px;
                                    margin-left: 5px;
                                }
                            }

                            .ratings {
                                display: flex;

                                i {
                                    color: #d56939;
                                    padding-right: 5px;
                                    font-size: 14px;
                                }

                                p {
                                    margin-bottom: 0;
                                    font-weight: 400;

                                    span {
                                        color: #d56939;
                                        padding-right: 3px;
                                    }

                                    .ratingCount {
                                        color: #a3a3a3;
                                    }
                                }
                            }
                        }
                    }

                    .templeImage {
                        img {
                            border-radius: 14px;
                            width: 100%;
                            height: 300px;
                            margin-top: 16px;
                            object-fit: cover;
                        }
                    }

                    .templeDescription {
                        min-height: 40px;
                        overflow: hidden;
                        margin-bottom: 0;
                        margin-top: 0;

                        p {
                            font-size: 14px;
                            margin-top: 16px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                            line-height: 1.4;
                            max-height: 4.5em;
                            text-align: justify;
                            color: #000;
                        }
                    }

                    .imgLogo {
                        position: absolute;
                        top: 82px;
                        right: 26px;
                        bottom: auto;

                        img {
                            width: 30px;
                            max-width: 100%;
                            background-color: #fff;
                            border-radius: 50%;
                            padding: 5px;
                            height: 30px;
                            object-fit: contain;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .jagannathTemple {
                    width: 100%;
                    background-color: #fff;
                    padding: 12px 15px;
                    height: auto;
                    margin: 10px 0;
                    border-radius: 12px;
                    cursor: pointer;

                    .jagannathTop {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;

                        .jagannathProfile {
                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                border: 3px solid #d56939;
                            }
                        }

                        .jagannathTitle {
                            p {
                                margin-bottom: 0;
                                font-size: 15px;
                                font-weight: bold;

                                img {
                                    width: 14px;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }

                    .jagannathImage {
                        img {
                            border-radius: 20px;
                            width: 100%;
                            height: 28em;
                            margin-top: 16px;
                            object-fit: cover;
                        }
                    }

                    .jagannathDes {
                        p {
                            font-size: 14px;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .templeRightBlock {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;
            }
        }
    }
}




// Responsive SCSS ============================================= //

@media (min-width: 1200px) {
    .pageContainer {
        &.templePage {
            .templeBlock {
                .templeCenterBlock {
                    .templeCardBox {
                        width: calc(50% - 1rem);
                        margin: 0.5rem;
                        float: left;
                        min-height: 450px;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .pageContainer {
        &.templePage {
            .templeBlock {
                .templeCenterBlock {
                    padding-bottom: 30px;
                }

                &.templeBlock {}
            }
        }
    }
}

@media (min-width: 330px) and (max-width: 767px) {

    .pageContainer.templePage {
        .templeBlock {

            .templeLeftBlock,
            .templeRightBlock {
                display: none;
            }

            .templeCenterBlock {

                .templeCardBox {
                    margin-bottom: 15px;
                    min-height: auto;

                    .templeImage {
                        img {
                            height: 200px;
                        }
                    }
                }
            }
        }
    }
}