.blogContainer {
    .imageUpSec {
        img {
            width: 200px;
            max-width: 100%;
        }
    }

    .multiSelectItem {
        .labelItem {
            background-color: #fff;
            padding-right: 6px;
        }
    }

    .checkBoxArea {
        span {
            &:hover {
                background-color: transparent;
            }
        }
    }

    .bottomBtnArea {
        margin-top: 16px;

        button {
            box-shadow: none;
        }
    }
}