.bannerSobisco {
    width: 100%;
    padding: 24px 10px 24px 10px;
    cursor: pointer;
}

.adBannerDialog {
    .MuiPaper-root {
        border-radius: 14px;
        width: 90%;
        max-width: 500px;
        margin: 16px;

        @media (min-width: 600px) {
            width: 500px;
        }
    }

    .dialogTitle {
        .topItem {
            .topCloseIcon {
                background-color: transparent;
                border: 2px solid #d65758;
                border-radius: 50%;
                padding: 2px;
                transition: background-color 0.3s ease, color 0.3s ease;

                svg {
                    color: #d65758;
                    transition: color 0.3s ease;
                    font-size: 16px;
                }

                &:hover {
                    background-color: #f13a3a;
                    border: 2px solid #f13a3a;

                    .MuiSvgIcon-root {
                        color: #fff;
                    }
                }
            }
        }
    }

    .linkWrapper {
        text-decoration: none;

        .onlinePlatformCard {
            border: 1px solid #eaeaea;
            border-radius: 20px;
            padding: 40px 16px;
            box-shadow: 0 5px 10px 0 rgba(80, 92, 98, 0.15);
            margin-bottom: 12px;
            cursor: pointer;
    
            .twoItem {
                width: auto;
    
                img {
                    width: 130px;
                    max-width: 100%;
                }
    
                button {
                    svg {
                        color: #242424;
                    }
                }
            }
        }
    }
}