.gurujiPredictionContent {
  .predictionTitle {
    color: #d56939;
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 0;
    text-transform: capitalize;
  }

  .predictionCard {
    background-color: #fff;
    padding: 12px 15px;
    border-radius: 6px;
    margin-top: 3px;
    margin-bottom: 2rem;

    p {
      font-size: 14px;
      color: #000;
      text-align: left;
    }
  }
}