.aiGurujiFooter {
  padding: 1rem 1.2rem;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  z-index: 10;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);

  .aiGurujiFooterContent {
    .aiGurujiFooterLeft {
      .chatTextInputArea {
        .MuiOutlinedInput-root {
          border-radius: 30px;

          & fieldset {
            border-color: #d56939;
            transition: border-color 0.3s ease;
          }

          &:hover fieldset {
            border-color: #d56939;
          }

          &.Mui-focused fieldset {
            border-color: #d56939;
          }
        }

        .MuiInputBase-input::placeholder {
          color: #6a6a6a;
          font-weight: 400;
        }

        .buttonDivider {
          width: 1px;
          height: 24px;
          background-color: #c4c4c4;
          margin-left: 2px;
        }

        input {
          width: 100%;
          padding: 15px 0px 15px 20px;
        }

        .footerIcon {
          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .aiGurujiFooterRight {
      .chatExport {
        text-align: center;
        cursor: pointer;

        img {
          width: 35px;
          max-width: 100%;
        }

        p {
          color: #d56939;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
}

.placeholder-container {
  position: relative;
  display: inline-block;
}

.placeholder-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  color: #333;
  /* Hide the cursor text */
}

.placeholder-static {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  pointer-events: none;
  color: #999;
}

.placeholder-static-typing {
  position: absolute;
  top: 14px;
  left: 18px;
  font-size: 16px;
  pointer-events: none;
  color: #999;
}

.placeholder-dynamic {
  position: absolute;
  top: 14px;
  left: 18px;
  /* Adjust this value to match where you want the animated text */
  font-size: 16px;
  pointer-events: none;
  color: #999;
  opacity: 0;
}

/* Keyframes for the sliding up animation with fade-in and fade-out */
@keyframes slideInOut {
  0% {
    transform: translateY(15px);
    opacity: 0;
  }

  10% {
    transform: translateY(0px);
    opacity: 1;
  }

  70% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

/* Apply the animation to the dynamic text */
.animate {
  animation: slideInOut 2.5s ease-in-out forwards;
}


.hide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}


.pulsating-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ff0000;
  animation: pulse 1.5s infinite;
}

.chatTextInputArea {
  //width: 380px;
  width: 460px;
  max-width: 100%;
}

// Request Button Animation ========= //
.box {
  width: 20px;
  height: 20px;
  display: grid;
  place-content: center;
  color: white;
  text-shadow: 0 1px 0 red;
  margin-right: 0;

  --border-angle: 0turn; // For animation.
  --main-bg: conic-gradient(from var(--border-angle),
      rgb(93, 255, 61),
      rgb(255, 249, 85) 5%,
      rgb(243, 154, 65) 60%,
      rgb(244, 54, 54) 95%);

  border: solid 3px transparent;
  border-radius: 1.5rem;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #c05600, #a80000 99%, transparent);

  background:
    var(--main-bg) padding-box,
    var(--gradient-border) border-box,
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 3s linear infinite;

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: paused;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

// Request Button Animation END ========= //

// ========= Responsive SCSS Start ========= //

@media (min-width: 300px) and (max-width: 991px) {
  .aiGurujiFooter {
    .aiGurujiFooterContent {
      .aiGurujiFooterLeft {
        //width: calc(100% - 60px);
        width: 100%;

        .placeholder-container {
          width: 100%;

          .chatTextInputArea {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .aiGurujiFooterLeft {
    .placeholder-container {
      .chatTextInputArea {
        width: 280px;
      }

      .footerIcon {
        padding-left: 0;
      }
    }
  }

  .aiGurujiFooterRight {
    margin-left: 10px;
    zoom: 80%;
  }
}

@media (max-width: 399px) {
  .aiGurujiFooterLeft {
    .placeholder-container {
      .chatTextInputArea {
        width: 280px;
      }

      .footerIcon {
        padding-left: 0;
      }
    }
  }

  .aiGurujiFooterRight {
    margin-left: 10px;
    zoom: 80%;
  }
  .placeholder-dynamic {
    span {
      display: none;
    }
  }
}

.typing-dots {
  display: inline-block;
  margin-left: 4px;
  font-weight: bold;

  &::after {
    content: '...';
    animation: typingDots 1s steps(3, end) infinite;
  }
}

.typing-dots {
  display: inline-block;
  margin-left: 4px;
  font-weight: bold;

  &::after {
    content: '...';
    animation: typingDots 1s steps(3, end) infinite;
  }
}

@keyframes typingDots {
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  100% { content: '...'; }
}
// ========= Responsive SCSS End ========= //