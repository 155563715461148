.rightPannelArea {
    width: 20em;

    .pannelTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        cursor: pointer;

        .leftProfileInfo {
            display: flex;
            align-items: center;

            i {
                font-size: 38px;
                color: #d56939;
            }

            div {
                &.MuiAvatar-root {
                    letter-spacing: -2px;
                    font-size: 14px;
                    width: 34px;
                    height: 34px;
                    padding: 0;
                    padding-right: 2px;
                }

            }

            img {
                width: 55px;
                height: 55px;
                border-radius: 50%;
                margin-right: 10px;
            }

            h6 {
                margin-bottom: 0;
                font-weight: bold;
                margin-left: 10px;

                p {
                    margin-bottom: 0;
                    margin-top: 2px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #d56939;
                }
            }
        }

        .rightArrow {
            button {
                border: 0;
                background-color: transparent;
                padding: 0;

                svg {
                    color: #626367;
                }
            }

        }
    }

    .bottomMenuList1 {
        border-bottom: 4px solid #f4f4f4;
        border-top: 4px solid #f4f4f4;

        ul {
            margin: 0;
            padding: 0 15px;
            padding-top: 16px;
            list-style: none;

            li {
                margin-bottom: 20px;

                a {
                    text-decoration: none;
                    color: #626367;

                    &:hover {
                        cursor: pointer;
                    }

                    svg {
                        color: #626367;
                        margin-right: 10px;
                    }
                }
            }
        }
        .forMobileOnly {
            display: none;
        }
    }

    // .bottomMenuList2 {
    //     ul {
    //         margin: 0;
    //         padding: 16px 15px;
    //         list-style: none;

    //         li {
    //             margin-bottom: 20px;

    //             a {
    //                 text-decoration: none;
    //                 color: #626367;

    //                 &:hover {
    //                     cursor: pointer;
    //                 }

    //                 svg {
    //                     color: #626367;
    //                     margin-right: 10px;
    //                 }
    //             }
    //         }
    //     }
    // }

    .appDownloadInfo {
        padding: 20px 15px 15px;

        li {
            list-style: none;

            .downloadBtnText {
                display: flex;
                align-items: center;
                background-color: #ffefe8;
                padding: 1rem;
                border-radius: 10px;
                text-decoration: none;
                transition: transform 0.2s ease, box-shadow 0.2s ease;

                &:hover {
                    box-shadow: 0px 4px 6px rgba(213, 105, 57, 0.25);
                }
    
                svg {
                    color: #d56939;
                    margin-right: 10px;
                    transition: transform 0.2s ease;
                }

                &:hover svg {
                    transform: rotate(-10deg);
                }
    
                p {
                    color: #626367;
                }
            }
        }
    }

    .logOutBtn {
        position: absolute;
        bottom: 0;
        cursor: pointer;

        ul {
            margin: 0;
            padding: 0 15px 16px;
            list-style: none;

            li {
                a {
                    text-decoration: none;
                    color: #626367;

                    &:hover {
                        cursor: pointer;
                    }
                    
                    svg {
                        color: #626367;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
// -- For Resposive Purpose -- //
@media (max-width: 1199px) {
    .rightPannelArea {
        .bottomMenuList1 {
            .forMobileOnly {
                display: block !important;
            }
        }
    }}