.pageContainer {
  &.communityGuidelines {
    .sectionHead {
      .mainTitle {
        text-align: center;
        font-size: 30px;
        font-weight: 600;

        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(to right, #d56939 0%, #d8a820 50%, #000000 100%);
          background-size: 200%;
          animation: gradientAnimation 6s ease-in-out infinite;
        }
      }
    }

    @keyframes gradientAnimation {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }

    .communityGuidelinesInfo {
      p {
        text-align: justify;
      }

      a {
        color: #000;
        font-weight: bold;
        text-decoration: none;
      }

      .communityGuidelinesList {
        padding: 0;

        li {
          list-style: none;
          padding-left: 0;
          padding-right: 0;
          display: flex;
          align-items: center;

          .primaryText {
            display: inline;
            font-weight: 600;
            margin-right: 0.5rem;
          }

          .secondaryText {
            display: inline;
            font-size: 1rem;
            font-weight: 400;
            color: #3d3d3d;
          }

          span {
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .sectionHead {
    p {
      font-size: 1.3rem !important;
    }
  }

  .refundCancellationInfo {
    h5 {
      font-size: 1.2rem !important;
    }
  }
}