.createNewAccount {
    width: 460px;
    max-width: 100%;
    margin: auto;
    margin-top: 6rem;
    text-align: left;

    p {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 16px;
        color: #d56939;
    }

    .mainTitle {
        margin-top: 15px;
        margin-bottom: 15px;
        color: #d56939;
        font-weight: bold;
        font-size: 30px;
        line-height: 24px;
        position: relative;
        padding-bottom: 10px;

        &::after {
            width: 50px;
            height: 1px;
            content: "";
            position: absolute;
            left: 0;
            bottom: -5px;
            background-color: #d56939;
        }
    }

    .signupDetails {
        margin-top: 40px;

        .signUpText {
            font-weight: bold;
            font-size: 18px;
            text-transform: uppercase;
            margin-top: 15px;
        }

        p {
            margin-bottom: 0;
            color: #000;
        }

        img {
            width: 105px;
            height: 105px;
            margin-bottom: 20px;
        }

        .textArea {
            .customerInfoInput {
                margin-bottom: 6px;
            }

            label {
                padding: 10px 0;
            }

            input {
                padding: 15px 0 5px;
            }
        }

        .genderSelect {
            text-align: left;
            margin-top: 26px;

            svg {
                color: #d56939;
            }
        }

        .updateBtn {
            margin: 30px 0;

            .btn-primary {
                font-size: 14px;
                background-color: #d56939;
                color: #fff;
                border: none;
                transition: all 0.7s ease;
                text-transform: uppercase;
                letter-spacing: 2px;
                min-width: 240px;
                width: 100%;
                box-shadow: none;

                &:hover {
                    background-color: #b14a1e;
                    box-shadow: none;
                }

                &.btnRound {
                    border-radius: 32px;
                    padding: 8px 18px;
                }
            }
        }

        .loginText {
            margin-bottom: 50px;

            p {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.6);
                font-weight: 600;

                a {
                    text-decoration: none;
                    font-size: 16px;
                    color: #d56939;
                }
            }
        }
    }
}

.ErrorformDialog {
    .errorForm {
        width: 400px;
        height: auto;
        max-width: 100%;
        padding: 20px;
        text-align: center;

        p {
            color: #000;
        }
    }

    .btnContinue {
        button {
            margin-top: 10px;
            box-shadow: none;
            border: 1px solid #d56939;
            border-radius: 20px;
        }
    }
}


// For Responsive
@media (min-width: 360px) and (max-width: 767px) {
    .createNewAccount {
        padding: 20px;
        margin-top: 5rem;
    }
}