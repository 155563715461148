.birthChartSection {
  width: auto;
  margin: 22px 0;

  .birthChartTitle {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #000;
  }

  .birthChartImg {
    width: 100%;
    height: auto;
    margin: 1rem 0;
  }
}